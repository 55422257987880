import React from "react";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./GalleryEditForm.css";
import ErrorPopup from "../../PopUps/ErrorPopup/ErrorPopup";

export default function GalleryEditForm({
  initialFormData,
  setFormData,
  galleryPopupEdit,
  visible,
  setpopUp,
  formData,
  formErrors,
  handleInputChange,
  handleAlbumGalleryImageChange,
  inputRefAlbumCoverImage,
  albumCoverImageLoading,
  handleSubmit,
  removeAlbumCoverImage,
  error,
  setError,
}) {
  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp}>
      {error.errorStatus === true ? (
        <ErrorPopup error={error} setError={setError} />
      ) : (
        <div className="GalleryCreateFormContainer w-[60%] h-[80%] overflow-y-scroll mt-[5%]  bg-[#ffff]">
          <div className="border-b-[1px] border-b-[#111] border-opacity-25 p-[24px] flex flex-row justify-between">
            <h3 className="text-[18px] font-[600]">
              Edit Details of Album Gallery
            </h3>
            <button
              onClick={() => {
                setpopUp(false);
                setFormData(initialFormData);
                setpopUp({ visible: false, galleryId: "" });
              }}
            >
             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none"><path d="M18 6L6 18M6 6L18 18" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </button>
          </div>
          <div className="mt-6  px-4 flex flex-col">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="coverImage"
            >
              Gallery Name
            </label>
            <input
              type="text"
              name="title"
              placeholder="Enter Gallery Name"
              className={`border rounded p-4 w-10/12 ${
                formErrors?.title ? "border-red-500" : ""
              }`}
              value={formData?.title}
              onChange={handleInputChange}
            />
            {formErrors?.title && (
              <p className="text-red-500">{formErrors?.title}</p>
            )}
            <br />
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="coverImage"
            >
              Gallery Description
            </label>
            <input
              type="text"
              name="description"
              placeholder="Enter Gallery Description"
              className={`border rounded p-4 w-10/12 ${
                formErrors.description ? "border-red-500" : ""
              }`}
              value={formData.description}
              onChange={handleInputChange}
            />
            {formErrors?.description && (
              <p className="text-red-500">{formErrors?.description}</p>
            )}
            <br />
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="coverImage"
              >
                Gallery Cover Image:
              </label>
              <div className="relative">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAlbumGalleryImageChange}
                  className="hidden"
                  id="coverImage"
                  name="coverImage"
                  ref={inputRefAlbumCoverImage}
                />
                <button
                  onClick={() => inputRefAlbumCoverImage.current.click()}
                  className="py-2 px-3 leading-tight focus:outline-none focus:shadow-outline cursor-pointer bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                  {formData?.coverImage
                    ? "Change Album Cover"
                    : "Choose Album Cover"}
                </button>

                {albumCoverImageLoading && (
                  <p className="text-sm text-gray-500 mt-2">Uploading...</p>
                )}
              </div>
              {formData?.coverImage && (
                <div className="mt-2">
                  <div
                    key="albumCover"
                    className="flex items-center bg-gray-100 p-2 rounded-lg"
                  >
                    <img
                      src={formData?.coverImage}
                      alt="coverImage"
                      className="w-20 h-20 object-cover rounded-lg mr-2"
                    />
                    <span className="text-green-500">&#10003; Uploaded</span>
                    <div>
                      <button
                        onClick={removeAlbumCoverImage}
                        className="text-red-500 ml-4 hover:text-red-700 transition duration-300 ease-in-out"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {formErrors.coverImage && (
                <p className="text-red-500 mt-2">{formErrors?.coverImage}</p>
              )}
            </div>

            <div class="text-center ">
              <button
                class="mx-auto bg-blue-500 mb-4 text-white font-bold py-2 px-2 rounded"
                onClick={() => {
                  handleSubmit(galleryPopupEdit?.folderId);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </PopupWrapper>
  );
}
