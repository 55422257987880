import React from "react";

const UploadQueue = ({
  compressing,
  isExpanded,
  uploadedFiles,
  selectedFiles,
  toggleExpand,
  totalFiles,
  completedFiles,
  currentFile,
  currentFileProgress,
}) => {
  return (
    <div className="fixed max-h-[500px] w-[300px] bottom-4 right-10 flex flex-col z-50">
      <div className="bg-gray-900 text-white p-4 rounded-t-lg shadow-lg w-80 flex justify-between items-center cursor-pointer backdrop-blur-lg bg-opacity-80">
        <div className="flex gap-[8px]  items-center">
          <h3 className="font-semibold text-lg">Upload Progress</h3>
          <p className="text-sm text-gray-400">
            {completedFiles}/{totalFiles}
          </p>
        </div>
        <button
          onClick={toggleExpand}
          className="text-white  font-medium  rounded-lg "
        >
          {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 15 16"
              fill="none"
            >
              <mask id="mask0_1330_7047">
                <path
                  d="M7.33268 14.6663C11.0147 14.6663 13.9993 11.6817 13.9993 7.99967C13.9993 4.31767 11.0147 1.33301 7.33268 1.33301C3.65068 1.33301 0.666016 4.31767 0.666016 7.99967C0.666016 11.6817 3.65068 14.6663 7.33268 14.6663Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.33333"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.332 7L7.33203 10L4.33203 7"
                  stroke="black"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </mask>
              <g mask="url(#mask0_1330_7047)">
                <path d="M-0.667969 0H15.332V16H-0.667969V0Z" fill="#ffffff" />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 15 16"
              fill="none"
            >
              <mask id="mask0_1330_7135">
                <path
                  d="M7.33268 1.33366C11.0147 1.33366 13.9993 4.31833 13.9993 8.00033C13.9993 11.6823 11.0147 14.667 7.33268 14.667C3.65068 14.667 0.666016 11.6823 0.666016 8.00033C0.666016 4.31833 3.65068 1.33366 7.33268 1.33366Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.33333"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.332 9L7.33203 6L4.33203 9"
                  stroke="black"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </mask>
              <g mask="url(#mask0_1330_7135)">
                <path d="M-0.667969 16H15.332V0H-0.667969V16Z" fill="#ffffff" />
              </g>
            </svg>
          )}
          {/*  'Minimize' : 'Expand'} */}
        </button>
      </div>
      {isExpanded && (
        <div className="bg-gray-800 text-white px-[10px] py-[24px] rounded-b-lg shadow-lg w-80 max-h-[400px] overflow-y-auto backdrop-blur-lg bg-opacity-90 scrollbar-thin scrollbar-thumb-blue-600 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg">
          {currentFile && (
            <div className=" text-[#fff] items-center flex justify-between">
              <h4 className="text-sm font-medium ">
                {currentFile?.name?.length > 5
                  ? currentFile?.name?.slice(0, 5) +
                    currentFile?.name?.slice(
                      currentFile?.name?.lastIndexOf(".")
                    )
                  : currentFile?.name}
              </h4>
              {/* <div className="w-full bg-gray-700 rounded-full h-2.5">
                  <div
                    className="bg-blue-500 h-2.5 rounded-full"
                    style={{ width: `${currentFileProgress}%` }}
                  ></div>
                </div> */}
              <p className="text-sm ">{currentFileProgress}% Uploading</p>
            </div>
          )}
          {/* // <div className="mb-4">
            //   <h4 className="text-sm font-medium text-gray-300">
            //     Uploading:{" "}
            //     <span className="text-white">{currentFile.name}</span>
            //   </h4>
            //   <div className="w-full bg-gray-700 rounded-full h-2.5">
            //     <div
            //       className="bg-blue-500 h-2.5 rounded-full"
            //       style={{ width: `${currentFileProgress}%` }}
            //     ></div>
            //   </div>
            //   <p className="text-sm text-gray-400 mt-1">
            //     Progress: {currentFileProgress}%
            //   </p>
            // </div> */}

          {compressing ? (
            <div className="text-center text-yellow-500">
              Processing images...
            </div>
          ) : (
            !currentFile && (
              <div className="space-y-2">
                {selectedFiles?.map((item, index) => (
                  <p
                    key={index}
                    className="flex justify-between gap-[8px] items-center bg-gray-700 p-2 rounded-lg"
                  >
                    <span className="">
                      {item.name?.length > 5
                        ? item?.name?.slice(0, 5) +
                          item?.name?.slice(item?.name?.lastIndexOf("."))
                        : item?.name}
                    </span>
                    {uploadedFiles[item.name] && (
                      <span className="text-green-500 whitespace-nowrap">
                        ✔
                      </span>
                    )}
                  </p>
                ))}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default UploadQueue;
