import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";

const QRCodeWithGallery = () => {
  const { albumId, photoshareid } = useParams();
  const qrCodeRef = useRef(null);
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageUrl, setPageUrl] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://storage.googleapis.com/photosharetoolqr/${albumId}/${photoshareid}.json`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const studioUniqueText =
          data?.studioUniqueText === "photosharetool"
            ? "photosharetool"
            : data?.studioUniqueText;
        const slugId = data?.slugId;
        const name = data?.name;
        setJsonData(data);
        setPageUrl(
          `https://${studioUniqueText}.memshots.com/${slugId}/QRPhotoShare/${name}`
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [albumId, photoshareid]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(pageUrl).then(() => {
      setCopySuccess("Link copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    });
  };

  // Function to download the QR code
  const handleDownloadQRCode = async () => {
    const canvas = await html2canvas(qrCodeRef.current);
    const dataUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "qr-code.png";
    link.click();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="px-[30px] border-b-[1px] border-b-[#111] border-opacity-10 flex justify-between items-center py-[20px]">
        <div className="flex items-center gap-[12px]">
          <button className="iconWatermark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_1330_6994)">
                <path
                  d="M13.0007 6C13.0007 5.47471 13.1042 4.95457 13.3052 4.46927C13.5062 3.98396 13.8008 3.54301 14.1723 3.17157C14.5437 2.80014 14.9847 2.5055 15.47 2.30448C15.9553 2.10346 16.4754 2 17.0007 2C17.526 2 18.0461 2.10346 18.5314 2.30448C19.0167 2.5055 19.4577 2.80014 19.8291 3.17157C20.2006 3.54301 20.4952 3.98396 20.6962 4.46927C20.8972 4.95457 21.0007 5.47471 21.0007 6C21.0007 7.06087 20.5793 8.07828 19.8291 8.82843C19.079 9.57857 18.0616 10 17.0007 10C15.9398 10 14.9224 9.57857 14.1723 8.82843C13.4221 8.07828 13.0007 7.06087 13.0007 6ZM8.99669 8.144L13.4897 15.132L15.9257 11.233L24.0007 22H0.179688L8.99669 8.144Z"
                  fill="#373CBD"
                />
              </g>
              <defs>
                <clipPath id="clip0_1330_6994">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <p className="text-[18px] text-[#161616] font-[600]">
            Selected Images
          </p>
        </div>
        <div>
          <div className="flex items-center gap-[12px]">
            <button
              onClick={handleDownloadQRCode}
              className="whitespace-nowrap flex justify-center items-center setPrice"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4 14H12M8 2V11.3333M8 11.3333L11.3333 8M8 11.3333L4.66667 8"
                  stroke="#161616"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Download QR Code</p>
            </button>

            <button
              onClick={handleCopyLink}
              className="whitespace-nowrap flex justify-center items-center setPrice"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12.2428 10.3578L11.3002 9.41381L12.2428 8.47115C12.8634 7.84509 13.2108 6.99869 13.2088 6.11717C13.2069 5.23565 12.8559 4.39078 12.2325 3.76745C11.6092 3.14411 10.7643 2.79307 9.88281 2.79114C9.00129 2.78921 8.15489 3.13655 7.52884 3.75715L6.5855 4.70048L5.64284 3.75781L6.5855 2.81515C7.46072 1.93993 8.64776 1.44824 9.8855 1.44824C11.1232 1.44824 12.3103 1.93993 13.1855 2.81515C14.0607 3.69036 14.5524 4.87741 14.5524 6.11515C14.5524 7.35289 14.0607 8.53993 13.1855 9.41515L12.2428 10.3578ZM10.3568 12.2431L9.41417 13.1858C8.53896 14.061 7.35191 14.5527 6.11417 14.5527C4.87643 14.5527 3.68938 14.061 2.81417 13.1858C1.93896 12.3106 1.44727 11.1236 1.44727 9.88581C1.44727 8.64807 1.93896 7.46103 2.81417 6.58581L3.7575 5.64315L4.70017 6.58715L3.7575 7.52981C3.14542 8.15752 2.80528 9.00111 2.81077 9.87783C2.81627 10.7546 3.16695 11.5938 3.78685 12.2138C4.40676 12.8338 5.24597 13.1846 6.12269 13.1902C6.99941 13.1958 7.84304 12.8558 8.47084 12.2438L9.41417 11.3011L10.3568 12.2431ZM9.8855 5.17181L10.8288 6.11515L6.11417 10.8285L5.1715 9.88581L9.8855 5.17181Z"
                  fill="#161616"
                />
              </svg>
              <p>Copy Link</p>
            </button>
          </div>
          {copySuccess && (
            <p className="text-green-500 text-end mt-2">{copySuccess}</p>
          )}
        </div>
      </div>

      <div className="justify-between px-[5%]  flex items-center ">
        <div className="p-4 overflow-y-auto">
          {jsonData && jsonData.media?.length >= 0 && (
            <div>
              {/* <h2 className="text-xl font-semibold mb-2 text-start">
                Selected Images
              </h2> */}

              <div className="grid-cols-3 grid  gap-[16px]">
                {jsonData.media.map((image, index) => (
                  <div key={index} className="w-[210px]  h-[250px]">
                    <img
                      src={image}
                      alt={`Media ${index + 1}`}
                      className="object-cover w-full h-full  rounded-md"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className=" flex   justify-center items-center">
          {pageUrl && (
            <div className="mb-6 text-center">
              <div
                ref={qrCodeRef}
                className="inline-block bg-white p-4 rounded-lg shadow-lg"
              >
                <QRCode value={pageUrl} size={283} />
              </div>

              <p className="text-[21px] mt-[7px] font-[600] text-center px-[20%] ">
                Scan this QR to Download photos
              </p>

              {/* <div className="mt-4 flex justify-center space-x-4">
                <button
                  onClick={handleCopyLink}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Copy Link
                </button>
                <button
                  onClick={handleDownloadQRCode}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                >
                  Download QR Code
                </button>
              </div>
              {copySuccess && (
                <p className="text-green-500 mt-2">{copySuccess}</p>
              )} */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QRCodeWithGallery;
