import React, { useContext, useEffect, useState } from "react";
import "./Event.css";
import axios from "axios";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "../../Assets/Animation/Loading";
import EventForm from "../../Components/Forms/EventForm/EventForm";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Pagination from "../../Components/Pagination/Pagination";
import whiteAddButton from "../../Assets/whiteAddButton.svg";
import eventRightArrow from "../../Assets/eventRightArrow.svg";
import eventLocation from "../../Assets/eventLocation.svg";
import { isEmpty } from "lodash";
import NoData from "../../Components/NoData/NoData";
import ExceedPopup from "../../Components/ExceedPopup/ExceedPopup";

function Event() {
  const limit = 25;
  const navigate = useNavigate();
  const {
    studioId,
    studioRole,
    activePackages,
    getStudioStorageOfAService,
    roleSelected,
    projectData,
    setProjectDatas,
    filteredProjects,
  } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  // const [projectData, setProjectDatas] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState([]);
  const [update, setUpdate] = useState(false);
  const [eventForm, setEventForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [flexi, setFlexi] = useState("");
  const [isEventCreationExceed, setIsEventCreationExceed] = useState(false);
  const [filterType, setFilterType] = useState("All");

  useEffect(() => {
    verifyUser();
    if (studioId) {
      getAllEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, studioId, limit, currentPage]);

  const dayOfWeek = (date) => {
    const currentDate = new Date(date);
    let dayOfWeek = currentDate.toLocaleString("default", { weekday: "long" });
    if (dayOfWeek === "Monday") {
      dayOfWeek = "MON";
    } else if (dayOfWeek === "Tuesday") {
      dayOfWeek = "TUE";
    } else if (dayOfWeek === "Wednesday") {
      dayOfWeek = "WED";
    } else if (dayOfWeek === "Thursday") {
      dayOfWeek = "THU";
    } else if (dayOfWeek === "Friday") {
      dayOfWeek = "FRI";
    } else if (dayOfWeek === "Saturday") {
      dayOfWeek = "SAT";
    } else if (dayOfWeek === "Sunday") {
      dayOfWeek = "SUN";
    } else dayOfWeek = "";
    return dayOfWeek;
  };

  // const day = (date) => {
  //   const currentDate = new Date(date);
  //   const day = currentDate.getDate();
  //   return day;
  // };

  const day = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    const dayOfWeek = currentDate
      .toLocaleDateString("en-US", { weekday: "short" })
      .toUpperCase();
    const month = currentDate.toLocaleDateString("en-US", { month: "short" });
    const year = currentDate.getFullYear();

    return {
      day,
      dayOfWeek,
      month,
      year,
      // formattedDate: `${day} ${dayOfWeek} ${month} ${year}` // Returns the formatted date (e.g., "27 Sun Dec 2024")
    };
  };

  const getAllEvents = async () => {
    try {
      setLoading(true);
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesProject/api/shootmates/project/events/all/${studioId}?jobStatus=EVENT&limit=${limit}&page=${currentPage}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setTotalDocuments(response?.data?.totalDocuments);
        setTotalPages(Math.ceil(response?.data?.totalDocuments / limit));
        setProjectDatas(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleCreateEvent = (value) => {
    if (value) {
      setFlexi(value);
      setEventForm(true);
    } else {
      setFlexi("");
      setEventForm(true);
    }
  };

  const handleEventCreationExceed = (value) => {
    setFlexi(value);
    setEventForm(true);
    setIsEventCreationExceed(false);
  };

  const handleRefresh = () => {
    setUpdate(!update);
  };

  const filterAndSearchProjects = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let newfilteredProjects = filteredProjects;

    if (filterType === "Upcoming") {
      newfilteredProjects = filteredProjects?.filter((obj) => {
        const eventDate = new Date(obj?.startDate);
        return eventDate >= today;
      });
    } else if (filterType === "Recent") {
      newfilteredProjects = filteredProjects?.filter((obj) => {
        const eventDate = new Date(obj?.startDate);
        return eventDate < today;
      });
    }

    return newfilteredProjects;
  };

  const filteredProjectsByDate = filterAndSearchProjects();

  return (
    <div className="eventContainer min-h-[100vh] pb-10">
      {/* <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[{ label: "Event", url: "/Event" }]}
      /> */}
      {isEventCreationExceed && (
        <ExceedPopup
          handleEventCreationExceed={handleEventCreationExceed}
          onClose={() => setIsEventCreationExceed(false)}
        />
      )}
      {eventForm ? (
        <EventForm
          update={update}
          setUpdate={setUpdate}
          visible={eventForm}
          closePopup={setEventForm}
          isFlexi={flexi || ""}
        />
      ) : (
        <>
          {!isEmpty(projectData) && (
            <div className="flex justify-between p-[24px] items-center">
              <div className="z-[100]">
                <p className="font_semibold_20 text-[#161616] mb-[6px]">
                  Events
                </p>
                <p className="font_normal_14 text-[#161616]">
                  See your scheduled events, upcoming events & running events
                </p>
              </div>
              {/* <button
                onClick={() => handleCreateEvent()}
                className="eventButton flex items-center gap-[6px] "
              >
                <img
                  src={whiteAddButton}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <p className="font_semibold_12 ">Create an event</p>
              </button> */}

              <div className="mr-[5.3%] flex z-[20] items-center gap-[20px]">
                <button
                  onClick={handleRefresh}
                  type="button"
                  className="refreshButEvents"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0643 15.8333C8.43596 15.8333 7.05651 15.2686 5.92596 14.1392C4.79596 13.0092 4.23096 11.6303 4.23096 10.0025C4.23096 8.37472 4.79596 6.99528 5.92596 5.86416C7.05596 4.73305 8.4354 4.16722 10.0643 4.16666C11.0548 4.16666 11.9823 4.40194 12.8468 4.8725C13.7107 5.34305 14.4071 6 14.936 6.84333V4.16666H15.7693V8.525H11.411V7.69166H14.4943C14.0598 6.86111 13.4493 6.20472 12.6626 5.7225C11.876 5.24028 11.0098 4.99944 10.0643 5C8.6754 5 7.49485 5.48611 6.52262 6.45833C5.5504 7.43055 5.06429 8.61111 5.06429 10C5.06429 11.3889 5.5504 12.5694 6.52262 13.5417C7.49485 14.5139 8.6754 15 10.0643 15C11.1337 15 12.099 14.6944 12.9601 14.0833C13.8212 13.4722 14.4254 12.6667 14.7726 11.6667H15.6576C15.2898 12.9144 14.5915 13.9208 13.5626 14.6858C12.5337 15.4508 11.3676 15.8333 10.0643 15.8333Z"
                      fill="#979797"
                    />
                  </svg>
                  Refresh
                </button>
                <div className="flex relative flex-col">
                  <button
                    onClick={() => {
                      activePackages?.noOfEvents
                        ? activePackages?.noOfEventsUsed >=
                          activePackages?.noOfEvents
                          ? setIsEventCreationExceed(true)
                          : handleCreateEvent(
                              activePackages?.packageDetails?.packageType
                                ? activePackages?.packageDetails?.packageType
                                : "FLEXI"
                            )
                        : handleCreateEvent(
                            activePackages?.packageDetails?.packageType
                              ? activePackages?.packageDetails?.packageType
                              : "FLEXI"
                          );
                    }}
                    className="eventButton disabled:opacity-30 flex items-center gap-[6px] "
                  >
                    <img
                      src={whiteAddButton}
                      alt=""
                      className="w-[20px] h-[20px]"
                    />
                    <p className="font_semibold_12 ">Create an event</p>
                  </button>
                </div>

                {/* {activePackages?.activePackage === true ? (
                  <button
                    onClick={() => handleCreateEvent()}
                    className="eventButton flex items-center gap-[6px] "
                  >
                    <img
                      src={whiteAddButton}
                      alt=""
                      className="w-[20px] h-[20px]"
                    />
                    <p className="font_semibold_12 ">Create an event</p>
                  </button>
                ) : (
                  <div className="gap-[20px] flex items-center justify-center">
                    <button
                      // onClick={() => handleCreateEvent("")}
                      onClick={() => navigate(`/choosepackage`)}
                      className="eventButton "
                    >
                      Choose Package
                    </button>
                    <button
                      onClick={() => handleCreateEvent("FLEXI")}
                      className="eventButton "
                    >
                      Continue with flexi
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          )}
          <div className="seperatorHorizontalNew"></div>
          {loading ? (
            <>
              <Lottie />
            </>
          ) : (
            <div className="w-full h-full  px-[20px]">
              {projectData?.length > 0 && (
                <div className="flex justify-between items-center">
                  <div className="my-[10px] z-[100] w-[280px] flex items-center justify-center gap-[10px] px-[6px] py-[2px] dateFilter">
                    {["All", "Upcoming", "Recent"]?.map((item) => (
                      <button
                        className={`px-[22px] py-[2px] buttonDate  ${
                          filterType === item ? "bg-[#fff]" : ""
                        }`}
                        onClick={() => setFilterType(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>

                  <div>
                    <p className="eventsLength items-center flex">
                      {Math.min((currentPage - 1) * limit + 1, totalDocuments)}{" "}
                      - {Math.min(currentPage * limit, totalDocuments)} of{" "}
                      {totalDocuments}
                    </p>
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-[14px]">
                {filteredProjectsByDate && filteredProjectsByDate?.length > 0
                  ? filteredProjectsByDate?.map((obj, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            navigate(`/Event/${obj?._id}`);
                          }}
                          className="eventTable cursor-pointer items-center pt-[20px] pb-[15px] pl-[20px] pr-[15px] border-[1px] border-[#D5D5D5] rounded-[8px] flex"
                        >
                          <div className="dateWidth  pr-[15px] sideSeperator flex  flex-col gap-[3px] items-center">
                            <p
                              className={`font_medium_14 ${
                                dayOfWeek(obj?.startDate) === "SUN"
                                  ? "text-[#E34D4D]"
                                  : "text-[#161616B2]"
                              }`}
                            >
                              {day(obj?.startDate)?.dayOfWeek}
                            </p>
                            <p
                              className={`font_medium_32  ${
                                dayOfWeek(obj?.startDate) === "SUN"
                                  ? "text-[#E34D4D]"
                                  : "text-[#161616B2]"
                              }`}
                            >
                              {day(obj?.startDate)?.day}
                            </p>

                            <p
                              className={` text-[14px]  ${
                                dayOfWeek(obj?.startDate) === "SUN"
                                  ? "text-[#E34D4D]"
                                  : "text-[#161616B2]"
                              }`}
                            >
                              {day(obj?.startDate)?.month},
                              {day(obj?.startDate)?.year}
                            </p>
                          </div>

                          <div className="eventNameWidth sideSeperator pl-[14px] flex flex-col gap-[16px]">
                            <p className="font_medium_12 text-black">
                              {obj?.eventType}
                            </p>
                            <p className="font_semibold_18 text-[#161616B2] ">
                              {obj?.leadName}
                            </p>
                            <p className="text-xs  text-[#161616] font-[400] ">
                              {obj?.packageType?.charAt(0).toUpperCase() +
                                obj?.packageType?.slice(1).toLowerCase()}{" "}
                              Package{" "}
                            </p>
                          </div>
                          <div className="eventLocationWidth  pl-[10px] flex gap-[9px] items-center">
                            <img
                              src={eventLocation}
                              alt=""
                              className="w-[20px] h-[20px]"
                            />
                            <p className="font_normal_14 text-[#161616]">
                              {obj?.location}
                            </p>
                          </div>
                          <div className="eventDetailsWidth flex gap-[8px] items-center pl-[10px]">
                            <p className="font_normal_14 text-[#161616]">
                              Go to event details
                            </p>
                            <img
                              src={eventRightArrow}
                              alt=""
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </div>
                      );
                    })
                  : projectData?.length > 0 && (
                      <div className="">
                        {" "}
                        <NoData text={"No Events"} />{" "}
                      </div>
                    )}
              </div>

              {projectData && projectData?.length > 0 ? (
                projectData?.length > 24 && currentPage === 1 ? (
                  <div>
                    <Pagination
                      limit={limit}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      dataLength={totalDocuments}
                    />
                  </div>
                ) : (
                  currentPage > 1 && (
                    <div>
                      <Pagination
                        limit={limit}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        dataLength={totalDocuments}
                      />
                    </div>
                  )
                )
              ) : (
                <>
                  {studioId ? (
                    <div className="">
                      {/* <NoData
                        text={
                          "Create your first event and unlock a world of memories. Make your day even better with Memshots — your ultimate event companion."
                        }


                      /> */}

                      <div className="absolute inset-0 left-[10%] flex justify-center items-center flex-col">
                        <div className="relative ">
                          <svg
                            className="absolute right-0 -top-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <circle
                              cx="4"
                              cy="4"
                              r="4"
                              transform="matrix(-1 0 0 1 8 0)"
                              fill="#13BB82"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <circle
                              cx="16"
                              cy="16"
                              r="15"
                              transform="matrix(-1 0 0 1 32 0)"
                              stroke="#161616"
                              stroke-width="2"
                            />
                          </svg>
                        </div>
                        <p className="NoDataHead"> Welcome to Memshots</p>
                        <p className="NoDataText mx-[30%]">
                          Create your first event and unlock a world of
                          memories. Make your day even better with Memshots —
                          your ultimate event companion.
                        </p>
                        <button
                          onClick={() =>
                            handleCreateEvent(
                              activePackages?.packageDetails?.packageType
                                ? activePackages?.packageDetails?.packageType
                                : "FLEXI"
                            )
                          }
                          className="eventButton mt-4 flex items-center gap-[6px] "
                        >
                          <img
                            src={whiteAddButton}
                            alt=""
                            className="w-[20px] h-[20px]"
                          />
                          <p className="font_semibold_12 ">Create an event</p>
                        </button>
                      </div>

                      {/* {activePackages?.activePackage === true ? (
                        <button
                          onClick={() => handleCreateEvent()}
                          className="eventButton flex items-center gap-[6px] "
                        >
                          <img
                            src={whiteAddButton}
                            alt=""
                            className="w-[20px] h-[20px]"
                          />
                          <p className="font_semibold_12 ">Create an event</p>
                        </button>
                      ) : (
                        <div className="gap-[50px] flex items-center justify-center">
                          <button
                            // onClick={() => handleCreateEvent("")}
                            onClick={() => navigate(`/choosepackage`)}
                            className="eventButton "
                          >
                            Choose Package
                          </button>
                          <button
                            onClick={() => handleCreateEvent("FLEXI")}
                            className="eventButton "
                          >
                            Continue with flexi
                          </button>
                        </div>
                      )} */}
                    </div>
                  ) : (
                    <div className="w-full h-full flex flex-col gap-[20px] items-center justify-center">
                      <p className="font_medium_22">
                        Please create your studio profile to get started.
                      </p>
                      <Link
                        to="/organizations"
                        state={{
                          buttonAction: "createOrganisation",
                        }}
                      >
                        <button className="eventButton flex items-center gap-[6px] ">
                          <img
                            src={whiteAddButton}
                            alt=""
                            className="w-[20px] h-[20px]"
                          />
                          <p className="font_semibold_12 ">
                            Create Organisation
                          </p>
                        </button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Event;
