import React from "react";

function AdvanceSettingsPho({
  setOpenAdvSettings,
  handleAlbumMetaDataButton,
  handleUserListingButton,
  setsessionForm,
  setAddWatermarkPopup,
}) {
  return (
    <div className="fixed bg-gray-900 bg-opacity-75 z-[100] top-0 right-0 bottom-0 left-0 justify-center items-center  flex mx-auto   ">
      <div className="bg-[#fff]   -mt-[13%] pop  rounded-[10px] flex flex-col p-[15px]    ">
        <div className="flex items-end justify-end">
          {/* <p className="text-[18px]">Advance Settings</p>*/}
          <button onClick={() => setOpenAdvSettings(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="text-[16px] flex flex-col justify-start items-start">
        <div
            onClick={handleAlbumMetaDataButton}
            className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
          >
            {/* <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" /> */}
            <p className="text-[16px] font-[400] text-[#161616]">Settings</p>
          </div>
          {/* <div
              onClick={() => {
                setsessionForm(true);
              }}
              className="shortButton"
            >
              <img src={options} alt="" className="w-[16px] h-[16px]" /> 
              <p className="font_normal_11 text-[#161616]">Create folder</p>
            </div> */}
          <div
            onClick={() => {
              setAddWatermarkPopup(true);
            }}
            className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
          >
            {/* <img src={options} alt="" className="w-[16px] h-[16px]" /> */}
            <p className=" font-[400] text-[#161616]">Add Watermark</p>
          </div>
          <div
            onClick={handleUserListingButton}
            className=" hover:border-opacity-100 cursor-pointer flex gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
          >
            {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
            <p className=" font-[400] text-[#161616]">View / Manage Crew</p>
          </div>
        </div>
        <div className="flex items-center gap-[16px]">
         
          {/* <div
              onClick={() => {
                setUpdate(!update);
              }}
              className="shortButton"
            >
              <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
              <p className="font_normal_11 text-[#161616]">Refresh</p>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default AdvanceSettingsPho;
