import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items, update, setUpdate }) => {
  const handleRefresh = () => {
    setUpdate(!update);
  };
  return (
    <>
      <nav
        className="flex mt-[10px] items-center gap-[10px] ml-[10px] rounded-[8px]"
        aria-label="Breadcrumb "
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse rounded-[8px]">
          {items.map((item, index) => (
            <li key={index} className="border-none p-0">
              <div className="flex items-center">
                {index !== 0 && (
                  <svg
                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1 rounded-[8px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                )}
                {item.url ? (
                  <Link
                    to={item.url}
                    className="ms-1 text-sm font-medium  text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-black rounded-[8px]"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <span className="ms-1 text-sm font-medium  text-gray-500 md:ms-2 dark:text-gray-400 rounded-[8px]">
                    {item.label}
                  </span>
                )}
              </div>
            </li>
          ))}

          <Link
            to={items[items?.length - 1]?.url}
            type="button"
            className=" hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-black rounded-[8px] text-sm font-medium flex items-center justify-center cursor-pointer   p-[6px]  "
          >
            <svg
              className=" w-3 h-3  mx-1 rounded-[8px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
              transform="rotate(180)"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>{" "}
            Back
          </Link>
        </ol>
        <svg
          onClick={() => setUpdate(!update)}
          className="flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
            stroke="#4D58E3"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6667 3.33301V7.49967H12.5"
            stroke="#4D58E3"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </nav>
      {/* <div className="mt-[10px] ml-[10px]">
        <button
          onClick={handleRefresh}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-8 py-1 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Refresh
        </button>
     
      </div> */}
    </>
  );
};

export default Breadcrumb;
