import React, { useState, useRef, useEffect } from "react";

const WatermarkCanvas = () => {
  const [mainImage, setMainImage] = useState(null);
  const [watermarkUrl, setWatermarkUrl] = useState("");
  const [position, setPosition] = useState("top-left");
  const canvasRef = useRef(null);

  const sizeRespToMainImage = 0.17;
  const paddingRespectiveToMainImage = 0.017;
  const paddingMarginHorizontal=1.7

  const getSizeMaintainingAspectRatio = (image, maxWidth, maxHeight) => {
    const imageWidth = image.width;
    const imageHeight = image.height;
    // Let's find the max available width for the scaled image
    var ratio = imageWidth / imageHeight;
    var aspectWidth = Math.min(maxWidth, ratio * maxHeight);
    var aspectHeight = Math.floor(aspectWidth / ratio);

    return {
      width: imageWidth > aspectWidth ? aspectWidth : imageWidth,
      height: imageHeight > aspectHeight ? aspectHeight : imageHeight,
    };
  };

  const getWatermarkSize = (image, width, height) => {
    if (image.width < image.height) {
      return getSizeMaintainingAspectRatio(image, width, height);
    } else {
      return getSizeMaintainingAspectRatio(image, height, width);
    }
  };

  const getWatermarkRect = (mainImage, watermark, position) => {
    const sizeRespectToMainImage = sizeRespToMainImage;
    let watermarkHeight;
    let watermarkWidth;
    let padding;

    if (mainImage.width > mainImage.height) {
      watermarkHeight = mainImage.height * sizeRespectToMainImage;
      watermarkWidth = mainImage.width * sizeRespectToMainImage;
      padding = mainImage.width * paddingRespectiveToMainImage; // Padding factor
    } else {
      watermarkHeight = mainImage.width * sizeRespectToMainImage;
      watermarkWidth = mainImage.height * sizeRespectToMainImage;
      padding = mainImage.height * paddingRespectiveToMainImage; // Padding factor
    }

    const watermarkSize = getWatermarkSize(
      watermark,
      watermarkWidth,
      watermarkHeight
    );

    switch (position) {
      case "top-left":
        return {
          x: padding * paddingMarginHorizontal,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "top-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * paddingMarginHorizontal,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-left":
        return {
          x: padding * paddingMarginHorizontal,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * paddingMarginHorizontal,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      default:
        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        };
    }
  };

  // Handle the main image file input
  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMainImage(URL.createObjectURL(file)); // Create URL for the main image
    }
  };

  // Handle the watermark URL input
  const handleWatermarkUrlChange = (e) => {
    setWatermarkUrl(e.target.value);
  };

  // Apply watermark to the main image on the canvas
  const applyWatermark = () => {
    if (mainImage && watermarkUrl) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const mainImageObj = new Image();
      const watermarkObj = new Image();

      mainImageObj.src = mainImage;
      watermarkObj.src = watermarkUrl;

      // Wait for both images to load
      mainImageObj.onload = () => {
        // Set the canvas dimensions to match the main image
        canvas.width = mainImageObj.width;
        canvas.height = mainImageObj.height;

        // Draw the main image on the canvas
        ctx.drawImage(mainImageObj, 0, 0);

        watermarkObj.onload = () => {
          const getWatermarkPositions = getWatermarkRect(
            mainImageObj,
            watermarkObj,
            position
          );
          console.log("getWatermarkPositions", getWatermarkPositions);
          // Draw the watermark on the canvas with padding
          ctx.drawImage(
            watermarkObj,
            getWatermarkPositions?.x,
            getWatermarkPositions?.y,
            getWatermarkPositions?.width,
            getWatermarkPositions?.height
          );
        };

        // Handle image loading errors
        watermarkObj.onerror = () => {
          console.error("Watermark image failed to load.");
        };
      };

      // Handle image loading errors
      mainImageObj.onerror = () => {
        console.error("Main image failed to load.");
      };
    }
  };

  return (
    <div>
      <h1>Watermark Image on Main Image</h1>

      <div>
        <label>
          Select Main Image:
          <input
            type="file"
            accept="image/jpeg"
            onChange={handleMainImageChange}
          />
        </label>
      </div>

      <div>
        <label>
          Watermark Image URL:
          <input
            type="text"
            value={watermarkUrl}
            onChange={handleWatermarkUrlChange}
            placeholder="Enter watermark image URL"
          />
        </label>
      </div>

      <div>
        <label>
          Select Position:
          <select
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            <option value="top-left">Top Left</option>
            <option value="top-right">Top Right</option>
            <option value="bottom-left">Bottom Left</option>
            <option value="bottom-right">Bottom Right</option>
          </select>
        </label>
      </div>

      <button onClick={applyWatermark}>Apply Watermark</button>

      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default WatermarkCanvas;
