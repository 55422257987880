import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "../../Assets/Animation/LoadingWhite";
import { dataContext } from "../../Context/DataContext";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import "./UserManagement.css";
import AddCrewForm from "../../Components/Forms/AddCrewForm/AddCrewForm";

const UserManagement = () => {
  const [addCrewPopup, setAddCrewPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [loadingText, setLoadingText] = useState(null);
  const [loading, setLoading] = useState(false);
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [update, setUpdate] = useState(false);
  const [crewData, setcrewData] = useState([]);
  useEffect(() => {
    verifyUser();
    getUserCrewDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const getUserCrewDatas = async () => {
    setLoading(true);
    setLoadingText("Fetching data..");
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/userslists/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setcrewData(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  
  const deleteCrew = async (userId) => {
    try {
      setLoading(true);
      setLoadingText("Removing Crew..");
      verifyUser();
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/removeCrewUser/${studioId}/${userId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setLoadingText("Crew Removed..");
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
        setUpdate(!update);
        setLoadingText(null);
      } else {
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: response?.data?.msg,
        });
        setLoadingText(null);
      }
    } catch (error) {
      setLoading(false);
      setMessagePopup({
        visible: true,
        type: "success",
        message: "Request failed please try again..",
      });
      console.log("error", error);
      setLoadingText(null);
    }
  };
  const sendEmailReset = async (email) => {
    try {
      setLoading(true);
      setLoadingText("Generating Request..");
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/passwordReset/${studioId}`,
        data: {
          email: email,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoadingText("Crew Removed..");
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
        setUpdate(!update);
        setLoadingText(null);
      } else {
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: response?.data?.msg,
        });
        setLoadingText(null);
      }
    } catch (error) {
      setLoading(false);
      setMessagePopup({
        visible: true,
        type: "success",
        message: "Request failed please try again..",
      });
      console.log("error", error);
      setLoadingText(null);
    }
  };

  return (
    <div className="flex flex-col">
      {addCrewPopup && (
        <AddCrewForm
          visible={addCrewPopup}
          setpopUp={setAddCrewPopup}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      {messagePopup?.visible && (
        <MessagePopup
          messagePopup={messagePopup}
          setMessagePopup={setMessagePopup}
        />
      )}
      <div className="payments-container">
        <h2 className="text-2xl italic font-bold">Crew Management</h2>
      </div>
      <div>
        <button
          className="ml-[10px] select-none rounded-lg bg-green-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() => {
            setUpdate(!update);
          }}
        >
          Refresh
        </button>
        <button
          className="ml-[10px] select-none rounded-lg bg-blue-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() => {
            setAddCrewPopup(true);
          }}
        >
          Add User
        </button>
      </div>
      <div class="relative overflow-x-auto ml-[10px] mt-[10px]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3 bg-[]">
                Name
              </th>
              <th scope="col" class="px-6 py-3">
                Role
              </th>
              <th scope="col" class="px-6 py-3">
                Email
              </th>
              <th scope="col" class="px-6 py-3">
                User Action
              </th>
              <th scope="col" class="px-6 py-3">
                Reset Password
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Lottie
                loadingText={
                  loadingText !== null ? loadingText : "Fetching data.."
                }
              />
            ) : crewData && crewData?.length > 0 ? (
              crewData?.map((el) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {el?.userRolesAndAccess?.userName}
                  </th>
                  <td className="px-6 py-4">
                    {el?.userRolesAndAccess?.studioRole}
                  </td>
                  <td className="px-6 py-4">{el?.userRolesAndAccess?.email}</td>
                  <td className="px-6 py-4">
                    <button
                      class="select-none rounded-lg bg-red-500 py-2 px-5 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      onClick={() => {
                        deleteCrew(el?.userRolesAndAccess?.userId);
                      }}
                    >
                      Remove User
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      class="select-none rounded-lg bg-cyan-500 py-2 px-5 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-cyan-500/20 transition-all hover:shadow-lg hover:shadow-cyan-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      onClick={() => {
                        sendEmailReset(el?.userRolesAndAccess?.email);
                      }}
                    >
                      Reset Password
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colspan="4"
                  className="px-6 py-4 text-center text-red-700 dark:text-red-400"
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagement;
