import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { dataContext } from "../../Context/DataContext";
import NoData from "../../Components/NoData/NoData";
import Lottie from "../../Assets/Animation/Loading";

function Storage() {
  const { tokenData, roleSelected, totalStorageGb, activePackages } =
    useContext(dataContext);

  const [storageData, setStorageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStudioStorage = async (studioId, packageType) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/totalstorage/${studioId}?packageType=${packageType}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      return { packageType, ...response.data };
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  const bytesToHumanReadable = (bytes) => {
    if (bytes >= 1024 ** 3) {
      return `${(bytes / 1024 ** 3).toFixed(2)} GB`;
    }
    return `${(bytes / 1024 ** 2).toFixed(2)} MB`;
  };

  const bytesToGB = (bytes) => bytes / 1024 ** 3;

  const fetchAllStorageData = async (studioId) => {
    const packageTypes = ["YEARLY", "FLEXI", "TRIAL"];

    setLoading(true);

    const promises =
      activePackages?.packageStatus === "ACTIVE"
        ? activePackages?.packageDetails?.packageType === "TRIAL"
          ? packageTypes
              .slice(1, 3)
              .map((type) => getStudioStorage(studioId, type)) // "FLEXI" and "TRIAL"
          : packageTypes
              .slice(0, 2)
              .map((type) => getStudioStorage(studioId, type)) // "YEARLY" and "FLEXI"
        : packageTypes
            .slice(1, 2)
            .map((type) => getStudioStorage(studioId, type)); // Only "FLEXI" for the last case

    const results = await Promise.all(promises);
    setStorageData(results);
    setLoading(false);
  };

  const calculateStorageDetails = (totalMediaSize) => {
    const usedPercentage =
      totalStorageGb && totalStorageGb > 0
        ? ((bytesToGB(totalMediaSize || 0) / totalStorageGb) * 100).toFixed(2)
        : null;

    const usedSize = bytesToHumanReadable(totalMediaSize || 0);

    return { totalStorageGb, usedPercentage, usedSize };
  };

  useEffect(() => {
    if (roleSelected?.studioId) {
      fetchAllStorageData(roleSelected?.studioId);
    }
  }, [roleSelected?.studioId]);

  return (
    <div className="p-[20px]">
      <p className="text-[30px] font-[500] mb-[16px]">Storage Details</p>
      {loading ? (
        <Lottie />
      ) : (
        <table className="w-full border-collapse border border-gray-300 text-left">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-[12px] py-[8px]">
                Package Type
              </th>
              <th className="border border-gray-300 px-[12px] py-[8px]">
                Usage
              </th>
              <th className="border border-gray-300 px-[12px] py-[8px]">
                Progress
              </th>
            </tr>
          </thead>
          <tbody>
            {storageData.map((data, index) => {
              const storageDetails = calculateStorageDetails(
                data?.totalMediaSize
              );

              return (
                <tr key={index} className="bg-white">
                  <td className="border border-gray-300 px-[12px] py-[8px]">
                    {data.packageType?.charAt(0).toUpperCase() +
                      data.packageType?.slice(1).toLowerCase()}{" "}
                  </td>
                  <td className="border border-gray-300 px-[12px] py-[8px]">
                    {data?.packageType === "YEARLY" ? (
                      <>
                        {storageDetails.usedSize} used of{" "}
                        {totalStorageGb === null
                          ? "Unlimited GB"
                          : `${totalStorageGb} GB`}
                      </>
                    ) : (
                      <>{storageDetails.usedSize} used</>
                    )}
                  </td>
                  {data?.packageType === "YEARLY" ? (
                    <td className="border border-gray-300 px-[12px] py-[8px]">
                      <div className="w-full bg-[#DDD] h-[8px] rounded-[4px] relative">
                        <div
                          className="bg-[#4D58E3] h-[8px] rounded-[4px]"
                          style={{
                            width: `${storageDetails.usedPercentage || 0}%`,
                          }}
                        ></div>
                      </div>
                      <span className="text-[12px] text-gray-500 mt-[4px] inline-block">
                        {storageDetails.usedPercentage || 0}%
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Storage;
