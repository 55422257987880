import React, { useContext, useState, useEffect, useRef } from "react";
import Lottie from "../../Assets/Animation/Loading";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import axios from "axios";
import MetaDataPopupSelectionTool from "../../Components/MetaDataPopup/MetaDataPopupSelectionTool";
import SelectionFolderCreate from "../../Components/Forms/SelectionFolderCreateForm/SelectionFolderCreate";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
import CrewUsers from "../../Components/PopUps/CrewUsers";
import "./PhotoShareTool.css";
//import albumThumb from "../../Assets/albumThumn.png";
import options from "../../Assets/NavbarIcons/options.svg";
import userIcon from "../../Assets/userIcon.svg";
import photoShareIcon from "../../Assets/photoshare.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import refresshIcon from "../../Assets/refresshIcon.svg";
import fileUploadIcon from "../../Assets/fileUploadIcon.svg";
import AlbumDetailDropdown from "../../Components/AlbumDetailDropdown/AlbumDetailDropdown";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import ImageGalleryPopup from "../../Components/ImageGalleryPopup/ImageGalleryPopup";
import Watermark from "../../Components/Watermark/Watermark";
import AdvanceSettingsPho from "../../Components/advanceSettings/AdvanceSettingsPho";

function PhotoShareTool() {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState({
    show: false,
    folderId: "null",
  });
  const [compressType, setCompressType] = useState("compress");
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [sessionForm, setsessionForm] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [addWatermarkPopup, setAddWatermarkPopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({});
  const [openAdvSettings, setOpenAdvSettings] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId } = useContext(dataContext);
  const Ref = useRef(null);

  useEffect(() => {
    Ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getAlbumDetails(), getGAlleryData()]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        //setAuthorisedUsers(response?.data?.data?.authorisedUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGAlleryData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_SECTION_DATAS}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setalbmTrainingSession(response?.data?.data?.sections);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async () => {
    try {
      verifyUser();
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      const isCompress = compressType === "compress" ? true : false;
      let url =
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;
      // selectedUploadType === "GOOGLE_DRIVE"
      //   ? `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
      //   : `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

      let config = {
        method: "post",
        url: url,
        data: {
          ...formData,
          title: formData?.folderName,
          description: "photo share tool",
          coverImage: "photo share tool",
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setsessionForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setsessionForm(false);
      setUpdate(!update);
    }
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleGalleryPopupEdit = (folderId, divData) => {
    const initialFormData = {
      title: divData?.title,
      description: divData?.description,
      coverImage: divData?.coverImage,
    };
    setFormData(initialFormData);
  };

  const triggerGDriveUpload = async (sectionId) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncgdriveupload/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          sectionId: sectionId,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleCompressTypeChange = (event) => {
    setCompressType(event.target.value);
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleDelete = async (folderId) => {
    setIsLoading(true);
    try {
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/deletefolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        data: {
          folderId: folderId,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setShowDeletePopup({ show: false, folderId: "null" });
        setUpdate(!update);
      }
    } catch (err) {
      console.error("Error deleting folder:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const openuserWindow = () => {
    const origin = window.location.origin;
    const url = `${origin}/Event/${projectId}/photosharetool/${shootmatesAlbumId}/userselection`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (
      addUserPopup ||
      addWatermarkPopup ||
      viewUserListPopup ||
      metaDataPopup ||
      sessionForm
    ) {
      setOpenAdvSettings(false);
    }
  }, [
    addUserPopup,

    addWatermarkPopup,
    viewUserListPopup,
    metaDataPopup,

    sessionForm,
  ]);

  return (
    <div ref={Ref} className="albumDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopupSelectionTool
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          {isOpenGallery && (
            <ImageGalleryPopup
              visible={isOpenGallery}
              setIsOpenGallery={setIsOpenGallery}
              albumId={albumDetails?.memshotAlbumId}
              projectId={projectId}
              shootmatesAlbumId={shootmatesAlbumId}
              studioId={studioId}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {addWatermarkPopup && (
            <Watermark
              setAddWatermarkPopup={setAddWatermarkPopup}
              projectId={projectId}
              studioId={studioId}
              albumId={shootmatesAlbumId}
              visible={addWatermarkPopup}
              setOpenAdvSettings={setOpenAdvSettings}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {sessionForm && (
            <SelectionFolderCreate
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={sessionForm}
              handleCompressTypeChange={handleCompressTypeChange}
              compressType={compressType}
              setpopUp={setsessionForm}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              setError={setError}
            />
          )}
          {openAdvSettings && (
            <AdvanceSettingsPho
              setOpenAdvSettings={setOpenAdvSettings}
              handleAlbumMetaDataButton={handleAlbumMetaDataButton}
              handleUserListingButton={handleUserListingButton}
              setsessionForm={setsessionForm}
              setAddWatermarkPopup={setAddWatermarkPopup}
            />
          )}

          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <div className="eventDetailSideSeperator px-[15px]">
                <img
                  src={photoShareIcon}
                  alt=""
                  className="w-[62px] h-[62px]"
                />
              </div>

              <div className=" flex flex-col ml-[10px] gap-[8px]">
                <p className="albumType">{albumDetails?.type} </p>
                <p className="flex  items-center gap-1 font_semibold_20 text-[#161616]">
                  {albumDetails?.albumName}{" "}
                  {/* <svg
                    onClick={() => setUpdate(!update)}
                    className="flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
                      stroke="#4D58E3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.6667 3.33301V7.49967H12.5"
                      stroke="#4D58E3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}
                </p>

                {albumDetails?.totalMedias && (
                  <p className="text-[14px] text-[#8C8C8C]">
                    {" "}
                    Total Photos
                    <span className="text-[#111] ml-[5px]">
                      {albumDetails?.totalMedias}{" "}
                    </span>{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="gap-[20px] flex ">
              <button
                onClick={() => {
                  setsessionForm(true);
                }}
                className="advsettingdFace "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    opacity="0.4"
                    d="M13.6595 6.22617C13.6462 5.90617 13.5928 5.59284 13.4928 5.29284C13.0328 3.85284 11.6862 2.81284 10.0995 2.81284H8.2395C7.85284 2.81284 7.82617 2.77283 7.61951 2.4995L6.6862 1.2595C6.25286 0.679501 5.91284 0.326172 4.82617 0.326172H3.27283C1.64616 0.326172 0.326172 1.64617 0.326172 3.27284V5.28616V10.0928C0.326172 12.0595 1.9262 13.6595 3.89286 13.6595H10.0929C12.0595 13.6595 13.6595 12.0595 13.6595 10.0928V6.37283C13.6662 6.33283 13.6662 6.27283 13.6595 6.22617Z"
                    fill="#4D58E3"
                  />
                </svg>
                <p className="font_normal_11 text-[#161616]">Create folder</p>
              </button>

              <button
                onClick={() => setOpenAdvSettings(true)}
                className="advsettingdFace "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                    stroke="#8C8C8C"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.33301 8.5863V7.41297C1.33301 6.71963 1.89967 6.1463 2.59967 6.1463C3.80634 6.1463 4.29967 5.29297 3.69301 4.2463C3.34634 3.6463 3.55301 2.8663 4.15967 2.51963L5.31301 1.85963C5.83967 1.5463 6.51967 1.73297 6.83301 2.25963L6.90634 2.3863C7.50634 3.43297 8.49301 3.43297 9.09967 2.3863L9.17301 2.25963C9.48634 1.73297 10.1663 1.5463 10.693 1.85963L11.8463 2.51963C12.453 2.8663 12.6597 3.6463 12.313 4.2463C11.7063 5.29297 12.1997 6.1463 13.4063 6.1463C14.0997 6.1463 14.673 6.71297 14.673 7.41297V8.5863C14.673 9.27963 14.1063 9.85297 13.4063 9.85297C12.1997 9.85297 11.7063 10.7063 12.313 11.753C12.6597 12.3596 12.453 13.133 11.8463 13.4796L10.693 14.1396C10.1663 14.453 9.48634 14.2663 9.17301 13.7396L9.09967 13.613C8.49967 12.5663 7.51301 12.5663 6.90634 13.613L6.83301 13.7396C6.51967 14.2663 5.83967 14.453 5.31301 14.1396L4.15967 13.4796C3.55301 13.133 3.34634 12.353 3.69301 11.753C4.29967 10.7063 3.80634 9.85297 2.59967 9.85297C1.89967 9.85297 1.33301 9.27963 1.33301 8.5863Z"
                    stroke="#8C8C8C"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                Manage Contents
              </button>
              <button onClick={openuserWindow} className="advsettingdFace ">
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                Open QR Window
              </button>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          {/* <div className="px-[20px] py-[16px] flex justify-between items-center">
            <div className="flex items-center gap-[16px]">
              <div
                onClick={() => {
                  setsessionForm(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Create folder</p>
              </div>
              <div
                onClick={() => {
                  setAddWatermarkPopup(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Add Watermark</p>
              </div>
              <div onClick={handleUserListingButton} className="shortButton">
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  View / Manage Crew
                </p>
              </div>
            </div>
            <div className="flex items-center gap-[16px]">
              <div onClick={handleAlbumMetaDataButton} className="shortButton">
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>
              <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div>
            </div>
          </div> */}
          <div className="seperatorHorizontalNew"></div>
          <div className="px-[20px]  flex flex-col gap-[8px]">
            {albmTrainingSession &&
              albmTrainingSession.map((div, index) => (
                <DivWithData
                  isLoading={isLoading}
                  showDeletePopup={showDeletePopup}
                  handleDelete={handleDelete}
                  setShowDeletePopup={setShowDeletePopup}
                  handleRefreshImages={handleRefreshImages}
                  triggerGDriveUpload={triggerGDriveUpload}
                  handleGalleryPopupEdit={handleGalleryPopupEdit}
                  key={index}
                  divData={div}
                  error={error}
                  albumDetails={albumDetails}
                  setError={setError}
                  projectId={projectId}
                  albumId={shootmatesAlbumId}
                  loading={loading}
                  setLoading={setLoading}
                  studioId={studioId}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default PhotoShareTool;

const ConfirmationPopup = ({
  showDeletePopup,
  handleDelete,
  folderId,
  onCancel,
  isLoading,
}) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <p className="text-lg font-medium text-gray-900 mb-4">
        Are you sure you want to delete this folder?
      </p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          disabled={isLoading}
        >
          No
        </button>
        <button
          onClick={() => handleDelete(showDeletePopup?.folderId)}
          className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 flex items-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
              </svg>
              Deleting...
            </>
          ) : (
            "Yes, Delete"
          )}
        </button>
      </div>
    </div>
  </div>
);

const DivWithData = ({
  isLoading,
  showDeletePopup,
  handleDelete,
  setShowDeletePopup,
  handleRefreshImages,
  triggerGDriveUpload,
  divData,
  error,
  setError,
  albumDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex items-center rounded-lg mt-2 newBox  p-[16px] shadow-sm hover:shadow-md transition-shadow duration-200 hover:bg-blue-50">
      {error.errorStatus === true && (
        <PopupWrapper visible={error.errorStatus}>
          <ErrorPopup error={error} setError={setError} />
        </PopupWrapper>
      )}
      {showDeletePopup.show && (
        <ConfirmationPopup
          showDeletePopup={showDeletePopup}
          handleDelete={handleDelete}
          folderId={divData?.folderId}
          onCancel={() => setShowDeletePopup({ show: false, folderId: "null" })}
          isLoading={isLoading}
        />
      )}
      <div className="justify-between w-full items-center flex">
        <div className="flex gap-[8px] items-center">
          <div className="flex-shrink-0   rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M29.32 14.4533C29.2933 13.8133 29.1867 13.1866 28.9867 12.5866C28.0667 9.70665 25.3734 7.62665 22.2 7.62665H18.48C17.7066 7.62665 17.6533 7.54665 17.24 6.99998L15.3734 4.51998C14.5067 3.35998 13.8267 2.65332 11.6533 2.65332H8.54663C5.2933 2.65332 2.65332 5.29332 2.65332 8.54665V12.5733V22.1866C2.65332 26.12 5.85337 29.32 9.7867 29.32H22.1867C26.12 29.32 29.32 26.12 29.32 22.1866V14.7466C29.3333 14.6666 29.3333 14.5466 29.32 14.4533Z"
                fill="#4D58E3"
              />
              <path
                d="M20.7731 26.2667C17.9598 26.4667 17.9598 30.5467 20.7731 30.7467H27.4531C28.2664 30.7467 29.0397 30.4534 29.6397 29.9067C31.613 28.1867 30.5597 24.72 27.9597 24.4C27.0264 18.7734 18.8797 20.9067 20.813 26.2667"
                fill="#4D58E3"
              />
            </svg>
          </div>
          <div>
            <p className="font_medium_16 text-[#161616]">{divData?.title}</p>
            <div className=" pt-[13px] flex justify-between items-end">
              <div className="flex  gap-[12px]">
                <div className="flex items-center">
                  <img src={fileUploadIcon} alt="" />
                  <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
                    Files uploaded
                  </p>
                  <p className="font_normal_12 text-[#4D58E3]">
                    {divData?.processedImages}
                  </p>
                </div>
                <div className="flex items-center">
                  <img src={fileUploadIcon} alt="" />
                  <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
                    Selected Photos
                  </p>
                  <p className="font_normal_12 text-[#4D58E3]">
                    {divData?.selectedCount ? divData?.selectedCount : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-[12px]">
          <div
            onClick={() => {
              navigate(`${location.pathname}/upload/${divData?.folderId}`);
            }}
            className="flex items-center px-3 py-2 rounded-md   text-white cursor-pointer transition-colors duration-200"
          >
            <button className="uploadImageButFace ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.75 20.25V15.75H15.75L12 11.25L8.25 15.75H11.25V20.25H7.5V20.2125C7.374 20.22 7.254 20.25 7.125 20.25C5.63316 20.25 4.20242 19.6574 3.14752 18.6025C2.09263 17.5476 1.5 16.1168 1.5 14.625C1.5 11.739 3.6825 9.387 6.483 9.0645C6.72854 7.78094 7.41368 6.62306 8.42057 5.79001C9.42746 4.95696 10.6932 4.5008 12 4.5C13.307 4.50072 14.5729 4.95682 15.5801 5.78985C16.5872 6.62289 17.2727 7.78081 17.5185 9.0645C20.319 9.387 22.4985 11.739 22.4985 14.625C22.4985 16.1168 21.9059 17.5476 20.851 18.6025C19.7961 19.6574 18.3653 20.25 16.8735 20.25C16.7475 20.25 16.626 20.22 16.4985 20.2125V20.25H12.75Z"
                  fill="black"
                />
              </svg>
              <p className="">View / Upload Images</p>
            </button>
          </div>
          {albumDetails?.userConfirmation !== true && (
            <div
              onClick={() => {
                handleRefreshImages(divData?.folderId);
              }}
              className="flex items-center px-3 py-2 rounded-md   text-white cursor-pointer transition-colors duration-200"
            >
              <button className="uploadImageButFace ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 4V9H15"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="">Refresh Images</p>
              </button>
            </div>
          )}
          {divData?.uploadType === "GDRIVE" ||
            (divData?.folderType === "GDRIVE" && (
              <div
                onClick={() => {
                  triggerGDriveUpload(divData?.folderId);
                }}
                className="px-[8px] py-[5px] rounded-[4px] bg-[#006A67] cursor-pointer"
              >
                <p className="font_normal_11 text-[#FFFFFF]"> Import images</p>
              </div>
            ))}
          <div
            onClick={() =>
              setShowDeletePopup({ show: true, folderId: divData?.folderId })
            }
            className=""
          >
            <button className="uploadImageButFace ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 30 30"
              >
                <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
              </svg>
              <p className="">Delete Folder</p>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="seperatorHorizontalNew"></div> */}
      {/* <div className="px-[12px] pt-[13px] flex justify-between items-end">
        <div className="flex flex-col gap-[12px]">
          <div className="flex items-center">
            <img src={fileUploadIcon} alt="" />
            <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
              Files uploaded
            </p>
            <p className="font_normal_12 text-[#4D58E3]">
              {divData?.processedImages}
            </p>
          </div>
          <div className="flex items-center">
            <img src={fileUploadIcon} alt="" />
            <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
              Selected Photos
            </p>
            <p className="font_normal_12 text-[#4D58E3]">
              {divData?.selectedCount ? divData?.selectedCount : 0}
            </p>
          </div>
        </div>
      </div> */}
    </div>

    // <div className="  rounded-[8px] border-[1px] border-[#8C8C8C4D]  py-[14px]">
    //   {error.errorStatus === true && (
    //     <PopupWrapper visible={error.errorStatus}>
    //       <ErrorPopup error={error} setError={setError} />
    //     </PopupWrapper>
    //   )}
    //   {showDeletePopup && (
    //     <ConfirmationPopup
    //       handleDelete={handleDelete}
    //       folderId={divData?.folderId}
    //       onCancel={() => setShowDeletePopup(false)}
    //       isLoading={isLoading}
    //     />
    //   )}
    //   <div className="flex items-center justify-between px-[12px] pb-[19px]">
    //     <div className="flex gap-[8px] items-center">
    //       <div className=" flex flex-col gap-[3px]">
    //         <p className="font_medium_16 text-[#161616]">{divData?.title}</p>
    //       </div>
    //     </div>
    //     <div className="flex items-center gap-[12px]">
    //       <div
    //         onClick={() => {
    //           navigate(`${location.pathname}/upload/${divData?.folderId}`);
    //         }}
    //         className="px-[8px] py-[5px] rounded-[4px] bg-[#4D58E3] cursor-pointer"
    //       >
    //         <p className="font_normal_11 text-[#FFFFFF]">
    //           View / Upload Images
    //         </p>
    //       </div>
    //       {albumDetails?.userConfirmation !== true && (
    //         <div
    //           onClick={() => {
    //             handleRefreshImages(divData?.folderId);
    //           }}
    //           className="px-[8px] py-[5px] rounded-[4px] bg-[#04AA6D] cursor-pointer"
    //         >
    //           <p className="font_normal_11 text-[#FFFFFF]">Refresh Images</p>
    //         </div>
    //       )}
    //       {divData?.uploadType === "GDRIVE" ||
    //         (divData?.folderType === "GDRIVE" && (
    //           <div
    //             onClick={() => {
    //               triggerGDriveUpload(divData?.folderId);
    //             }}
    //             className="px-[8px] py-[5px] rounded-[4px] bg-[#006A67] cursor-pointer"
    //           >
    //             <p className="font_normal_11 text-[#FFFFFF]"> Import images</p>
    //           </div>
    //         ))}
    //       <div
    //         onClick={() => setShowDeletePopup(true)}
    //         className="px-[8px] py-[5px] rounded-[4px] bg-red-500 cursor-pointer"
    //       >
    //         <p className="font_normal_11 text-[#FFFFFF]">Delete Folder</p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="seperatorHorizontalNew"></div>
    //   <div className="px-[12px] pt-[13px] flex justify-between items-end">
    //     <div className="flex flex-col gap-[12px]">
    //       <div className="flex items-center">
    //         <img src={fileUploadIcon} alt="" />
    //         <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
    //           Files uploaded
    //         </p>
    //         <p className="font_normal_12 text-[#4D58E3]">
    //           {divData?.processedImages}
    //         </p>
    //       </div>
    //       <div className="flex items-center">
    //         <img src={fileUploadIcon} alt="" />
    //         <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
    //           Selected Photos
    //         </p>
    //         <p className="font_normal_12 text-[#4D58E3]">
    //           {divData?.selectedCount ? divData?.selectedCount : 0}
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
