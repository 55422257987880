import React from "react";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";

function AlertAIPopup({ visible, onClose, aiPopupMessage }) {
  return (
    <PopupWrapper visible={visible}>
      <div className="bg-white   h-[150px] mt-[15%] w-[25%]  py-4 px-8 rounded-[20px] shadow-lg flex flex-col items-center gap-[5px] justify-center">
        <div className="justify-center items-center flex  flex-col">
          <p className="text-[18px] font-[600]">{aiPopupMessage}</p>
          <button
            onClick={() => onClose(false)}
            className="mt-[20px] eventButton"
          >
            Ok
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default AlertAIPopup;
