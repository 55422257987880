import React, { useState } from "react";
import PhotoShareQRGenerator from "./PhotoShareQRGenerator";
import qrcodeIcon from "../../Assets/qrCodeIcon.svg";

const UploadPanel = ({
  handleRefreshImages,
  type,
  memshotAlbumId,
  slugId,
  studioDetails,
  setShowUploadPopup,
  uploadedMediasLength,
  setRefresh,
  refresh,
  selectedMedia,
  setSelectedMedias,
}) => {
  const [showQRGenerator, setShowQRGenerator] = useState(false);

  const handleClick = () => {
    setShowUploadPopup(true);
  };

  const clearCart = () => {
    setSelectedMedias([]);
  };

  const openCart = () => {
    setShowQRGenerator(true);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-gray-800 via-gray-700 to-gray-600 text-white p-6 rounded-xl shadow-2xl mb-4 transform transition duration-300 ease-in-out hover:shadow-lg">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-6">
            <span className="text-gray-300 text-lg">
              <strong className="text-white">Uploaded Media:</strong>{" "}
              {uploadedMediasLength}
            </span>
            <button
              className="bg-blue-600 flex items-center gap-[4px] hover:bg-blue-500 text-white font-semibold py-2 px-3 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={handleClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.75 20.25V15.75H15.75L12 11.25L8.25 15.75H11.25V20.25H7.5V20.2125C7.374 20.22 7.254 20.25 7.125 20.25C5.63316 20.25 4.20242 19.6574 3.14752 18.6025C2.09263 17.5476 1.5 16.1168 1.5 14.625C1.5 11.739 3.6825 9.387 6.483 9.0645C6.72854 7.78094 7.41368 6.62306 8.42057 5.79001C9.42746 4.95696 10.6932 4.5008 12 4.5C13.307 4.50072 14.5729 4.95682 15.5801 5.78985C16.5872 6.62289 17.2727 7.78081 17.5185 9.0645C20.319 9.387 22.4985 11.739 22.4985 14.625C22.4985 16.1168 21.9059 17.5476 20.851 18.6025C19.7961 19.6574 18.3653 20.25 16.8735 20.25C16.7475 20.25 16.626 20.22 16.4985 20.2125V20.25H12.75Z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              Upload New Media
            </button>
            <button
              className="bg-green-600 flex items-center gap-[4px] hover:bg-green-500 text-white font-semibold py-2 px-3 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={() => setRefresh(!refresh)}
            >
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
                  stroke="#FFFFFF"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6667 3.33301V7.49967H12.5"
                  stroke="#FFFFFF"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Refresh
            </button>
            {(type === "aiunpaid" || type === "aipaid") && (
              <button
                className="bg-yellow-600 flex items-center gap-[4px] hover:bg-yellow-500 text-white font-semibold py-2 px-3 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
                onClick={() => handleRefreshImages()}
              >
                <svg
                  className="flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM12 19C10.34 19 9 17.66 9 16C9 14.34 10.34 13 12 13C13.66 13 15 14.34 15 16C15 17.66 13.66 19 12 19ZM15 9H5V5H15V9Z"
                    fill="white"
                  />
                </svg>
                Sync Images
              </button>
            )}
            {(type === "aiunpaid" || type === "aipaid") && (
              <div className="flex flex-col items-start">
                <strong className="text-white">AI Publish Status</strong>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-green-500 rounded-sm"></div>
                  <span className="text-white text-sm">Completed</span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-red-500 rounded-sm"></div>
                  <span className="text-white text-sm">Waiting</span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-yellow-500 rounded-sm"></div>
                  <span className="text-white text-sm">Processing</span>
                </div>
              </div>
            )}

            {selectedMedia?.length > 0 && (
              <>
                <button
                  className="bg-cyan-600 flex gap-[4px] items-center hover:bg-cyan-500 text-white font-semibold py-2 px-3 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  onClick={openCart}
                >
                  <img src={qrcodeIcon} alt="" className="w-[20px] h-[20px]" />{" "}
                  Generate QR Code{" "}
                  <span className="text-sm">({selectedMedia?.length})</span>
                </button>
                <button
                  onClick={clearCart}
                  className="bg-red-600 hover:bg-red-500 text-white font-semibold py-2 px-3 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Clear Cart
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showQRGenerator && (
        <div className="mt-4">
          <PhotoShareQRGenerator
            slugId={slugId}
            studioDetails={studioDetails}
            memshotAlbumId={memshotAlbumId}
            onClose={() => setShowQRGenerator(false)}
            selectedMedia={selectedMedia}
          />
        </div>
      )}
    </>
  );
};

export default UploadPanel;
