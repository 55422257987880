import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import ClientSearchDropDown from "../../ClientSearchDropDown/ClientSearchDropDown";
import ClientForm from "../ClientForm/ClientForm";
import "./EventForm.css";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";
import _ from "lodash";
import Loading from "../../../Assets/Animation/Loading";
//import { useNavigate } from 'react-router-dom'

function EventForm({ update, setUpdate, visible, closePopup, isFlexi }) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { studioId, activePackages } = useContext(dataContext);
  const { userIdToken, currentUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [search, setSearch] = useState("");
  const [clientSearchData, setClientSearchData] = useState([]);
  const [clientSelect, setClientSelect] = useState(false);
  const [clientDropDownLoading, setClientDropDownLoading] = useState(false);
  const [clientPopUp, setClientPopUp] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [packageTypeError, setPackageTypeError] = useState("");
  const [errorFromBackEnd, setErrorFromBackEnd] = useState("");
  const [packageType, setPackageType] = useState(
    isFlexi === "YEARLY" && activePackages?.packageDetails?.packageType
      ? activePackages?.packageDetails?.packageType
      : "FLEXI"
  );
  const initialValues = {
    isDateConfirmed: "FALSE",
    isAllDay: "FALSE",
    packageType: activePackages?.activePackage ? "FLEXI" : packageType,
  };
  const [formValues, setFormValues] = useState(initialValues);
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      packageType,
    }));
  }, [packageType]);

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (search !== "") {
      clientSearch(source);
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };

  const getEvents = async () => {
    // setLoading(true);
    try {
      const data = await axios.get(
        "https://storage.googleapis.com/shootmatescrmdatas/eventTypes.json"
      );
      setEventsData(data?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const validateForm = () => {
    let errors = {};
    if (_.isEmpty(formValues.eventType)) {
      errors.eventType = "Please choose an event type";
    }
    if (_.isEmpty(formValues.leadName)) {
      errors.leadName = "Please give a event name";
    }
    if (_.isEmpty(formValues.location)) {
      errors.location = "Please give a location name";
    }
    if (_.isEmpty(formValues.startDate)) {
      errors.startDate = "Please give a start Date";
    }

    return errors;
  };

  const clientSearch = async (source) => {
    try {
      setClientDropDownLoading(true);
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALL_CLIENTS}/${studioId}?search=${search}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      };
      const response = await axios(config);

      if (response.status === 201) {
        setClientDropDownLoading(false);
        setClientSearchData(response.data.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  };

  const createEvent = async () => {
    try {
      const validationErrors = validateForm();
      if (!packageType || packageType == "")
        return setPackageTypeError("Please give package Type");
      if (Object.keys(validationErrors).length === 0) {
        setLoading(true);
        let config = {
          method: "post",
          // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootMatesProject/api/test/shootmates/project/create/albumprojectevent/${studioId}`,
          url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesProject/api/shootmates/project/create/albumprojectevent/${studioId}`,

          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
          data: { projectData: formValues },
        };
        const response = await axios(config);
        if (response?.data?.error === true) {
          setLoading(false);
          return setErrorFromBackEnd(response?.data?.msg);
        }
        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          setUpdate(!update);
          closePopup(false);
        }
      } else {
        setErrors(validationErrors);
      }
      setPackageTypeError("");
    } catch (error) {
      console.log("error", error);
      closePopup(true);
      setLoading(false);
    }
  };

  const handleFrequencyChange = (event) => {
    setPackageTypeError("");
    setPackageType(event.target.value);
  };

  useEffect(() => {
    if (errorFromBackEnd) {
      setErrorFromBackEnd("");
    }
  }, [visible]);

  return (
    <PopupWrapper visible={visible}>
      {loading ? (
        <Loading />
      ) : errorFromBackEnd ? (
        <div className="AlbumCreateFormContainer p-[20px] w-[400px] mt-[200px] ml-[78px] mr-[78px] ">
          <div class="justify-center items-center flex flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <path
                d="M17.9183 6.5L6.5 17.9183V34.0817L17.9183 45.5H34.0817L45.5 34.0817V17.9183L34.0817 6.5M18.2217 15.1667L26 22.945L33.7783 15.1667L36.8333 18.2217L29.055 26L36.8333 33.7783L33.7783 36.8333L26 29.055L18.2217 36.8333L15.1667 33.7783L22.945 26L15.1667 18.2217"
                fill="#FE6753"
              ></path>
            </svg>
          </div>
          <div className="font_semibold_20 text-[#161616] flex justify-center items-center ">
            <p className="text-[18px] text-center  ">{errorFromBackEnd}</p>
          </div>
          <div className="flex mt-[20px] justify-center items-center gap-[40px]">
            <button
              onClick={() => {
                closePopup(false);
              }}
              className="py-[8px]  px-[16px] rounded-[10px] border-[1px] border-opacity-30 border-[#111] font_semibold_12"
            >
              Ok
            </button>
          </div>
        </div>
      ) : (
        <div>
          {clientPopUp ? (
            <ClientForm
              update={update}
              setUpdate={setUpdate}
              visible={clientPopUp}
              setpopUp={setClientPopUp}
              formValues={formValues}
              idkey="create"
            />
          ) : (
            <div className="eventFormContainer mt-[2%]">
              <div className="flex justify-between p-[24px] items-center">
                <p className="font_semibold_20 text-[#161616]">
                  Add a new event
                </p>
                <img
                  src={popupsCloseIcon}
                  alt=""
                  onClick={() => {
                    closePopup(false);
                  }}
                  className="cursor-pointer"
                />
              </div>
              <div className="seperatorHorizontalNew"></div>
              <div className="w-full h-full px-[24px] py-[20px]">
                <p className="font_semibold_16 text-[#161616] mb-[24px]">
                  Event details
                </p>
                <div className="eventFormAlbumGridContainer">
                  <div className="formEventName">
                    <p className="font_normal_12 text-[#8C8C8C]">Event Name*</p>
                    <input
                      type="text"
                      placeholder="Please enter event name"
                      id="leadName"
                      onChange={handleChange}
                      value={formValues.leadName}
                    />
                    {errors?.leadName && (
                      <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                        {errors?.leadName}
                      </p>
                    )}
                  </div>

                  <div className="formEventType">
                    <p className="font_normal_12 text-[#8C8C8C]">Event Type*</p>

                    <select
                      style={{
                        border: "1px solid #D1D5DB",
                        borderRadius: "8px",
                        padding: "12px",
                      }}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          eventType: e.target.value,
                        });
                      }}
                      value={formValues?.eventType}
                    >
                      <option value="">--Please choose an event--</option>
                      {eventsData &&
                        eventsData.length > 0 &&
                        eventsData.map((obj, index) => {
                          return (
                            <option key={index} value={obj}>
                              {obj}
                            </option>
                          );
                        })}
                    </select>
                    {errors?.eventType && (
                      <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                        {errors?.eventType}
                      </p>
                    )}
                  </div>
                  <div className="formEventLocation">
                    <p className="font_normal_12 text-[#8C8C8C]">
                      Event Location*
                    </p>
                    <input
                      type="text"
                      placeholder="Please enter location"
                      id="location"
                      onChange={handleChange}
                      value={formValues.location}
                    />
                    {errors?.location && (
                      <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                        {errors?.location}
                      </p>
                    )}
                  </div>
                  <div className="formClientName">
                    <p className="font_normal_12 text-[#8C8C8C]">
                      Client Contact<span className="text-red-600">*</span>
                    </p>
                    <input
                      type="text"
                      placeholder="Start entering..."
                      onFocus={() => {
                        setClientSelect(true);
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                    {clientSelect && search !== "" && (
                      <ClientSearchDropDown
                        clientDataLength={clientSearchData.length}
                        clientDropDownLoading={clientDropDownLoading}
                        setClientDropDownLoading={setClientDropDownLoading}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        clientSearchData={clientSearchData}
                        setSearch={setSearch}
                        setClientSelect={setClientSelect}
                      />
                    )}
                  </div>
                  <div className="eventitem2">
                    <p className="font_normal_12 text-[#8C8C8C]">Or</p>
                  </div>
                  <div className="eventitem3">
                    <button
                      className="eventButton font_semibold_12"
                      onClick={() => setClientPopUp(true)}
                    >
                      Add New Client
                    </button>
                  </div>

                  <div className="items-center gap-[20px] ">
                    <div className="flex justify-start items-center gap-[20px]">
                      <label htmlFor="paymentFrequency " className="  text-md">
                        {activePackages?.packageDetails?.packageType ===
                          "TRIAL" ||
                        activePackages?.packageDetails?.packageType === "YEARLY"
                          ? "Choose package Type"
                          : "Package type"}
                        {packageTypeError && (
                          <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                            {packageTypeError}
                          </p>
                        )}
                      </label>

                      <div
                        className={`p-2  
                    `}
                      >
                        <div>
                          {isFlexi === "YEARLY" &&
                            activePackages?.activePackage === true &&
                            activePackages?.packageDetails?.packageType ===
                              "YEARLY" && (
                              <label className="mr-4">
                                <input
                                  type="radio"
                                  name="paymentFrequency"
                                  value="YEARLY"
                                  // disabled={activePackages?.activePackage === false}
                                  checked={packageType === "YEARLY"}
                                  onChange={handleFrequencyChange}
                                  className="mr-1"
                                />
                                YEARLY
                              </label>
                            )}
                          <label>
                            <input
                              type="radio"
                              name="paymentFrequency"
                              value="FLEXI"
                              checked={
                                packageType === "FLEXI" || isFlexi === "FLEXI"
                              }
                              onChange={handleFrequencyChange}
                              className="mr-1"
                            />
                            FLEXI
                          </label>
                          {activePackages?.activePackage === true &&
                            activePackages?.packageDetails?.packageType ===
                              "TRIAL" && (
                              <label className="ml-4">
                                <input
                                  type="radio"
                                  name="paymentFrequency"
                                  value="TRIAL"
                                  checked={packageType === "TRIAL"}
                                  onChange={handleFrequencyChange}
                                  className="mr-1"
                                />
                                TRIAL
                              </label>
                            )}
                        </div>
                      </div>
                    </div>
                    {isFlexi === "YEARLY"
                      ? activePackages?.activePackage === true &&
                        activePackages?.packageDetails?.packageType ===
                          "YEARLY" && (
                          <div className="max-w-[500px] ">
                            {" "}
                            <p className="text-[14px] mt-[2px]">
                              You are on a yearly package. If you would like to
                              add a service for another brand or if your package
                              has expired, you can choose the Flexi option.
                            </p>
                          </div>
                        )
                      : ""}
                    {/* {activePackages?.packageStatus === "EXPIRED" && (
                    <p>
                      Your package has been {activePackages?.packageStatus}{" "}
                    </p>
                  )} */}
                  </div>

                  {formValues.isDateConfirmed === "FALSE" && (
                    <>
                      <div className="eventitem8">
                        <p className="font_normal_12 text-[#8C8C8C]">
                          Date<span className="text-red-600">*</span>
                        </p>
                        <input
                          type="date"
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              startDate: e.target.value,
                            });
                          }}
                          value={formValues.startDate}
                        />
                        {errors?.startDate && (
                          <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                            {errors?.startDate}
                          </p>
                        )}
                      </div>

                      {/* <div className="eventitem11">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            isAllDay:
                              formValues.isAllDay === "FALSE"
                                ? "TRUE"
                                : "FALSE",
                          });
                        }}
                        value={formValues.isAllDay}
                        checked={formValues.isAllDay === "FALSE" ? false : true}
                      />
                      <label className="font_normal_12 text-[#8C8C8C]">
                        All Day
                      </label>
                    </div> */}
                      {/* {formValues.isAllDay === "FALSE" && (
                      <>
                        {" "}
                        <div className="eventitem12">
                          <p className="font_normal_12 text-[#8C8C8C]">
                            Time<span className="text-red-600">*</span>
                          </p>
                          <input
                            type="time"
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                startTime: e.target.value,
                              });
                            }}
                            value={formValues.startTime}
                          />
                        </div>
                        <div className="eventitem13">
                          <p className="font_normal_12 text-[#8C8C8C]">To</p>
                        </div>
                        <div className="eventitem14">
                          <input
                            type="time"
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                endTime: e.target.value,
                              });
                            }}
                            value={formValues.endTime}
                            className="w-full"
                          />
                        </div>
                        <div className="eventitem15"></div>{" "}
                      </>
                    )} */}
                    </>
                  )}

                  {/* <div className="eventitem18">
                  <p className="font_normal_12 text-[#8C8C8C]">Notes:</p>
                  <input
                    type="text"
                    id="projectNote"
                    placeholder="Please enter notes"
                    onChange={handleChange}
                    value={formValues.projectNote}
                  />
                </div> */}
                </div>
              </div>
              <div className="seperatorHorizontalNew"></div>
              {errorFromBackEnd && (
                <p className="text-end text-[red] mr-[10%]">
                  {errorFromBackEnd}
                </p>
              )}
              <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[16px] pb-[24px]">
                <button
                  onClick={() => {
                    closePopup(false);
                  }}
                  className="cancelButton font_semibold_12"
                >
                  Cancel
                </button>

                <button
                  className="eventButton font_semibold_12"
                  onClick={() => createEvent()}
                >
                  {loading ? "Loading..." : "Create Event"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </PopupWrapper>
  );
}

export default EventForm;
