import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";

function CreateServicePopup({
  visible,
  whiteAddButton,
  setCreatePhotoShare,
  leadDetails,
  setCreateAlbum,
  setCreateInvitation,
  setCreateSelectionTool,
  setCreateLiveFaceDetection,
  setOpenServicePopup,
}) {
  return (
    <PopupWrapper visible={visible}>
      <div className="bg-[#fff] popUpShadow  min-h-[400px] max-h-[450px] min-w-[400px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <div className="flex items-center justify-between">
          <p className="text-[24px]">Create a Service</p>
          <button onClick={() => setOpenServicePopup(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col gap-[20px] justify-between mt-[12px] pr-[5%] w-full">
          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            } eventCreateClass`}
            onClick={() =>
              leadDetails?.packageType === "TRIAL" ? "" : setCreateAlbum(true)
            }
          >
            <div className="flex gap-[4px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.5 12.0002C10.5 13.3277 9.9225 14.5277 9 15.3452C8.205 16.0652 7.155 16.5002 6 16.5002C3.5175 16.5002 1.5 14.4827 1.5 12.0002C1.5 9.93021 2.91 8.17521 4.815 7.65771C5.3325 8.96271 6.4425 9.96771 7.815 10.3427C8.19 10.4477 8.5875 10.5002 9 10.5002C9.4125 10.5002 9.81 10.4477 10.185 10.3427C10.3875 10.8527 10.5 11.4152 10.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 6C13.5 6.585 13.3875 7.1475 13.185 7.6575C12.6675 8.9625 11.5575 9.9675 10.185 10.3425C9.81 10.4475 9.4125 10.5 9 10.5C8.5875 10.5 8.19 10.4475 7.815 10.3425C6.4425 9.9675 5.3325 8.9625 4.815 7.6575C4.6125 7.1475 4.5 6.585 4.5 6C4.5 3.5175 6.5175 1.5 9 1.5C11.4825 1.5 13.5 3.5175 13.5 6Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 12.0002C16.5 14.4827 14.4825 16.5002 12 16.5002C10.845 16.5002 9.795 16.0652 9 15.3452C9.9225 14.5277 10.5 13.3277 10.5 12.0002C10.5 11.4152 10.3875 10.8527 10.185 10.3427C11.5575 9.96771 12.6675 8.96271 13.185 7.65771C15.09 8.17521 16.5 9.93021 16.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px]  text-black">
                Signature Album
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                fill="#161616"
              ></path>
            </svg>
          </div>

          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            }  eventCreateClass`}
            onClick={() =>
              leadDetails?.packageType === "TRIAL"
                ? ""
                : setCreateInvitation(true)
            }
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M14.624 7.77588V14.6226C14.624 15.0701 14.4462 15.4993 14.1298 15.8158C13.8133 16.1323 13.3841 16.3101 12.9365 16.3101H5.06152C4.61397 16.3101 4.18475 16.1323 3.86828 15.8158C3.55181 15.4993 3.37402 15.0701 3.37402 14.6226V3.37256C3.37402 2.92501 3.55181 2.49578 3.86828 2.17932C4.18475 1.86285 4.61397 1.68506 5.06152 1.68506H8.5332C8.83147 1.6851 9.11751 1.80359 9.32844 2.01447L14.2946 6.98064C14.5055 7.19157 14.624 7.47761 14.624 7.77588Z"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.99902 1.96631V6.18506C8.99902 6.48343 9.11755 6.76958 9.32853 6.98055C9.53951 7.19153 9.82565 7.31006 10.124 7.31006H14.3428"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.18652 10.123H11.8115"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.18652 12.9351H11.8115"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Online Invitation
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                fill="#161616"
              ></path>
            </svg>
          </div>

          <div
            className="eventCreateClass "
            onClick={() => setCreateSelectionTool(true)}
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.5 12.0002C10.5 13.3277 9.9225 14.5277 9 15.3452C8.205 16.0652 7.155 16.5002 6 16.5002C3.5175 16.5002 1.5 14.4827 1.5 12.0002C1.5 9.93021 2.91 8.17521 4.815 7.65771C5.3325 8.96271 6.4425 9.96771 7.815 10.3427C8.19 10.4477 8.5875 10.5002 9 10.5002C9.4125 10.5002 9.81 10.4477 10.185 10.3427C10.3875 10.8527 10.5 11.4152 10.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 6C13.5 6.585 13.3875 7.1475 13.185 7.6575C12.6675 8.9625 11.5575 9.9675 10.185 10.3425C9.81 10.4475 9.4125 10.5 9 10.5C8.5875 10.5 8.19 10.4475 7.815 10.3425C6.4425 9.9675 5.3325 8.9625 4.815 7.6575C4.6125 7.1475 4.5 6.585 4.5 6C4.5 3.5175 6.5175 1.5 9 1.5C11.4825 1.5 13.5 3.5175 13.5 6Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 12.0002C16.5 14.4827 14.4825 16.5002 12 16.5002C10.845 16.5002 9.795 16.0652 9 15.3452C9.9225 14.5277 10.5 13.3277 10.5 12.0002C10.5 11.4152 10.3875 10.8527 10.185 10.3427C11.5575 9.96771 12.6675 8.96271 13.185 7.65771C15.09 8.17521 16.5 9.93021 16.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Photo Selection
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                fill="#161616"
              ></path>
            </svg>
          </div>

          <div
            className="eventCreateClass "
            onClick={() => setCreateLiveFaceDetection(true)}
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.66667 13.3332H3.33333C2.97971 13.3332 2.64057 13.1927 2.39052 12.9426C2.14048 12.6926 2 12.3535 2 11.9998V5.99984C2 5.64622 2.14048 5.30708 2.39052 5.05703C2.64057 4.80698 2.97971 4.6665 3.33333 4.6665H4C4.35362 4.6665 4.69276 4.52603 4.94281 4.27598C5.19286 4.02593 5.33333 3.68679 5.33333 3.33317C5.33333 3.15636 5.40357 2.98679 5.5286 2.86177C5.65362 2.73674 5.82319 2.6665 6 2.6665H10C10.1768 2.6665 10.3464 2.73674 10.4714 2.86177C10.5964 2.98679 10.6667 3.15636 10.6667 3.33317C10.6667 3.68679 10.8071 4.02593 11.0572 4.27598C11.3072 4.52603 11.6464 4.6665 12 4.6665H12.6667C13.0203 4.6665 13.3594 4.80698 13.6095 5.05703C13.8595 5.30708 14 5.64622 14 5.99984V7.33317"
                  stroke="#4D58E3"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.57464 7.43341C9.39867 7.2087 9.17664 7.02425 8.92347 6.89247C8.6703 6.76068 8.39185 6.68461 8.10685 6.66936C7.82184 6.65411 7.53687 6.70004 7.27108 6.80407C7.0053 6.90809 6.76486 7.06779 6.56591 7.27245C6.36697 7.4771 6.21413 7.72197 6.11768 7.99059C6.02122 8.25922 5.98337 8.54537 6.00668 8.82984C6.02999 9.1143 6.11391 9.39048 6.25281 9.63981C6.39171 9.88915 6.58237 10.1059 6.81197 10.2754M9.33331 14.0001V11.3334C9.33331 10.9798 9.47378 10.6407 9.72383 10.3906C9.97388 10.1406 10.313 10.0001 10.6666 10.0001C11.0203 10.0001 11.3594 10.1406 11.6094 10.3906C11.8595 10.6407 12 10.9798 12 11.3334V14.0001M9.33331 12.6667H12M14 10.0001V14.0001"
                  stroke="#4D58E3"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                AI Photo Delivery
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                fill="#161616"
              ></path>
            </svg>
          </div>

          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            } eventCreateClass `}
            onClick={() =>
              leadDetails?.packageType === "TRIAL"
                ? ""
                : setCreatePhotoShare(true)
            }
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.238 2C2.90966 2 2.59477 2.13043 2.3626 2.3626C2.13043 2.59477 2 2.90966 2 3.238V6.09533C2 6.77933 2.554 7.33333 3.238 7.33333H6.09533C6.42367 7.33333 6.73856 7.2029 6.97073 6.97073C7.2029 6.73856 7.33333 6.42367 7.33333 6.09533V3.238C7.33333 2.90966 7.2029 2.59477 6.97073 2.3626C6.73856 2.13043 6.42367 2 6.09533 2H3.238ZM9.90467 2C9.57633 2 9.26144 2.13043 9.02927 2.3626C8.7971 2.59477 8.66667 2.90966 8.66667 3.238V6.09533C8.66667 6.77933 9.22067 7.33333 9.90467 7.33333H12.762C13.0903 7.33333 13.4052 7.2029 13.6374 6.97073C13.8696 6.73856 14 6.42367 14 6.09533V3.238C14 2.90966 13.8696 2.59477 13.6374 2.3626C13.4052 2.13043 13.0903 2 12.762 2H9.90467ZM3.238 8.66667C2.90966 8.66667 2.59477 8.7971 2.3626 9.02927C2.13043 9.26144 2 9.57633 2 9.90467V12.762C2 13.446 2.554 14 3.238 14H6.09533C6.42367 14 6.73856 13.8696 6.97073 13.6374C7.2029 13.4052 7.33333 13.0903 7.33333 12.762V9.90467C7.33333 9.57633 7.2029 9.26144 6.97073 9.02927C6.73856 8.7971 6.42367 8.66667 6.09533 8.66667H3.238ZM12 9.33333C12 9.15652 11.9298 8.98695 11.8047 8.86193C11.6797 8.73691 11.5101 8.66667 11.3333 8.66667C11.1565 8.66667 10.987 8.73691 10.8619 8.86193C10.7369 8.98695 10.6667 9.15652 10.6667 9.33333V10.6667H9.33333C9.15652 10.6667 8.98695 10.7369 8.86193 10.8619C8.73691 10.987 8.66667 11.1565 8.66667 11.3333C8.66667 11.5101 8.73691 11.6797 8.86193 11.8047C8.98695 11.9298 9.15652 12 9.33333 12H10.6667V13.3333C10.6667 13.5101 10.7369 13.6797 10.8619 13.8047C10.987 13.9298 11.1565 14 11.3333 14C11.5101 14 11.6797 13.9298 11.8047 13.8047C11.9298 13.6797 12 13.5101 12 13.3333V12H13.3333C13.5101 12 13.6797 11.9298 13.8047 11.8047C13.9298 11.6797 14 11.5101 14 11.3333C14 11.1565 13.9298 10.987 13.8047 10.8619C13.6797 10.7369 13.5101 10.6667 13.3333 10.6667H12V9.33333Z"
                  fill="#FA6666"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Photo Share Tool
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                fill="#161616"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default CreateServicePopup;
