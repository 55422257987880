import { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import { ref, uploadBytes, updateMetadata } from "firebase/storage";
import { userAuthContext } from "../../Context/UserAuthContext";
import { shootmatesalbumdata } from "../../store/firebase-config";
import "./Watermarks.css";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
//import samImg from "../../Assets/sample.png";
import mainImageForPreview from "../../Assets/wmrk.jpg";

const Watermark = ({
  visible,
  setAddWatermarkPopup,
  setOpenAdvSettings,
  setOpen,
  studioId,
  projectId,
  albumId,

  // message,
}) => {
  const sizeRespToMainImage = 0.17;
  const paddingRespectiveToMainImage = 0.017;
  const [message, setMessage] = useState("");
  //const [size, setSize] = useState(500);
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const canvasRef = useRef(null);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [mainImageSrc] = useState(mainImageForPreview);
  const [watermarkSrc, setWatermarkSrc] = useState(null);
  const [position, setPosition] = useState("bottom-right");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    verifyUser();
    applyWatermark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watermarkSrc, position]);

  const getSizeMaintainingAspectRatio = (image, maxWidth, maxHeight) => {
    const imageWidth = image.width;
    const imageHeight = image.height;
    // Let's find the max available width for the scaled image
    var ratio = imageWidth / imageHeight;
    var aspectWidth = Math.min(maxWidth, ratio * maxHeight);
    var aspectHeight = Math.floor(aspectWidth / ratio);

    return {
      width: imageWidth > aspectWidth ? aspectWidth : imageWidth,
      height: imageHeight > aspectHeight ? aspectHeight : imageHeight,
    };
  };

  const getWatermarkSize = (image, width, height) => {
    if (image.width < image.height) {
      return getSizeMaintainingAspectRatio(image, width, height);
    } else {
      return getSizeMaintainingAspectRatio(image, height, width);
    }
  };

  const getWatermarkRect = (mainImage, watermark, position) => {
    const sizeRespectToMainImage = sizeRespToMainImage;
    let watermarkHeight;
    let watermarkWidth;
    let padding;

    if (mainImage.width > mainImage.height) {
      watermarkHeight = mainImage.height * sizeRespectToMainImage;
      watermarkWidth = mainImage.width * sizeRespectToMainImage;
      padding = mainImage.width * paddingRespectiveToMainImage;
    } else {
      watermarkHeight = mainImage.width * sizeRespectToMainImage;
      watermarkWidth = mainImage.height * sizeRespectToMainImage;
      padding = mainImage.height * paddingRespectiveToMainImage;
    }

    const watermarkSize = getWatermarkSize(
      watermark,
      watermarkWidth,
      watermarkHeight
    );

    switch (position) {
      case "top-left":
        return {
          x: padding * 1.5,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "top-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * 1.5,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-left":
        return {
          x: padding * 1.5,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * 1.5,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      default:
        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        };
    }
  };

  const applyWatermark = async () => {
    if (mainImageSrc) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const mainImageObj = new Image();
      const watermarkObj = new Image();

      mainImageObj.src = mainImageSrc;
      watermarkObj.src = watermarkSrc;

      // Wait for both images to load
      mainImageObj.onload = () => {
        // Set the canvas dimensions to match the main image
        canvas.width = mainImageObj.width;
        canvas.height = mainImageObj.height;

        // Draw the main image on the canvas
        ctx.drawImage(mainImageObj, 0, 0);

        watermarkObj.onload = () => {
          const getWatermarkPositions = getWatermarkRect(
            mainImageObj,
            watermarkObj,
            position
          );
          console.log("getWatermarkPositions", getWatermarkPositions);
          // Draw the watermark on the canvas with padding
          ctx.drawImage(
            watermarkObj,
            getWatermarkPositions?.x,
            getWatermarkPositions?.y,
            getWatermarkPositions?.width,
            getWatermarkPositions?.height
          );
        };

        // Handle image loading errors
        watermarkObj.onerror = () => {
          console.error("Watermark image failed to load.");
        };
      };

      // Handle image loading errors
      mainImageObj.onerror = () => {
        console.error("Main image failed to load.");
      };
    }
  };

  const handleWatermarkImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["png"];

    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage("Only JPG and PNG files are allowed.");
      e.target.value = "";
      return;
    }

    if (file.size > 1024 * 1024) {
      setErrorMessage("File size should be 1MB or less.");
      e.target.value = "";
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width > 1000 || img.height > 1000) {
        setErrorMessage("Image dimensions should be 1000px or less.");
        e.target.value = "";
        return;
      }
      setRefresh(!refresh);
      setErrorMessage(""); // Clear any previous errors
      setWatermarkSrc(URL.createObjectURL(file));
    };
  };

  const uploadWatermark = async (file) => {
    setLoading(true);
    try {
      const fileName = file.name;
      const filePath = `/studio/${studioId}/project/${projectId}/album/${albumId}/${fileName}`;
      const storageRef = ref(shootmatesalbumdata, filePath);
      await uploadBytes(storageRef, file);

      const newMetadata = {
        cacheControl: "no-cache, no-store, must-revalidate",
        contentType: file.type,
      };
      await updateMetadata(storageRef, newMetadata);

      const url = `https://storage.googleapis.com/shootmatesalbumdata${filePath}`;
      return url;
    } catch (error) {
      console.error("Error uploading watermark:", error);
      setMessage("Error uploading watermark.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const changePosition = (item) => {
    setPosition(item);
  };

  const handleSetWaterMarkUpload = async () => {
    setLoadingApi(true);
    try {
      if (!watermarkSrc) {
        setErrorMessage("No watermark image selected.");
        return;
      }

      const watermarkImg = new Image();
      watermarkImg.src = watermarkSrc;
      watermarkImg.onload = async () => {
        const file = await fetch(watermarkSrc).then((res) => res.blob());
        const fileObj = new File([file], "watermark.png", {
          type: "image/png",
        });

        const url = await uploadWatermark(fileObj);
        if (url) {
          const watermarkdetails = {
            position,
            watermarkUrl: url,
          };

          const config = {
            method: "post",
            url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/mediaoperations/setWatermark/${projectId}/${studioId}/${albumId}`,
            headers: {
              Authorization: `Bearer ${tokenData}`,
              "Content-Type": "application/json",
            },
            data: {
              watermarkdetails: watermarkdetails,
              action: "SET",
            },
          };

          const response = await axios(config);
          if (response.status === 200) {
            setMessage("Watermark uploaded successfully");
          } else {
            setMessage("Something went wrong, please try again!");
          }
        }
      };
    } catch (error) {
      console.error("Error setting watermark:", error);
      setMessage("Error setting watermark.");
    } finally {
      setLoadingApi(false);
    }
  };

  const handleCLick = () => {
    setMessage("");
    setAddWatermarkPopup(false);
    setOpenAdvSettings(false);
  };

  // const handleScaling = async (e) => {
  //   console.log("Hii");

  //   const value = e.target.value;
  //   const newValue = value === "" ? "" : Number(value);
  //   console.log(value);
  //   const watermarkObj = new Image();

  //   watermarkObj.src = watermarkSrc;
  //   const resizedBlob = await resizeWatermark(watermarkObj, newValue);
  //   setWatermarkSrc(URL.createObjectURL(resizedBlob));
  //   setSize(newValue)
  // };
  // const resizeWatermark = (image, maxSize) => {
  //   return new Promise((resolve, reject) => {
  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");

  //     const aspectRatio = image.width / image.height;
  //     let newWidth, newHeight;

  //     if (image.width > image.height) {
  //       newWidth = maxSize;
  //       newHeight = Math.floor(maxSize / aspectRatio);
  //     } else {
  //       newHeight = maxSize;
  //       newWidth = Math.floor(maxSize * aspectRatio);
  //     }

  //     canvas.width = newWidth;
  //     canvas.height = newHeight;

  //     context.drawImage(image, 0, 0, newWidth, newHeight);

  //     canvas.toBlob(
  //       (blob) => {
  //         if (blob) {
  //           resolve(blob); // Return the resized file
  //         } else {
  //           reject(new Error("Canvas toBlob failed"));
  //         }
  //       },
  //       "image/png",
  //       1
  //     );
  //   });
  // };

  return (
    <PopupWrapper visible={visible}>
      {message ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white   h-[150px]    py-4 px-8 rounded-[20px] shadow-lg flex flex-col items-center gap-[5px] justify-center">
            <div className="justify-center items-center flex  flex-col">
              <p className="text-[18px] font-[600]">{message}</p>
              <button
                onClick={() => {
                  handleCLick();
                }}
                className="mt-[20px] eventButton"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="WaterMarkContainer p-[16px]  my-[30px] bg-white rounded-lg shadow-lg">
          <div className="px-4 py-4 sticky top-0 bg-white flex items-center justify-between rounded-t-lg">
            <div className="flex gap-[12px]">
              <button className="iconWatermark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="#373CBD" />
                </svg>
              </button>
              <div>
                <p className="text-[18px] font-[600] text-[#101828]">
                  Add Watermark
                </p>
                <p className="text-[14px] font-[400] text-[#161616]">
                  Upload a Watermark Image Less Than 500px width and 500px
                  height
                </p>
              </div>
              {/* <button
              type="button"
              onClick={handleSetWaterMarkUpload}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              {loadingApi ? "Processing..." : "Set Watermark"}
            </button> */}
            </div>
            <img
              onClick={() => setAddWatermarkPopup(false)}
              src={popupsCloseIcon}
              alt="Close"
              className="cursor-pointer w-6 h-6"
            />
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="flex gap-[31px] justify-center items-start">
            <div className="mb-4">
              <div
                style={{
                  boxShadow:
                    "0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
                }}
                className="relative   mt-[16px] w-[640px] h-[426px]"
              >
                <canvas ref={canvasRef} className="border mt-4 w-full" />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="watermark_image"
                  className="changeWaterMark text-[#4D58E3] mt-[10px] text-[12px] font-[600]"
                >
                  {watermarkSrc
                    ? "Change Watermark Image"
                    : "Upload Watermark Image"}
                </label>
                <input
                  type="file"
                  id="watermark_image"
                  accept=".jpg,.png"
                  onChange={handleWatermarkImageChange}
                  className="w-full hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="watermark_image_help"
                />
              </div>
              {errorMessage && (
                <p className="text-red-500 text-sm ">{errorMessage}</p>
              )}
            </div>
            {/* </div> */}
            <div className=" mt-[10px]">
              <div className="mb-4">
                <label className="text-[#101828] font-[600] text-[14px]">
                  Watermark Position
                </label>
                <div className="waterMarkPosition mt-[16px]  p-[18px] flex flex-col">
                  <div className="flex items-center gap-[18px]">
                    {[
                      "top-left",
                      "top-right",
                      "bottom-left",
                      "bottom-right",
                    ]?.map((item) => (
                      <button
                        onClick={() => changePosition(item)}
                        className={`${
                          position === item
                            ? "bg-[#4D58E3] text-[#FFF]"
                            : "bg-[#F4F4F4] text-[#2E2E2E]"
                        }  waterMarkPositionButton whitespace-nowrap`}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
                {/* <label className="text-[#101828] font-[600] text-[14px]">
                  Scaling
                </label>
                <div className="waterMarkPosition mt-[16px]  p-[8px] flex flex-col">
                  <div className="flex items-center gap-[8px]">
                    <input
                      type="range"
                      min="50"
                      max="500"
                      value={size}
                      onChange={ handleScaling}
                      className="w-full mt-2"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className=" justify-end items-end flex">
            <div className="w-[40%] flex gap-[12px] mt-2">
              <button
                onClick={handleSetWaterMarkUpload}
                className="continueWaterMark"
              >
                {loadingApi ? "Processing..." : "Continue"}
              </button>
            </div>
          </div>
          {(loading || loadingApi) && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
              <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      )}
    </PopupWrapper>
  );
};

export default Watermark;
