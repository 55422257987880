import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { userAuthContext } from "../../Context/UserAuthContext";
import { ref, uploadBytesResumable } from "firebase/storage";
import { photosharetool } from "../../store/firebase-config";
import axios from "axios";
import QRCode from "react-qr-code";
import { dataContext } from "../../Context/DataContext";
import { bucketStorageMap } from "../../store/firebase-config";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";

const QRPopup = ({ qrCodeValue, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">QR Code</h2>
          <button
            onClick={onClose}
            className="text-red-500 text-lg font-bold hover:text-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          {qrCodeValue ? (
            <>
              <QRCode value={qrCodeValue} size={200} />
              {/* <p className="text-sm mt-2  break-words text-center">
                {qrCodeValue}
              </p> */}
            </>
          ) : (
            <p className="text-sm text-gray-500">No QR Code Available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const PhotoShareUserSelection = () => {
  const [qrCodeValue, setQRCodeValue] = useState(null); // Value for QR code
  const [showQRPopup, setShowQRPopup] = useState(false); // QR Popup State
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(0);
  const [albumDetails, setAlbumDetails] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleImages, setVisibleImages] = useState(100); // Number of images to display initially
  const { projectId, shootmatesAlbumId, sectionId } = useParams();
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, studioDetails } = useContext(dataContext);

  useEffect(() => {
    verifyUser();
    const fetchData = async () => {
      if (studioId) {
        setLoading(true);
        try {
          await getUploadedMediasData();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [studioId, refresh]);

  const splitString = (str) => {
    const parts = str.split("/");
    return `${parts[2]}/${parts[3]}`;
  };

  const getThumbnail = (url) => {
    const replacements = ["aimemshots", "shootmatesprivatecloud", "albumdata"];
    const targetReplacement = "albumthumbnails";
    const urlParts = url.split("/");
    const updatedParts = urlParts.map((part) =>
      replacements.includes(part) ? targetReplacement : part
    );
    const lastPart = updatedParts.at(-1);
    const modifiedFilename = lastPart.includes(".")
      ? `d_${lastPart}`
      : `d_${lastPart}`;
    updatedParts[updatedParts.length - 1] = modifiedFilename;
    return updatedParts.join("/");
  };
 
  const getQrcodeUrl = (fileId) => {
    return `https://${
      studioDetails?.studioUniqueText || "photosharetool"
    }.memshots.com/${albumDetails?.slugId}/qrsharephoto?imgid=${splitString(
      fileId
    )}`;
  };

  const getBucketName = (type) => {
    switch (type) {
      case "photoselection":
      case "album":
      case "photosharetool":
        return "albumdata";
      case "aipaid":
        return "shootmatesprivatecloud";
      case "aiunpaid":
        return "aimemshots";
      default:
        return "albumdata";
    }
  };

  const getUploadedMediasData = useCallback(async () => {
    try {
      const config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/getallimagesfromalbum/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      let mediaFiles = [];
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        const sections = response?.data?.data?.sections || [];
        const mediaDatas = await Promise.all(
          sections.map(async (item) => {
            mediaFiles.push(...item?.sectionData?.media);
          })
        );
        mediaFiles.sort(
          (a, b) => new Date(b.uploadedTime) - new Date(a.uploadedTime)
        );

        setImages(mediaFiles);
      }
    } catch (error) {
      console.error("Error fetching album details:", error);
    }
  }, [projectId, studioId, shootmatesAlbumId, tokenData]);

  // const getAllMediaFromAlbum = async (memshotAlbumId, type) => {
  //   try {
  //     const bucketName = getBucketName(type);
  //     const selectedStorage = bucketStorageMap[bucketName];
  //     if (!selectedStorage) throw new Error("Invalid bucket name.");
  //     const albumRef = ref(selectedStorage, `${memshotAlbumId}/`);
  //     const albumResult = await listAll(albumRef);
  //     const folderPrefixes = albumResult.prefixes;

  //     const allMediaFiles = [];

  //     // Process each folder concurrently
  //     await Promise.all(
  //       folderPrefixes.map(async (folderRef) => {
  //         const folderResult = await listAll(folderRef);

  //         // Fetch metadata and process media files
  //         const mediaFiles = await Promise.all(
  //           folderResult.items
  //             .filter((item) => item.name !== "folderCheck.keep")
  //             .map(async (item) => {
  //               const metadata = await getMetadata(item); // Fetch metadata
  //               return {
  //                 name: item.name,
  //                 fullPath: `https://storage.googleapis.com/${bucketName}/${item.fullPath}`,
  //                 folder: folderRef.name,
  //                 qrurl: `https://${
  //                   studioDetails?.studioUniqueText || "photosharetool"
  //                 }.memshots.com/${
  //                   albumDetails?.slugId
  //                 }/qrsharephoto?imgid=${splitAtFirstSlash(item.fullPath)}`,
  //                 thumbnail: `https://storage.googleapis.com/albumthumbnails/${memshotAlbumId}/${folderRef.name}/d_${item.name}`,
  //                 createdAt: new Date(metadata.timeCreated), // Add createdAt
  //               };
  //             })
  //         );

  //         // Sort media files by createdAt in descending order
  //         mediaFiles.sort((a, b) => b.createdAt - a.createdAt);

  //         allMediaFiles.push(...mediaFiles);
  //       })
  //     );

  //     setImages(allMediaFiles); // Update state with sorted files
  //   } catch (error) {
  //     console.error("Error fetching media from album:", error);
  //   }
  // };

  const handleUpload = () => {
    const bucketName = getBucketName(albumDetails?.type);
    const selectedStorage = bucketStorageMap[bucketName];
    if (!selectedStorage) throw new Error("Invalid bucket name.");
    setIsUploading(true);
    const name = `${Date.now()}`;
    const jsonData = {
      media: selectedImages,
      studioUniqueText: studioDetails?.studioUniqueText || "photosharetool",
      slugId: albumDetails?.slugId,
      name: name,
    };
    const jsonBlob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });

    const storageRef = ref(
      photosharetool,
      `${albumDetails?.memshotAlbumId}/${name}.json`
    );

    const uploadTask = uploadBytesResumable(storageRef, jsonBlob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      async () => {
        setIsUploading(false);
        setQRCodeValue(
          `https://${
            studioDetails?.studioUniqueText || "photosharetool"
          }.memshots.com/${albumDetails?.slugId}/QRPhotoShare/${name}`
        );
        setShowQRPopup(true);
      }
    );
  };

  const toggleSelectImage = (id) => {
    setSelectedImages(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((imageId) => imageId !== id) // Deselect
          : [...prevSelected, id] // Select
    );
  };

  const refreshSelection = () => {
    setRefresh(!refresh);
  };

  const clearCart = () => {
    setSelectedImages([]);
  };

  const loadMoreImages = () => {
    setVisibleImages((prev) => prev + 20); // Increase visible images by 20
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        loadMoreImages();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
console.log("images",images);

  return (
    <div className="min-h-screen bg-[#121212] text-white">
      <header
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
          background: " #161616",
        }}
        className="p-6 flex justify-between items-center "
      >
        <div className="flex gap-[18px]">
          <div className="max-w-[44px] max-h-[44px]">
            <img
              src={studioDetails?.studioLogoDark}
              className="w-full h-full object-contain"
              alt=""
            />
          </div>
          <h1 className="text-[16px]  font-[400] tracking-wide">
            {studioDetails?.studioName}
          </h1>
        </div>
        <div className="flex text-[#989898] text-[12px] items-center gap-[24px]">
          <p> {studioDetails?.email}</p>
          <p> {studioDetails?.phone_number}</p>
        </div>
      </header>

      <header className="p-6 flex justify-between items-center">
        <h1 className="text-[16px] font-[400] tracking-wide">
          {albumDetails?.albumName}
        </h1>
        <div className="flex items-center justify-center space-x-6">
          <p className="text-lg font-semibold">
            Selected: {selectedImages.length}
          </p>
          {selectedImages?.length > 0 && (
            <>
              <button
                onClick={handleUpload}
                className="bg-[#4B4B4B] flex gap-[6px] items-center px-[12px]  py-[6px] text-[14px] font-[500] rounded-[4px]"
              >
                <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                {isUploading ? "Generating Qr Code" : "   Generate QR Code"}
              </button>
              <button
                onClick={clearCart}
                className=" bg-red-500 flex gap-[6px] items-center px-[12px]  py-[6px] text-[14px] font-[500] rounded-[4px]"
              >
                Clear Selection
              </button>
            </>
          )}

          <button
            onClick={refreshSelection}
            className="flex items-center text-[14px] font-[400] gap-[6px] border-[1px] border-[#fff] px-[10px] py-[6px] rounded-[4px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M20 4V9H15"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            Refresh
          </button>
        </div>
      </header>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid grid-cols-5 sm:grid-cols-6 gap-6 p-[30px]">
          {images.slice(0, visibleImages).map((image) => (
            <div
              key={image?._id}
              className={`relative rounded-lg  border-[1px] ${
                selectedImages.includes(image?.url)
                  ? "border-green-400"
                  : "border-gray-300"
              } transition transform hover:scale-105 w-[200px] `}
            >
              <img
                src={getThumbnail(image?.url)}
                alt={image.name}
                className="w-full h-[237px] object-cover"
                onClick={() => toggleSelectImage(image?.url)}
              />
              <div className="my-[10px] justify-center items-center flex">
                <QRCode value={getQrcodeUrl(image?.fileId)} size={80} />
              </div>

              <label
                className="absolute top-2 right-2 z-10"
                htmlFor={`checkbox-${image?.url}`}
              >
                <input
                  type="checkbox"
                  id={`checkbox-${image?.url}`}
                  checked={selectedImages.includes(image?.url)}
                  onChange={() => toggleSelectImage(image?.url)}
                  className="w-6 h-6 rounded border-2 border-white bg-transparent text-green-500 focus:ring-2 focus:ring-green-500"
                />
              </label>
            </div>
          ))}
        </div>
      )}
      {visibleImages < images.length && (
        <div className="text-center my-6">
          <button
            onClick={loadMoreImages}
            className="px-6 py-3 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-500 transition duration-200"
          >
            View More
          </button>
        </div>
      )}
      {/* QR Popup */}
      {showQRPopup && (
        <QRPopup
          qrCodeValue={qrCodeValue}
          onClose={() => setShowQRPopup(false)}
        />
      )}
    </div>
  );
};

export default PhotoShareUserSelection;
