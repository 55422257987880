import React from "react";

function ExceedPopup({ onClose, handleEventCreationExceed }) {
  return (
    <div class="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-[1000]">
      <div class="bg-white mx-auto p-[20px] rounded-[8px] relative   w-[332px]">
        <div
          onClick={onClose}
          className="cursor-pointer absolute top-4 right-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 50 50"
          >
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
          </svg>
        </div>
        <div class="justify-center items-center flex flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
          >
            <path
              d="M17.9183 6.5L6.5 17.9183V34.0817L17.9183 45.5H34.0817L45.5 34.0817V17.9183L34.0817 6.5M18.2217 15.1667L26 22.945L33.7783 15.1667L36.8333 18.2217L29.055 26L36.8333 33.7783L33.7783 36.8333L26 29.055L18.2217 36.8333L15.1667 33.7783L22.945 26L15.1667 18.2217"
              fill="#FE6753"
            ></path>
          </svg>
        </div>

        <p class="text-gray-700 text-center mt-[16px]">
          Your event creation limit has been exceeded. You can create events
          under the Flexi package or contact the Memshost Admin.
        </p>

        <div className="flex mt-[20px] justify-center items-center gap-[40px]">
          <button
            onClick={() => {
              onClose();
            }}
            className="py-[8px]  px-[16px] rounded-[10px] border-[1px] border-opacity-30 border-[#111] font_semibold_12"
          >
            Cancel
          </button>
          <button
            className="eventButton whitespace-nowrap font_semibold_12"
            onClick={() => handleEventCreationExceed("FLEXI")}
          >
            Continue with flexi
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExceedPopup;
