import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import "./EventDetailView.css";
import ClientForm from "../../Components/Forms/ClientForm/ClientForm";
import AlbumCreateForm from "../../Components/AlbumSection/AlbumCreateForm";
import MessagePopupAlert from "../../Components/MessagePopupAlert/MessagePopupAlert";
import ConfirmationPopup from "../../Components/ConfirmationPopup/ConfirmationPopup";
import SelectionToolForm from "../../Components/SelectionToolSection/SelectionToolForm";
import FaceDetectionForm from "../../Components/FaceDetectionSection/FaceDetectionForm";
import PhotoShareToolForm from "../../Components/PhotoShareToolForm/PhotoShareToolForm";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Lottie from "../../Assets/Animation/Loading";
import LeadForm from "../../Components/Forms/LeadForm/LeadForm";
// import eventSignatureAlbum from "../../Assets/eventSignatureAlbum.svg";
// import photoShare from "../../Assets/photoshare.svg";
// import invitation from "../../Assets/invitation.svg";
// import eventInvitation from "../../Assets/eventInvitation.svg";
// import eventFaceDetection from "../../Assets/eventFaceDetection.svg";
import InvitationCreateFormNew from "../../Components/AlbumSection/InvitationCreateFormNew";
import SelectionToolSectionDuplication from "../../Components/SelectionToolSectionDuplication/SelectionToolSectionDuplication";
import ServicePayPopUp from "../../Components/ServicePayPopUp/ServicePayPopUp";
import NoData from "../../Components/NoData/NoData";
import whiteAddButton from "../../Assets/whiteAddButton.svg";
import CreateServicePopup from "../../Components/CreateServocePopUp/CreateServicePopup";

function EventDetailView() {
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [pricingData, setPricingData] = useState({});
  const tokenData = userIdToken || currentUser?.accessToken;
  const {
    studioId,
    getStudioStorageOfAService,
    roleSelected,
    bytesToGB,
    loading,
    setLoading,
    flexiPackagePayment,
    usedSize,
    activePackages,
    flexiPackagePaymentInitialise,
  } = useContext(dataContext);
  const { eventId } = useParams();
  const [update, setUpdate] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [clientEdit, setClientEdit] = useState(false);
  const [leadEdit, setLeadEdit] = useState(false);
  const [createAlbum, setCreateAlbum] = useState(false);
  const [selectionToolDuplication, setSelectionDuplication] = useState(false);
  const [selectedTool, setSelectedTool] = useState({});
  const [createInvitation, setCreateInvitation] = useState(false);
  const [createSelectionTool, setCreateSelectionTool] = useState(false);
  const [createLiveFaceDetection, setCreateLiveFaceDetection] = useState(false);
  const [createPhotoShare, setCreatePhotoShare] = useState(false);
  const [storageData, setStorageData] = useState({});
  const [storageLoading, setStorageLoading] = useState(false);
  const [usedPercentage, setUsedPercentage] = useState();
  const [selectedService, setSelectedService] = useState("");
  const [openServicePopup, setOpenServicePopup] = useState(false);
  const [copied, setCopied] = useState("");

  const [forPayment, setForPayment] = useState({
    data: {},
    bool: false,
  });
  const Ref = useRef(null);

  useEffect(() => {
    Ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  const albumTypes = [
    {
      id: 1,
      name: "Signature Album",
      key: "SIGNATUREALBUM",
    },
    {
      id: 2,
      name: "Online Invitation",
      key: "ONLINEINVITATION",
    },
    {
      id: 3,
      name: "Photo Selection",
      key: "PHOTOSELECTION",
    },
    {
      id: 4,
      name: "AI Photo Delivery",
      key: "AIPHOTODELIVERY",
    },
    {
      id: 5,
      name: "Photoshare Tool",
      key: "PHOTOSHARETOOL",
    },
  ];
  const navigate = useNavigate();
  const values = {
    hasEmailTask: false,
    isDrag: true,
    isCrew: false,
    workFlowTaskColor: "#000000",
  };

  useEffect(() => {
    verifyUser();
    if (studioId) {
      getData();
      getProjectDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  useEffect(() => {
    if (leadDetails?.albums) {
      leadDetails?.albums.forEach((album) => {
        handleStorageByService(album?.shootmatesalbumId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadDetails]);

  const getData = async () => {
    try {
      const response = await axios.get(`/json/pricing.json`);
      setPricingData(response?.data?.packages);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectDetails = async () => {
    try {
      setLoading(true);
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesProject/api/shootmates/project/eventdetails/${eventId}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLeadDetails(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const clientObj = leadDetails?.client;
  const leadObj = leadDetails;

  const dayOfWeek = (date) => {
    const currentDate = new Date(date);
    let dayOfWeek = currentDate.toLocaleString("default", { weekday: "long" });
    if (dayOfWeek === "Monday") {
      dayOfWeek = "MON";
    } else if (dayOfWeek === "Tuesday") {
      dayOfWeek = "TUE";
    } else if (dayOfWeek === "Wednesday") {
      dayOfWeek = "WED";
    } else if (dayOfWeek === "Thursday") {
      dayOfWeek = "THU";
    } else if (dayOfWeek === "Friday") {
      dayOfWeek = "FRI";
    } else if (dayOfWeek === "Saturday") {
      dayOfWeek = "SAT";
    } else if (dayOfWeek === "Sunday") {
      dayOfWeek = "SUN";
    } else dayOfWeek = "";
    return dayOfWeek;
  };

  const day = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    return day;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleAlert = () => {
    setAlertPopup(!isModalOpen);
  };

  const navigateToAlbum = (eventId, obj) => {
    const basePath = `/Event/${eventId}`;
    let path = "";
    switch (obj?.albumType) {
      case "FACEDETECTION":
        path = `${basePath}/facedetection/${obj?.shootmatesalbumId}`;
        break;
      case "INVITATION":
        path = `${basePath}/invitationdetails/${obj?.shootmatesalbumId}`;
        break;
      case "PHOTOSHARETOOL":
        path = `${basePath}/photosharetool/${obj?.shootmatesalbumId}`;
        break;
      case "SELECTIONTOOL":
        path = `${basePath}/selectiontool/${obj?.shootmatesalbumId}`;
        break;
      default:
        path = `${basePath}/albumdetail/${obj?.shootmatesalbumId}`;
    }
    navigate(path);
  };

  const getAlbumLinkText = (albumType) => {
    switch (albumType) {
      case "FACEDETECTION":
        return "AI PHOTO DELIVERY";
      case "SELECTIONTOOL":
        return "PHOTO SELECTION";
      case "INVITATION":
        return "INVITATION";
      case "PHOTOSHARETOOL":
        return "PHOTOSHARE";
      default:
        return "ALBUM";
    }
  };

  const handleStorageByService = async (id) => {
    try {
      setStorageLoading(true);
      const res = await getStudioStorageOfAService(roleSelected?.studioId, id);
      setStorageData((prev) => ({
        ...prev,
        [id]: res?.data,
      }));
      setStorageLoading(false);
    } catch (error) {
      console.error("Error fetching storage data:", error);
    }
  };

  const handlePayment = (
    studioId,
    albumId,
    packageCode,
    eventId,
    packageDetails,
    invoiceId
  ) => {
    const newData = {
      studioId,
      albumId,
      packageCode,
      eventId,
      packageDetails,
      invoiceId,
    };

    setForPayment((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...newData,
      },
      bool: true,
    }));
  };

  const handleCloseForPaymentPop = () => {
    setForPayment((prevState) => ({
      data: {},
      bool: false,
    }));
  };
  const bytesToMB = (bytes) => bytes / 1024 ** 2;
  useEffect(() => {
    setOpenServicePopup(false);
  }, [
    createPhotoShare,
    createAlbum,
    createInvitation,
    createSelectionTool,
    createLiveFaceDetection,
  ]);
  const handleCopy = (e, previewUrl) => {
    e.stopPropagation();

    navigator.clipboard
      .writeText(previewUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const getAlbumTypeLabel = (albumType) => {
    switch (albumType) {
      case "PHOTOSHARETOOL":
        return "Photoshare Tool";
      case "FACEDETECTION":
        return "AI Photo Delivery";
      case "SELECTIONTOOL":
        return "Photo Selection";
      case "ALBUM":
        return "Signature Album";
      case "INVITATION":
        return "Online Invitation";
      default:
        return albumType;
    }
  };

  return (
    <div ref={Ref} className="eventDetailContainer min-h-[100vh]">
      {forPayment?.bool && Object?.keys(forPayment?.data)?.length > 0 && (
        <ServicePayPopUp
          flexiPackagePaymentInitialise={flexiPackagePaymentInitialise}
          details={forPayment?.data}
          visible={forPayment?.bool}
          loading={loading}
          onClose={handleCloseForPaymentPop}
        />
      )}
      <div className="justify-between gap-[220px] items-center  flex">
        <Breadcrumb
          update={update}
          setUpdate={setUpdate}
          items={[{ label: "Event", url: "/Event" }]}
        />

        {/* <div className="flex justify-between mt-[12px] pr-[5%] w-full">
          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            } eventCreateClass`}
            onClick={() =>
              leadDetails?.packageType === "TRIAL" ? "" : setCreateAlbum(true)
            }
          >
            <div className="flex gap-[4px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.5 12.0002C10.5 13.3277 9.9225 14.5277 9 15.3452C8.205 16.0652 7.155 16.5002 6 16.5002C3.5175 16.5002 1.5 14.4827 1.5 12.0002C1.5 9.93021 2.91 8.17521 4.815 7.65771C5.3325 8.96271 6.4425 9.96771 7.815 10.3427C8.19 10.4477 8.5875 10.5002 9 10.5002C9.4125 10.5002 9.81 10.4477 10.185 10.3427C10.3875 10.8527 10.5 11.4152 10.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 6C13.5 6.585 13.3875 7.1475 13.185 7.6575C12.6675 8.9625 11.5575 9.9675 10.185 10.3425C9.81 10.4475 9.4125 10.5 9 10.5C8.5875 10.5 8.19 10.4475 7.815 10.3425C6.4425 9.9675 5.3325 8.9625 4.815 7.6575C4.6125 7.1475 4.5 6.585 4.5 6C4.5 3.5175 6.5175 1.5 9 1.5C11.4825 1.5 13.5 3.5175 13.5 6Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 12.0002C16.5 14.4827 14.4825 16.5002 12 16.5002C10.845 16.5002 9.795 16.0652 9 15.3452C9.9225 14.5277 10.5 13.3277 10.5 12.0002C10.5 11.4152 10.3875 10.8527 10.185 10.3427C11.5575 9.96771 12.6675 8.96271 13.185 7.65771C15.09 8.17521 16.5 9.93021 16.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px]  text-black">
                Signature Album
              </p>
            </div>
          </div>

          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            }  eventCreateClass`}
            onClick={() =>
              leadDetails?.packageType === "TRIAL"
                ? ""
                : setCreateInvitation(true)
            }
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M14.624 7.77588V14.6226C14.624 15.0701 14.4462 15.4993 14.1298 15.8158C13.8133 16.1323 13.3841 16.3101 12.9365 16.3101H5.06152C4.61397 16.3101 4.18475 16.1323 3.86828 15.8158C3.55181 15.4993 3.37402 15.0701 3.37402 14.6226V3.37256C3.37402 2.92501 3.55181 2.49578 3.86828 2.17932C4.18475 1.86285 4.61397 1.68506 5.06152 1.68506H8.5332C8.83147 1.6851 9.11751 1.80359 9.32844 2.01447L14.2946 6.98064C14.5055 7.19157 14.624 7.47761 14.624 7.77588Z"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.99902 1.96631V6.18506C8.99902 6.48343 9.11755 6.76958 9.32853 6.98055C9.53951 7.19153 9.82565 7.31006 10.124 7.31006H14.3428"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.18652 10.123H11.8115"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.18652 12.9351H11.8115"
                  stroke="#7000FF"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Online Invitation
              </p>
            </div>
          </div>

          <div
            className="eventCreateClass"
            onClick={() => setCreateSelectionTool(true)}
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.5 12.0002C10.5 13.3277 9.9225 14.5277 9 15.3452C8.205 16.0652 7.155 16.5002 6 16.5002C3.5175 16.5002 1.5 14.4827 1.5 12.0002C1.5 9.93021 2.91 8.17521 4.815 7.65771C5.3325 8.96271 6.4425 9.96771 7.815 10.3427C8.19 10.4477 8.5875 10.5002 9 10.5002C9.4125 10.5002 9.81 10.4477 10.185 10.3427C10.3875 10.8527 10.5 11.4152 10.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 6C13.5 6.585 13.3875 7.1475 13.185 7.6575C12.6675 8.9625 11.5575 9.9675 10.185 10.3425C9.81 10.4475 9.4125 10.5 9 10.5C8.5875 10.5 8.19 10.4475 7.815 10.3425C6.4425 9.9675 5.3325 8.9625 4.815 7.6575C4.6125 7.1475 4.5 6.585 4.5 6C4.5 3.5175 6.5175 1.5 9 1.5C11.4825 1.5 13.5 3.5175 13.5 6Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 12.0002C16.5 14.4827 14.4825 16.5002 12 16.5002C10.845 16.5002 9.795 16.0652 9 15.3452C9.9225 14.5277 10.5 13.3277 10.5 12.0002C10.5 11.4152 10.3875 10.8527 10.185 10.3427C11.5575 9.96771 12.6675 8.96271 13.185 7.65771C15.09 8.17521 16.5 9.93021 16.5 12.0002Z"
                  stroke="#E65F5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Photo Selection
              </p>
            </div>
          </div>

          <div
            className="eventCreateClass"
            onClick={() => setCreateLiveFaceDetection(true)}
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.66667 13.3332H3.33333C2.97971 13.3332 2.64057 13.1927 2.39052 12.9426C2.14048 12.6926 2 12.3535 2 11.9998V5.99984C2 5.64622 2.14048 5.30708 2.39052 5.05703C2.64057 4.80698 2.97971 4.6665 3.33333 4.6665H4C4.35362 4.6665 4.69276 4.52603 4.94281 4.27598C5.19286 4.02593 5.33333 3.68679 5.33333 3.33317C5.33333 3.15636 5.40357 2.98679 5.5286 2.86177C5.65362 2.73674 5.82319 2.6665 6 2.6665H10C10.1768 2.6665 10.3464 2.73674 10.4714 2.86177C10.5964 2.98679 10.6667 3.15636 10.6667 3.33317C10.6667 3.68679 10.8071 4.02593 11.0572 4.27598C11.3072 4.52603 11.6464 4.6665 12 4.6665H12.6667C13.0203 4.6665 13.3594 4.80698 13.6095 5.05703C13.8595 5.30708 14 5.64622 14 5.99984V7.33317"
                  stroke="#4D58E3"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.57464 7.43341C9.39867 7.2087 9.17664 7.02425 8.92347 6.89247C8.6703 6.76068 8.39185 6.68461 8.10685 6.66936C7.82184 6.65411 7.53687 6.70004 7.27108 6.80407C7.0053 6.90809 6.76486 7.06779 6.56591 7.27245C6.36697 7.4771 6.21413 7.72197 6.11768 7.99059C6.02122 8.25922 5.98337 8.54537 6.00668 8.82984C6.02999 9.1143 6.11391 9.39048 6.25281 9.63981C6.39171 9.88915 6.58237 10.1059 6.81197 10.2754M9.33331 14.0001V11.3334C9.33331 10.9798 9.47378 10.6407 9.72383 10.3906C9.97388 10.1406 10.313 10.0001 10.6666 10.0001C11.0203 10.0001 11.3594 10.1406 11.6094 10.3906C11.8595 10.6407 12 10.9798 12 11.3334V14.0001M9.33331 12.6667H12M14 10.0001V14.0001"
                  stroke="#4D58E3"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                AI Photo Delivery
              </p>
            </div>
          </div>

          <div
            className={`${
              leadDetails?.packageType === "TRIAL" && "opacity-40"
            } eventCreateClass`}
            onClick={() =>
              leadDetails?.packageType === "TRIAL"
                ? ""
                : setCreatePhotoShare(true)
            }
          >
            <div className="flex gap-[6px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.238 2C2.90966 2 2.59477 2.13043 2.3626 2.3626C2.13043 2.59477 2 2.90966 2 3.238V6.09533C2 6.77933 2.554 7.33333 3.238 7.33333H6.09533C6.42367 7.33333 6.73856 7.2029 6.97073 6.97073C7.2029 6.73856 7.33333 6.42367 7.33333 6.09533V3.238C7.33333 2.90966 7.2029 2.59477 6.97073 2.3626C6.73856 2.13043 6.42367 2 6.09533 2H3.238ZM9.90467 2C9.57633 2 9.26144 2.13043 9.02927 2.3626C8.7971 2.59477 8.66667 2.90966 8.66667 3.238V6.09533C8.66667 6.77933 9.22067 7.33333 9.90467 7.33333H12.762C13.0903 7.33333 13.4052 7.2029 13.6374 6.97073C13.8696 6.73856 14 6.42367 14 6.09533V3.238C14 2.90966 13.8696 2.59477 13.6374 2.3626C13.4052 2.13043 13.0903 2 12.762 2H9.90467ZM3.238 8.66667C2.90966 8.66667 2.59477 8.7971 2.3626 9.02927C2.13043 9.26144 2 9.57633 2 9.90467V12.762C2 13.446 2.554 14 3.238 14H6.09533C6.42367 14 6.73856 13.8696 6.97073 13.6374C7.2029 13.4052 7.33333 13.0903 7.33333 12.762V9.90467C7.33333 9.57633 7.2029 9.26144 6.97073 9.02927C6.73856 8.7971 6.42367 8.66667 6.09533 8.66667H3.238ZM12 9.33333C12 9.15652 11.9298 8.98695 11.8047 8.86193C11.6797 8.73691 11.5101 8.66667 11.3333 8.66667C11.1565 8.66667 10.987 8.73691 10.8619 8.86193C10.7369 8.98695 10.6667 9.15652 10.6667 9.33333V10.6667H9.33333C9.15652 10.6667 8.98695 10.7369 8.86193 10.8619C8.73691 10.987 8.66667 11.1565 8.66667 11.3333C8.66667 11.5101 8.73691 11.6797 8.86193 11.8047C8.98695 11.9298 9.15652 12 9.33333 12H10.6667V13.3333C10.6667 13.5101 10.7369 13.6797 10.8619 13.8047C10.987 13.9298 11.1565 14 11.3333 14C11.5101 14 11.6797 13.9298 11.8047 13.8047C11.9298 13.6797 12 13.5101 12 13.3333V12H13.3333C13.5101 12 13.6797 11.9298 13.8047 11.8047C13.9298 11.6797 14 11.5101 14 11.3333C14 11.1565 13.9298 10.987 13.8047 10.8619C13.6797 10.7369 13.5101 10.6667 13.3333 10.6667H12V9.33333Z"
                  fill="#FA6666"
                />
              </svg>
              <p className="font-medium text-[14px] text-black">
                Photo Share Tool
              </p>
            </div>
          </div>
        </div> */}
      </div>

      {openServicePopup && (
        <CreateServicePopup
          setOpenServicePopup={setOpenServicePopup}
          visible={openServicePopup}
          setCreatePhotoShare={setCreatePhotoShare}
          leadDetails={leadDetails}
          setCreateAlbum={setCreateAlbum}
          setCreateInvitation={setCreateInvitation}
          setCreateSelectionTool={setCreateSelectionTool}
          setCreateLiveFaceDetection={setCreateLiveFaceDetection}
        />
      )}
      {clientEdit && (
        <ClientForm
          visible={clientEdit}
          setpopUp={setClientEdit}
          clientObj={clientObj}
          idkey="update"
        />
      )}
      {leadEdit && (
        <LeadForm
          visible={leadEdit}
          setpopUp={setLeadEdit}
          leadObj={leadObj}
          idkey="update"
        />
      )}
      <ConfirmationPopup isOpen={isModalOpen} toggleModal={toggleModal} />
      <MessagePopupAlert isOpen={alertPopup} toggleModal={toggleAlert} />
      {createAlbum && (
        <AlbumCreateForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createAlbum}
          setAlbum={setCreateAlbum}
          values={values}
          eventType={
            leadDetails?.eventType === "CORPORATE EVENTS" ||
            leadDetails?.eventType === "OTHER"
              ? "CORPORATE_EVENTS"
              : leadDetails?.eventType
          }
          packageType={leadDetails?.packageType}
        />
      )}
      {createInvitation && (
        <InvitationCreateFormNew
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createInvitation}
          setAlbum={setCreateInvitation}
          values={values}
          eventType={
            leadDetails?.eventType === "CORPORATE EVENTS"
              ? "CORPORATE_EVENTS"
              : leadDetails?.eventType
          }
          // eventType={leadDetails?.eventType}
          packageType={leadDetails?.packageType}
        />
      )}
      {createLiveFaceDetection && (
        <FaceDetectionForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createLiveFaceDetection}
          setAlbum={setCreateLiveFaceDetection}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {createPhotoShare && (
        <PhotoShareToolForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createPhotoShare}
          setAlbum={setCreatePhotoShare}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {createSelectionTool && (
        <SelectionToolForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createSelectionTool}
          setAlbum={setCreateSelectionTool}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {selectionToolDuplication && (
        <SelectionToolSectionDuplication
          update={update}
          priceData={pricingData}
          setUpdate={setUpdate}
          visible={selectionToolDuplication}
          setAlbum={setSelectionDuplication}
          selectedToolData={selectedTool}
          packageType={leadDetails?.packageType}
        />
      )}
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <>
          <div className="flex gap-[65px] px-[20px] py-[12px] ">
            <div className="flex  w-full ">
              <div className="flex-shrink-0  eventDetailSideSeperator pr-[7px] flex flex-col gap-[3px] items-center h-fit">
                <p
                  className={`font_medium_14 pr-[18px] ${
                    dayOfWeek(leadDetails?.startDate) === "SUN"
                      ? "text-[#E34D4D]"
                      : "text-[#161616B2]"
                  }`}
                >
                  {dayOfWeek(leadDetails?.startDate)}
                </p>
                <p
                  className={`dateNumber pr-[18px] ${
                    dayOfWeek(leadDetails?.startDate) === "SUN"
                      ? "text-[#E34D4D]"
                      : "text-[#161616B2]"
                  }`}
                >
                  {day(leadDetails?.startDate)}
                </p>
              </div>
              <div className="w-full pl-[13px] flex flex-col gap-[13px]">
                <p className="eventType">{leadDetails?.eventType}</p>
                <div className="flex  justify-between items-center w-full ">
                  <p className="eventName flex gap-[4px] items-center">
                    {leadDetails?.leadName}{" "}
                    {/* <svg
                      onClick={() => setUpdate(!update)}
                      class="flex-shrink-0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
                        stroke="#4D58E3"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M16.6667 3.33301V7.49967H12.5"
                        stroke="#4D58E3"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg> */}
                  </p>
                  <p className="serviceLength">
                    {leadDetails?.albums?.length > 0 &&
                      `${
                        leadDetails?.albums?.length === 1
                          ? `${leadDetails?.albums?.length} Service Created`
                          : `${leadDetails?.albums?.length} Services Created`
                      } `}
                  </p>
                </div>
                <p className="text-xs  text-[#161616] font-[400] ">
                  {leadDetails?.packageType?.charAt(0).toUpperCase() +
                    leadDetails?.packageType?.slice(1).toLowerCase()}{" "}
                  Package{" "}
                </p>
                <p className="text-xs  text-[#161616] font-[400] ">
                  {leadDetails?.totalMedias}
                </p>
              </div>
            </div>
          </div>

          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="flex justify-between items-center px-[20px] pb-[8px] pt-[12px] ">
            <p className="text-[20px] font-[500] ">Purchased Services</p>

            {leadDetails?.packageType === "YEARLY" ? (
              activePackages?.packageStatus === "ACTIVE" ? (
                <button
                  onClick={() => setOpenServicePopup(true)}
                  className="eventButton"
                >
                  {" "}
                  <img
                    src={whiteAddButton}
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                  <p className="font_semibold_12 ">Create Service</p>
                </button>
              ) : (
                <div className="paymentErrorBoxDiv w-[252px]">
                  <div className="flex items-center justify-center gap-[10px]">
                    <p className="text-[14px] leading-[18px]">
                      Expiry Alert Your package has expired. To create a new
                      event or service, please upgrade your package or contact
                      Memshots Admin.
                    </p>
                  </div>
                </div>
              )
            ) : (
              <button
                onClick={() => setOpenServicePopup(true)}
                className="eventButton"
              >
                {" "}
                <img
                  src={whiteAddButton}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <p className="font_semibold_12 ">Create Service</p>
              </button>
            )}
          </div>
          {/* <div className="seperatorHorizontalNew mt-[6px]"></div> */}
          {/* <div className="px-[20px]  gap-[14px] py-[18px] flex items-center ">
            <p className="service">Service</p>
            <div className="bg-[#F8F8F8] flex gap-[5px] rounded-[10px] p-[5px]">
              {albumTypes?.map((item) => (
                <button
                  onClick={() => setSelectedService(item?.key)}
                  className={`${
                    item?.key === selectedService
                      ? "buttonServiceNameSel"
                      : "buttonServiceName"
                  }  `}
                >
                  {item?.name}
                </button>
              ))}
            </div>
          </div> */}

          {/* <div className=" px-5 py-4 flex flex-col sm:flex-row sm:justify-between gap-4 w-full">
            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              } cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL" ? "" : setCreateAlbum(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-red-100 flex-shrink-0 rounded-lg p-2">
                  <img
                    src={eventSignatureAlbum}
                    alt="album"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px]  text-black">
                  Signature Album
                </p>
              </div>
              <div>
                <p className="font-medium  text-[14px] text-red-500">Add</p>
              </div>
            </div>

            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              }  cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL"
                  ? ""
                  : setCreateInvitation(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-blue-100 rounded-lg p-2">
                  <img
                    src={invitation}
                    alt="invitation"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Online Invitation
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-blue-500">Add</p>
              </div>
            </div>

            <div
              className="cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors"
              onClick={() => setCreateSelectionTool(true)}
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-blue-100 rounded-lg p-2">
                  <img
                    src={eventInvitation}
                    alt="invitation"
                    className="w-7 h-7"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Photo Selection
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-blue-500">Add</p>
              </div>
            </div>

            <div
              className="cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors"
              onClick={() => setCreateLiveFaceDetection(true)}
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-yellow-100 rounded-lg p-2">
                  <img
                    src={eventFaceDetection}
                    alt="faceDetection"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  AI Photo Delivery
                </p>
              </div>
              <div>
                <p className="font-medium text-sm text-yellow-500">Add</p>
              </div>
            </div>

            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              } cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL"
                  ? ""
                  : setCreatePhotoShare(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-yellow-100 rounded-lg p-2">
                  <img
                    src={photoShare}
                    className="w-[20px] h-[20px]"
                    alt="faceDetection"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Photo Share Tool
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-yellow-500">Add</p>
              </div>
            </div>
          </div> */}
          {/* <div className="seperatorHorizontalNew mt-[6px]"></div> */}
          {/* <div className="eventDetailsTable space-y-4">
            {leadDetails?.albums?.map((obj, i) => {
              return (
                <div
                  key={i}
                  className="flex justify-between gap-[20px] items-center p-3 bg-white rounded-lg shadow-md hover:bg-blue-100 cursor-pointer transition-colors"
                  onClick={() =>
                    obj?.paymentStatus == "PENDING"
                      ? ""
                      : navigateToAlbum(eventId, obj)
                  }
                >
                  <div className="flex justify-between gap-[30px] items-start">
                    <div
                      className={`rounded-lg p-3 ${
                        obj?.albumType === "FACEDETECTION"
                          ? "bg-yellow-100"
                          : obj?.albumType === "SELECTIONTOOL"
                          ? "bg-blue-100"
                          : obj?.albumType === "INVITATION"
                          ? "bg-blue-100"
                          : obj?.albumType === "PHOTOSHARETOOL"
                          ? "bg-blue-100"
                          : "bg-red-100"
                      }`}
                    >
                      <img
                        src={
                          obj?.albumType === "FACEDETECTION"
                            ? eventFaceDetection
                            : obj?.albumType === "SELECTIONTOOL"
                            ? eventInvitation
                            : obj?.albumType === "INVITATION"
                            ? invitation
                            : obj?.albumType === "PHOTOSHARETOOL"
                            ? photoShare
                            : eventSignatureAlbum
                        }
                        alt={obj?.albumType}
                        className="w-7 h-7"
                      />
                    </div>

                    <div className="ml-3 flex flex-grow flex-col gap-1">
                      <p className="font-medium text-lg text-gray-900">
                        {obj?.albumName}
                      </p>
                      <p className={`text-base transition-colors `}>
                        {getAlbumLinkText(obj?.albumType)}
                      </p>
                    </div>

                    {obj?.packageType == "FLEXI" && storageLoading ? (
                      <p className="text-[16px] font-[400] ">Loading...</p>
                    ) : (
                      <div className="storage ">
                        <p>My Storage</p>

                        <div className="w-[300px] mt-[16px] bg-[#DDD]  h-[15px] rounded-[4px]">
                          <div
                            className="bg-blue-600 h-4 rounded-full"
                            style={{
                              width: `${
                                (storageData[obj?.shootmatesalbumId]
                                  ?.totalMediaCount /
                                  storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize) *
                                  100 || 0
                              }%`,
                            }}
                          ></div>
                        </div>
                        <div className="storageusedText mt-[9px]">
                          {bytesToGB(
                            storageData[obj?.shootmatesalbumId]
                              ?.totalMediaCount || 0
                          )}{" "}
                          GB used,{" "}
                          {bytesToGB(
                            storageData[obj?.shootmatesalbumId]
                              ?.totalMediaSize || 0
                          )}{" "}
                          GB available
                        </div>
                      </div>
                    )}
                  </div>

                  {obj?.albumType === "SELECTIONTOOL" && (
                    <button
                      className="ml-auto py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectionDuplication(true);
                        setSelectedTool(obj);
                      }}
                    >
                      Duplicate
                    </button>
                  )}

                  {obj?.packageDetails?.packageCode &&
                  obj?.packageType == "FLEXI" &&
                  obj?.paymentStatus == "PENDING" ? (
                    <div className="flex-col flex">
                      <p className="whitespace-nowrap">
                        Payment Status - {obj?.paymentStatus}
                      </p>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          flexiPackagePayment(
                            studioId,
                            obj?.shootmatesalbumId,
                            obj?.packageDetails?.packageCode?.replace(/'/g, ""),
                            eventId
                          );
                        }}
                        className="rounded-[10px] mt-2 text-[#fff] px-[15px] py-[8px] bg-[#3780ee]"
                      >
                        Pay Now
                      </button>
                    </div>
                  ) : (
                    ""
                    // <p className="whitespace-nowrap">
                    //   Payment Status - {obj?.paymentStatus}
                    // </p>
                  )}
                </div>
              );
            })}
          </div> */}

          {/* {leadDetails} */}
          {console.log(leadDetails?.albums, "albums")}

          {!loading && leadDetails?.albums?.length > 0 ? (
            <>
              {leadDetails?.albums?.map((obj, i) => (
                <div
                  onClick={() =>
                    obj?.packageType === "FLEXI"
                      ? obj?.paymentPlan === "ONETIME" ||
                        obj?.paymentPlan === ""
                        ? obj?.paymentStatus !== "PAYMENT_SUCCESS"
                          ? ""
                          : navigateToAlbum(eventId, obj)
                        : navigateToAlbum(eventId, obj)
                      : navigateToAlbum(eventId, obj)
                  }
                  className={` cursor-pointer ${
                    obj?.paymentStatus === "PENDING" ? "" : "clickable"
                  }  p-[6px]`}
                >
                  <div className="serviceListDiv p-[20px] justify-between items-center flex">
                    <div className="min-w-[200px]">
                      <p className="serviceName">{obj?.albumName}</p>
                      <p className="serviceType mt-[8px]">
                        {getAlbumTypeLabel(obj?.albumType)}
                      </p>
                    </div>

                    {
                      // obj?.packageType != "YEARLY"  (
                      obj?.packageDetails?.packageCode &&
                      obj?.packageType === "FLEXI" ? (
                        obj?.paymentStatus !== "PAYMENT_SUCCESS" ? (
                          <div className="justify-end items-center gap-[20px] flex ">
                            {obj?.paymentPlan === "PAYLATER" && (
                              <div className="flex flex-col ">
                                {obj?.packageDetails?.storageInGb && (
                                  <div className="w-[171px] rounded-[4px] h-[7px]">
                                    <div
                                      className={`w-full rounded-[4px] h-full ${
                                        storageData[obj?.shootmatesalbumId]
                                          ?.totalMediaSize &&
                                        obj?.packageDetails?.storageInGb &&
                                        bytesToGB(
                                          storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize
                                        ) >
                                          (obj?.packageDetails?.storageInGb ||
                                            0)
                                          ? "bg-[#FA6666]"
                                          : "bg-[#FFD09A]"
                                      } `}
                                    >
                                      <div
                                        style={{
                                          width: `${
                                            obj?.packageDetails?.storageInGb &&
                                            storageData[obj?.shootmatesalbumId]
                                              ?.totalMediaSize
                                              ? bytesToGB(
                                                  storageData[
                                                    obj?.shootmatesalbumId
                                                  ]?.totalMediaSize
                                                ) >
                                                obj?.packageDetails?.storageInGb
                                                ? (
                                                    bytesToGB(
                                                      storageData[
                                                        obj?.shootmatesalbumId
                                                      ]?.totalMediaSize
                                                    ) -
                                                    obj?.packageDetails
                                                      ?.storageInGb
                                                  ).toFixed(2)
                                                : (
                                                    (storageData[
                                                      obj?.shootmatesalbumId
                                                    ]?.totalMediaSize /
                                                      (obj?.packageDetails
                                                        ?.storageInGb *
                                                        1024 ** 3)) *
                                                    100
                                                  ).toFixed(2)
                                              : 0
                                          }%`,
                                        }}
                                        className={`max-w-[100%] rounded-[4px] h-full ${
                                          storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize &&
                                          bytesToGB(
                                            storageData[obj?.shootmatesalbumId]
                                              ?.totalMediaSize
                                          ) >
                                            (obj?.packageDetails?.storageInGb ||
                                              0)
                                            ? "bg-[#D42F2F]"
                                            : "bg-[#D8B1FF]"
                                        }`}

                                        // className={` max-w-[100%] rounded-[4px] h-full  bg-[#D8B1FF] `}
                                      ></div>
                                    </div>
                                  </div>
                                )}

                                <p className="storageText mt-2  min-w-[70px] ">
                                  {storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize
                                    ? storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize >=
                                      1024 ** 3
                                      ? `${bytesToGB(
                                          storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize
                                        )?.toFixed(2)} GB used`
                                      : `${bytesToMB(
                                          storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize
                                        )?.toFixed(2)} MB used`
                                    : "0 MB used"}
                                  {obj?.packageType === "FLEXI" &&
                                    obj?.packageDetails?.storageInGb &&
                                    ` of  ${
                                      obj?.packageDetails?.storageInGb || 0
                                    }  GB
       `}
                                </p>
                              </div>
                            )}

                            <div className="paymentErrorBoxDiv w-[252px]">
                              <div className="flex items-center justify-center gap-[10px]">
                                <svg
                                  className="flex-shrink-0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.4"
                                    d="M7.99967 14.6673C11.6816 14.6673 14.6663 11.6825 14.6663 8.00065C14.6663 4.31875 11.6816 1.33398 7.99967 1.33398C4.31778 1.33398 1.33301 4.31875 1.33301 8.00065C1.33301 11.6825 4.31778 14.6673 7.99967 14.6673Z"
                                    fill="#FA6666"
                                  />
                                  <path
                                    d="M10.4998 7.15268V6.66602C10.4998 6.04602 10.4998 4.16602 7.99976 4.16602C5.49976 4.16602 5.49976 6.04602 5.49976 6.66602V7.15268C4.68642 7.33268 4.41309 7.85935 4.41309 8.99935V9.66602C4.41309 11.1327 4.86642 11.586 6.33309 11.586H9.66642C11.1331 11.586 11.5864 11.1327 11.5864 9.66602V8.99935C11.5864 7.85935 11.3131 7.33268 10.4998 7.15268ZM7.99976 10.066C7.59309 10.066 7.26644 9.73935 7.26644 9.33268C7.26644 8.92602 7.59309 8.59935 7.99976 8.59935C8.40642 8.59935 8.73307 8.92602 8.73307 9.33268C8.73307 9.73935 8.40642 10.066 7.99976 10.066ZM9.49976 7.07935H6.49976V6.66602C6.49976 5.69268 6.73976 5.16602 7.99976 5.16602C9.25976 5.16602 9.49976 5.69268 9.49976 6.66602V7.07935Z"
                                    fill="#FA6666"
                                  />
                                </svg>
                                <p>
                                  You payment has not been completed. Please
                                  click on 'Pay Now' to complete the payment
                                  process
                                </p>
                              </div>
                            </div>
                            {/* <button className="viewInviceBut">
                              View Invoice
                            </button> */}
                            {/* <p className="whitespace-nowrap text-lg">
                              Payment Status -{" "}
                              {obj?.paymentPlan === "PAYLATER"
                                ? "Pay Later"
                                : obj?.paymentStatus === "INVOICE_CREATED"
                                ? "Pending"
                                : obj?.paymentStatus?.toLowerCase()}
                            </p> */}
                            <p className="servicePrice">
                              ₹ {obj?.packageDetails?.price}
                            </p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePayment(
                                  studioId,
                                  obj?.shootmatesalbumId,
                                  obj?.packageDetails?.packageCode?.replace(
                                    /'/g,
                                    ""
                                  ),
                                  Number(eventId),
                                  obj?.packageDetails,
                                  obj?.invoiceId
                                );
                              }}
                              className=" payServiceBut   text-white px-[15px] py-[8px] bg-blue-600 rounded-[10px]"
                            >
                              Pay Now
                            </button>

                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 4.875C12.6213 4.875 13.125 4.37132 13.125 3.75C13.125 3.12868 12.6213 2.625 12 2.625C11.3787 2.625 10.875 3.12868 10.875 3.75C10.875 4.37132 11.3787 4.875 12 4.875Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 21.375C12.6213 21.375 13.125 20.8713 13.125 20.25C13.125 19.6287 12.6213 19.125 12 19.125C11.3787 19.125 10.875 19.6287 10.875 20.25C10.875 20.8713 11.3787 21.375 12 21.375Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg> */}
                          </div>
                        ) : (
                          <div className="flex items-center gap-[30px]">
                            {obj?.albumType === "INVITATION" && (
                              <button
                                onClick={(e) => handleCopy(e, obj?.previewUrl)}
                                className="copyLink"
                              >
                                <p>{copied ? "Copied" : "Copy Link"}</p>
                              </button>
                            )}

                            {/* <p className="daysAgo">daysAgo</p> */}
                            {obj?.packageType != "YEARLY"
                              ? obj?.paymentStatus === "PAYMENT_SUCCESS" &&
                                obj?.albumType === "SELECTIONTOOL" && (
                                  <button
                                    className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectionDuplication(true);
                                      setSelectedTool(obj);
                                    }}
                                  >
                                    Duplicate
                                  </button>
                                )
                              : obj?.albumType === "SELECTIONTOOL" && (
                                  <button
                                    className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectionDuplication(true);
                                      setSelectedTool(obj);
                                    }}
                                  >
                                    Duplicate
                                  </button>
                                )}

                            {console.log(obj, "on")}

                            {obj?.packageDetails?.storageInGb && (
                              <div className="w-[171px] rounded-[4px] h-[7px]">
                                <div
                                  className={`w-full rounded-[4px] h-full ${
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize &&
                                    obj?.packageDetails?.storageInGb &&
                                    bytesToGB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    ) > (obj?.packageDetails?.storageInGb || 0)
                                      ? "bg-[#FA6666]"
                                      : "bg-[#FFD09A]"
                                  } `}
                                >
                                  <div
                                    style={{
                                      width: `${
                                        obj?.packageDetails?.storageInGb &&
                                        storageData[obj?.shootmatesalbumId]
                                          ?.totalMediaSize
                                          ? bytesToGB(
                                              storageData[
                                                obj?.shootmatesalbumId
                                              ]?.totalMediaSize
                                            ) > obj?.packageDetails?.storageInGb
                                            ? (
                                                bytesToGB(
                                                  storageData[
                                                    obj?.shootmatesalbumId
                                                  ]?.totalMediaSize
                                                ) -
                                                obj?.packageDetails?.storageInGb
                                              ).toFixed(2)
                                            : (
                                                (storageData[
                                                  obj?.shootmatesalbumId
                                                ]?.totalMediaSize /
                                                  (obj?.packageDetails
                                                    ?.storageInGb *
                                                    1024 ** 3)) *
                                                100
                                              ).toFixed(2)
                                          : 0
                                      }%`,
                                    }}
                                    className={`max-w-[100%] rounded-[4px] h-full ${
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize &&
                                      bytesToGB(
                                        storageData[obj?.shootmatesalbumId]
                                          ?.totalMediaSize
                                      ) >
                                        (obj?.packageDetails?.storageInGb || 0)
                                        ? "bg-[#D42F2F]"
                                        : "bg-[#D8B1FF]"
                                    }`}

                                    // className={` max-w-[100%] rounded-[4px] h-full  bg-[#D8B1FF] `}
                                  ></div>
                                </div>
                              </div>
                            )}
                            {/* {obj?.packageDetails?.storageInGb && ( */}
                            <p className="storageText min-w-[100px] ">
                              {storageData[obj?.shootmatesalbumId]
                                ?.totalMediaSize
                                ? storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize >=
                                  1024 ** 3
                                  ? `${bytesToGB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} GB used`
                                  : `${bytesToMB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} MB used`
                                : "0 MB used"}
                              {obj?.packageType === "FLEXI" &&
                                obj?.packageDetails?.storageInGb &&
                                ` of  ${
                                  obj?.packageDetails?.storageInGb || 0
                                }  GB
                                   `}
                            </p>
                            {/* // )} */}

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.4"
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                fill="#4D58E3"
                              />
                              <path
                                d="M10.7397 16.2802C10.5497 16.2802 10.3597 16.2102 10.2097 16.0602C9.91969 15.7702 9.91969 15.2902 10.2097 15.0002L13.2097 12.0002L10.2097 9.00016C9.91969 8.71016 9.91969 8.23016 10.2097 7.94016C10.4997 7.65016 10.9797 7.65016 11.2697 7.94016L14.7997 11.4702C15.0897 11.7602 15.0897 12.2402 14.7997 12.5302L11.2697 16.0602C11.1197 16.2102 10.9297 16.2802 10.7397 16.2802Z"
                                fill="#4D58E3"
                              />
                            </svg>

                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 4.875C12.6213 4.875 13.125 4.37132 13.125 3.75C13.125 3.12868 12.6213 2.625 12 2.625C11.3787 2.625 10.875 3.12868 10.875 3.75C10.875 4.37132 11.3787 4.875 12 4.875Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 21.375C12.6213 21.375 13.125 20.8713 13.125 20.25C13.125 19.6287 12.6213 19.125 12 19.125C11.3787 19.125 10.875 19.6287 10.875 20.25C10.875 20.8713 11.3787 21.375 12 21.375Z"
                                stroke="#161616"
                                stroke-width="2.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg> */}
                          </div>
                        )
                      ) : (
                        <div className="flex items-center gap-[30px]">
                          {obj?.albumType === "INVITATION" && (
                            <button
                              onClick={(e) => handleCopy(e, obj?.previewUrl)}
                              className="copyLink"
                            >
                              <p>{copied ? "Copied" : "Copy Link"}</p>
                            </button>
                          )}

                          {/* <p className="daysAgo">daysAgo</p> */}
                          {obj?.packageType != "YEARLY"
                            ? obj?.paymentStatus === "PAYMENT_SUCCESS" &&
                              obj?.albumType === "SELECTIONTOOL" && (
                                <button
                                  className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectionDuplication(true);
                                    setSelectedTool(obj);
                                  }}
                                >
                                  Duplicate
                                </button>
                              )
                            : obj?.albumType === "SELECTIONTOOL" && (
                                <button
                                  className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectionDuplication(true);
                                    setSelectedTool(obj);
                                  }}
                                >
                                  Duplicate
                                </button>
                              )}
                          {/* {obj?.packageDetails?.storageInGb && ( */}

                          <div className="w-[171px] rounded-[4px] h-[7px]">
                            <div
                              className={`w-full rounded-[4px] h-full ${
                                storageData[obj?.shootmatesalbumId]
                                  ?.totalMediaSize &&
                                obj?.packageDetails?.storageInGb &&
                                bytesToGB(
                                  storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize
                                ) > (obj?.packageDetails?.storageInGb || 0)
                                  ? "bg-[#FA6666]"
                                  : "bg-[#FFD09A]"
                              } `}
                            >
                              <div
                                style={{
                                  width: `${
                                    obj?.packageDetails?.storageInGb &&
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                      ? bytesToGB(
                                          storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize
                                        ) > obj?.packageDetails?.storageInGb
                                        ? (
                                            bytesToGB(
                                              storageData[
                                                obj?.shootmatesalbumId
                                              ]?.totalMediaSize
                                            ) - obj?.packageDetails?.storageInGb
                                          ).toFixed(2)
                                        : (
                                            (storageData[obj?.shootmatesalbumId]
                                              ?.totalMediaSize /
                                              (obj?.packageDetails
                                                ?.storageInGb *
                                                1024 ** 3)) *
                                            100
                                          ).toFixed(2)
                                      : 0
                                  }%`,
                                }}
                                className={`max-w-[100%] rounded-[4px] h-full ${
                                  storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize &&
                                  bytesToGB(
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                  ) > (obj?.packageDetails?.storageInGb || 0)
                                    ? "bg-[#D42F2F]"
                                    : "bg-[#D8B1FF]"
                                }`}

                                // className={` max-w-[100%] rounded-[4px] h-full  bg-[#D8B1FF] `}
                              ></div>
                            </div>
                          </div>
                          {/* )} */}

                          {/* {obj?.packageDetails?.storageInGb && ( */}
                          <p className="storageText min-w-[100px]">
                            {storageData[obj?.shootmatesalbumId]?.totalMediaSize
                              ? storageData[obj?.shootmatesalbumId]
                                  ?.totalMediaSize >=
                                1024 ** 3
                                ? `${bytesToGB(
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                  )?.toFixed(2)} GB used`
                                : `${bytesToMB(
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                  )?.toFixed(2)} MB used`
                              : "0 MB used"}
                            {
                              // obj?.packageType === "FLEXI" &&
                              obj?.packageDetails?.storageInGb &&
                                ` of  ${obj?.packageDetails?.storageInGb}  GB
                                   `
                            }
                          </p>
                          {/* // )} */}

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.4"
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              fill="#4D58E3"
                            />
                            <path
                              d="M10.7397 16.2802C10.5497 16.2802 10.3597 16.2102 10.2097 16.0602C9.91969 15.7702 9.91969 15.2902 10.2097 15.0002L13.2097 12.0002L10.2097 9.00016C9.91969 8.71016 9.91969 8.23016 10.2097 7.94016C10.4997 7.65016 10.9797 7.65016 11.2697 7.94016L14.7997 11.4702C15.0897 11.7602 15.0897 12.2402 14.7997 12.5302L11.2697 16.0602C11.1197 16.2102 10.9297 16.2802 10.7397 16.2802Z"
                              fill="#4D58E3"
                            />
                          </svg>

                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 4.875C12.6213 4.875 13.125 4.37132 13.125 3.75C13.125 3.12868 12.6213 2.625 12 2.625C11.3787 2.625 10.875 3.12868 10.875 3.75C10.875 4.37132 11.3787 4.875 12 4.875Z"
                              stroke="#161616"
                              stroke-width="2.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                              stroke="#161616"
                              stroke-width="2.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 21.375C12.6213 21.375 13.125 20.8713 13.125 20.25C13.125 19.6287 12.6213 19.125 12 19.125C11.3787 19.125 10.875 19.6287 10.875 20.25C10.875 20.8713 11.3787 21.375 12 21.375Z"
                              stroke="#161616"
                              stroke-width="2.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                        </div>
                      )
                    }
                  </div>
                  {storageData[obj?.shootmatesalbumId]?.totalMediaSize &&
                  obj?.packageDetails?.storageInGb &&
                  bytesToGB(
                    storageData[obj?.shootmatesalbumId]?.totalMediaSize
                  ) > obj?.packageDetails?.storageInGb ? (
                    <div className="exceedStoragePlace justify-end items-end flex pr-[75px] py-[7px]">
                      {" "}
                      <div className="flex items-center gap-[14px]">
                        <div className="flex items-center gap-[6px]">
                          <p className="extraSpace"> Extra Space used</p>
                          <button className="totalStorageBut">
                            {(
                              bytesToGB(
                                storageData[obj?.shootmatesalbumId]
                                  ?.totalMediaSize
                              ) - obj?.packageDetails?.storageInGb
                            ).toFixed(2)}{" "}
                            GB
                          </button>
                        </div>
                        {/* <div className="flex items-center gap-[6px]">
                          <p className="extraSpace">
                            {" "}
                            Extra Charges Applied amount of 399
                          </p>
                          <button className="payDueStorageBut">PAY DUE</button>
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className="mt-24 relative">
              <NoData
                top={"22%"}
                left={"-3%"}
                text={
                  "Click create-service button to create the required services (Photo Selection, Signature Album,Online Invitation,Ai Photo Delivery,Photo Share Tool )"
                }
              />
            </div>
          )}
          {/* 
          <div className="px-5 py-4 ">
            {!loading && leadDetails?.albums?.length > 0 ? (
              <table className="w-full text-left bg-white shadow-md rounded-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-3">Service</th>
                    
                    <th className="p-3">Storage</th>
                   
                    <th className="p-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {leadDetails?.albums?.map((obj, i) => (
                    <tr
                      key={i}
                      className={`cursor-pointer hover:bg-blue-100 border-b-[1px] border-[#787878] border-opacity-40 transition-colors ${
                        c
                      }`}
                   
                      onClick={() =>
                        obj?.packageType === "FLEXI"
                          ? obj?.paymentPlan === "ONETIME" ||
                            obj?.paymentPlan === ""
                            ? obj?.paymentStatus !== "PAYMENT_SUCCESS"
                              ? ""
                              : navigateToAlbum(eventId, obj)
                            : navigateToAlbum(eventId, obj)
                          : navigateToAlbum(eventId, obj)
                      }
                    >
                 
                      <td className="p-3 flex  items-center gap-4">
                        <div
                          className={`rounded-lg p-3 ${
                            obj?.albumType === "FACEDETECTION"
                              ? "bg-yellow-100"
                              : obj?.albumType === "SELECTIONTOOL" ||
                                obj?.albumType === "INVITATION" ||
                                obj?.albumType === "PHOTOSHARETOOL"
                              ? "bg-blue-100"
                              : "bg-red-100"
                          }`}
                        >
                          <img
                            src={
                              obj?.albumType === "FACEDETECTION"
                                ? eventFaceDetection
                                : obj?.albumType === "SELECTIONTOOL"
                                ? eventInvitation
                                : obj?.albumType === "INVITATION"
                                ? invitation
                                : obj?.albumType === "PHOTOSHARETOOL"
                                ? photoShare
                                : eventSignatureAlbum
                            }
                            alt={obj?.albumType}
                            className="w-7 h-7"
                          />
                        </div>
                        <div>
                          <p className="font-medium text-lg text-gray-900">
                            {obj?.albumName}
                          </p>
                          <p className=" text-md">
                            {getAlbumLinkText(obj?.albumType)}
                          </p>
                        </div>
                      </td>

            

                      <td className="p-3">
                        {obj?.packageType === "FLEXI" && storageLoading ? (
                          <p className="text-[16px] font-[400]">Loading...</p>
                        ) : (
                          <div className="w-[300px]">
                            <div className="w-full bg-gray-300 rounded h-[15px]">
                              <div
                                className="bg-blue-600 h-full rounded-full"
                                style={{
                                  width: `${
                                    obj?.packageDetails?.storageInGb &&
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                      ? (
                                          (storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize /
                                            (obj?.packageDetails?.storageInGb *
                                              1024 ** 3)) *
                                          100
                                        )?.toFixed(2)
                                      : 0
                                  }%`,
                                }}
                              ></div>
                            </div>

                            <p className="mt-2 text-lg">
                              {storageData[obj?.shootmatesalbumId]
                                ?.totalMediaSize
                                ? storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize >=
                                  1024 ** 3
                                  ? `${bytesToGB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} GB used`
                                  : `${bytesToMB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} MB used`
                                : "0 MB used"}
                              {obj?.packageType === "FLEXI" &&
                                ` of  ${
                                  obj?.packageDetails?.storageInGb || 0
                                }  GB
                           `}
                            </p>

                          
                          </div>
                        )}
                      </td>

                  
                      <td className=" p-3">
                        {obj?.packageType != "YEARLY"
                          ? obj?.paymentStatus === "PAYMENT_SUCCESS" &&
                            obj?.albumType === "SELECTIONTOOL" && (
                              <button
                                className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectionDuplication(true);
                                  setSelectedTool(obj);
                                }}
                              >
                                Duplicate
                              </button>
                            )
                          : obj?.albumType === "SELECTIONTOOL" && (
                              <button
                                className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectionDuplication(true);
                                  setSelectedTool(obj);
                                }}
                              >
                                Duplicate
                              </button>
                            )}
                        {obj?.packageType != "YEARLY"
                          ? obj?.packageDetails?.packageCode &&
                            obj?.packageType === "FLEXI" &&
                            obj?.paymentStatus !== "PAYMENT_SUCCESS" && (
                              <div className="justify-end items-end flex flex-col">
                                <p className="whitespace-nowrap text-lg">
                                  Payment Status -{" "}
                                  {obj?.paymentStatus === "INVOICE_CREATED"
                                    ? "Pending"
                                    : obj?.paymentStatus?.toLowerCase()}
                                </p>

                                <p className="whitespace-nowrap text-lg">
                                  Payment Plan -
                                  {obj?.paymentPlan?.toLowerCase()}
                                </p>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePayment(
                                      studioId,
                                      obj?.shootmatesalbumId,
                                      obj?.packageDetails?.packageCode?.replace(
                                        /'/g,
                                        ""
                                      ),
                                      Number(eventId),
                                      obj?.packageDetails,
                                      obj?.invoiceId
                                    );
                                  }}
                                  className=" payNowBut  mt-2 text-white px-[15px] py-[8px] bg-blue-600 rounded-[10px]"
                                >
                                  Pay Now
                                </button>
                              </div>
                            )
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="mt-24 relative">
                <NoData
                  top={"22%"}
                  left={"-3%"}
                  text={
                    "No Services Created ,  create  any service to continue"
                  }
                />
              </div>
            )}
          </div> */}
        </>
      )}
    </div>
  );
}

export default EventDetailView;
