import React from "react";

function AdvanceSettingsSel({
  setsessionForm,
  handleUserListingButton,
  handleDownload,
  downloadLinkReady,
  setIsOpenGallery,
  handleAlbumMetaDataButton,
  handleChangeSelectionStatus,
  setAddWatermarkPopup,
  copyToClipboard,
  setaddPinPopup,
  copy,
  userIcon,
  loading,
  setOpenAdvSettings,
}) {
  return (
    <div className="fixed bg-gray-900 bg-opacity-75 z-[100]  top-0 right-0 bottom-0 left-0 justify-center items-center flex mx-auto   ">
      <div className="bg-[#fff]  pop  rounded-[10px] flex flex-col p-[15px]    ">
        <div className="flex items-end justify-end">
          {/* <p className="text-[18px]">Advance Settings</p>*/}
          <button onClick={() => setOpenAdvSettings(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className=" flex text-[16px] flex-col justify-start items-start">
          <div className="flex flex-col items-start ">
            {/* <div
              onClick={() => {
                setsessionForm(true);
              }}
              className="flex cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
               <img src={options} alt="" className="w-[16px] h-[16px]" /> 
              <p className="font_normal_11 text-[#161616]">Create folder</p>
            </div> */}

            <div
              onClick={handleAlbumMetaDataButton}
              className="hover:border-opacity-100 flex cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" text-[#161616]">Settings</p>
            </div>
            <div
              onClick={() => {
                setaddPinPopup(true);
              }}
              className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={setPinIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" text-[#161616]">Set / View Pin</p>
            </div>
            <div
              onClick={() => {
                setAddWatermarkPopup(true);
              }}
              className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={options} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" text-[#161616]">Add Watermark</p>
            </div>

            <div
              onClick={handleUserListingButton}
              className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" text-[#161616]">View / Manage Crew</p>
            </div>

            <div
              onClick={() => {
                setIsOpenGallery(true);
              }}
              className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" text-[#161616]">View Selected Images</p>
            </div>

            {/* <div
              onClick={() => copyToClipboard("live")}
               className="flex cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
               <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" /> 
              <p className="font_normal_11 text-[#F6F6F6]">
                {copy ? "Selection Link Copied!" : "Selection Link "}
              </p>
            </div> */}
            {downloadLinkReady ? (
              <div className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full">
                {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
                <p className=" text-[#161616]">Preparing Excel</p>
              </div>
            ) : (
              <div
                className="flex hover:border-opacity-100 cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
                onClick={() => {
                  handleDownload("excel");
                }}
              >
                {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
                <p className=" text-[#161616]">
                  Download Excel Of Selected Images
                </p>
              </div>
            )}

            {/* {loading ? (
              <div className="flex cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full">
               <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" /> 
                <p className="font_normal_11 text-[#161616]">Loading..</p>
              </div>
            ) : (
              <div
                onClick={handleChangeSelectionStatus}
                className="flex cursor-pointer gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" /> 
                <p className="font_normal_11 text-[#161616]">
                  Change Selection Status
                </p>
              </div>
            )} */}
          </div>
          <div className="flex flex-col  gap-[16px]">
            {/* <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvanceSettingsSel;
