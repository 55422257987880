import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import Slider from "../Slider/Slider";
import Loading from "../../Assets/Animation/Loading";
import "./LiveUsersPopup.css";
import ResultShowPopup from "./ResultShowPopup";

function LiveUsersPopup({
  studioId,
  memshotAlbumId,
  setViewLiveUsersPopup,
  projectId,
}) {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [refreshing, setRefreshing] = useState(true);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [shareLinkLoading, setShareLinkLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentResultIndex, setCurrentResultIndex] = useState(-1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const [copied, setCopied] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    fetchRegisteredUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  const updatedLiveRequests = (requestId, shareLink) => {
    const updatedregisteredUsers =
      registeredUsers &&
      registeredUsers?.liveRequests &&
      registeredUsers?.liveRequests.length >= 0 &&
      registeredUsers?.liveRequests.map((user) => {
        if (user?.requestId === requestId) {
          return {
            ...user,
            shareLinkedCreated: true,
            shareLink: shareLink,
          };
        }
        return user;
      });

    const updatedFilteredUsers =
      filteredUsers &&
      filteredUsers &&
      filteredUsers?.length >= 0 &&
      filteredUsers?.map((user) => {
        if (user?.requestId === requestId) {
          return {
            ...user,
            shareLinkedCreated: true,
            shareLink: shareLink,
          };
        }
        return user;
      });
    setFilteredUsers(updatedFilteredUsers);
    setRegisteredUsers({
      ...registeredUsers,
      liveRequests: updatedregisteredUsers,
    });
  };

  const fetchRegisteredUsers = async () => {
    try {
      await verifyUser();
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/livefaces/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRegisteredUsers(response?.data?.data);
      setFilteredUsers(response?.data?.data?.liveRequests || []);
      setRefreshing(false);
    } catch (error) {
      console.error("Error fetching registered users:", error);
      setRefreshing(false);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.length >= 3) {
      const filtered = registeredUsers?.liveRequests?.filter((user) =>
        user.name.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(registeredUsers?.liveRequests);
    }
  };

  const convertMongoDBTimestampToIST = (mongodbTimestamp) => {
    const utcTime = new Date(mongodbTimestamp);
    const istTime = utcTime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    return istTime;
  };

  const handleViewResults = (user) => {
    setSelectedUser(user);
    // setCurrentResultIndex(0);
    setShowResultsPopup(true);
  };

  const handlePreviousResult = () => {
    setCurrentResultIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser?.results?.length - 1
    );
  };

  const handleNextResult = () => {
    setCurrentResultIndex((prevIndex) =>
      prevIndex < selectedUser?.results?.length - 1 ? prevIndex + 1 : 0
    );
  };

  const getThumbnail = (url) => {
    const replacements = ["aimemshots", "shootmatesprivatecloud", "albumdata"];
    const targetReplacement = "albumthumbnails";
    const urlParts = url.split("/");
    const updatedParts = urlParts.map((part) =>
      replacements.includes(part) ? targetReplacement : part
    );
    const lastPart = updatedParts.at(-1);
    const modifiedFilename = lastPart.includes(".")
      ? `d_${lastPart}`
      : `d_${lastPart}`;
    updatedParts[updatedParts.length - 1] = modifiedFilename;
    return updatedParts.join("/");
  };

  const handleCreateShareLink = async (requestId) => {
    setShareLinkLoading(true);
    try {
      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/generatesharelink/${projectId}/${studioId}/${memshotAlbumId}/${requestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);
      updatedLiveRequests(requestId, response.data?.shareLink);
    } catch (error) {
      console.error("Error creating share link:", error);
    } finally {
      setShareLinkLoading(false);
    }
  };
  // const handleViewFaces = (user) => {
  //   setSelectedUser(user);
  //   setCurrentImageIndex(0);
  // };

  // const handlePreviousImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
  //   );
  // };

  // const handleNextImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
  //   );
  // };

  // const handleCloseSlider = () => {
  //   setSelectedUser(null);
  // };
  const copyImageUrl = (index) => {
    const imageUrl = `https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${selectedUser?.faceUrl[index]}`;
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy image URL: ", err);
      });
  };

  const handleCopyLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        //setCopied(true);
        // setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="bg-white mt-[2%] max-w-6xl w-full h-[600px] overflow-y-auto rounded-md shadow-md">
      {/* {selectedUser && (
        <Slider
          memshotAlbumId={memshotAlbumId}
          images={selectedUser?.faceUrl}
          currentIndex={currentImageIndex}
          onPrev={handlePreviousImage}
          onNext={handleNextImage}
          onClose={handleCloseSlider}
        />
      )} */}
      <div className="flex justify-between items-center p-6 sticky top-0 bg-white">
        <p className="text-xl font-semibold text-blue-500">Live Users</p>
        <div className="flex items-center gap-[20px]">
          <span className="text-sm text-gray-500 mr-2">{`Total Live Users: ${
            refreshing ? "Refreshing..." : registeredUsers?.reqCounter || 0
          }`}</span>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by name (min 3 letters)"
            className="border rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
          <button
            onClick={() => setRefreshing(!refreshing)}
            className={` whitespace-nowrap flex justify-center items-center setPrice ${
              refreshing && "bg-blue-300 cursor-not-allowed"
            }`}
            disabled={refreshing}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 4V9H15"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>{refreshing ? "Refreshing..." : "Refresh"}</p>
          </button>

          <button onClick={() => setViewLiveUsersPopup(false)} className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>

      {refreshing ? (
        <div className="flex justify-center items-center h-full">
          <Loading />
        </div>
      ) : (
        <div className="text-[#161616] p-[10px]">
          <table className="w-full ">
            <thead className="border-[1px] border-[#111] border-opacity-20">
              <tr className="text-[12px] font-[500] ">
                <th className="py-3 px-4 whitespace-nowrap text-left text-sm font-medium">
                  Name
                </th>
                {/* <th className="py-3 px-4 text-left text-sm font-medium">
                  Live Faces
                </th> */}
                <th className="py-3 px-4 whitespace-nowrap text-left text-sm font-medium">
                  Total Results
                </th>
                <th className="py-3 px-4 whitespace-nowrap text-left text-sm font-medium">
                  Results
                </th>
                <th className="py-3 px-4 whitespace-nowrap text-left text-sm font-medium">
                  Search Time
                </th>
                <th className="py-3 px-4 whitespace-nowrap text-left text-sm font-medium">
                  Share Link
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                  } hover:bg-gray-300 transition-all duration-300`}
                >
                  <td className="py-3 px-4 text-sm font-medium text-gray-700">
                    {user?.name}
                  </td>
                  {/* <td className="py-3 px-4">
                    {user?.faceUrl?.length > 0 ? (
                      <button
                        onClick={() => handleViewFaces(user)}
                        className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-full hover:bg-green-600 transition-all duration-300"
                      >
                        Show Live Faces
                      </button>
                    ) : (
                      <button className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300">
                        No Faces
                      </button>
                    )}
                  </td> */}
                  <td className="py-3 px-4 text-sm font-medium text-gray-700">
                    {user?.results?.length}
                  </td>
                  <td className="py-3 px-4">
                    <button
                      onClick={() => handleViewResults(user)}
                      className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded-full hover:bg-blue-600 transition-all duration-300"
                    >
                      View Results
                    </button>
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-600">
                    {convertMongoDBTimestampToIST(user?.registeredAt)}
                  </td>
                  {user?.results?.length > 0 ? (
                    <td className="py-3 px-4 text-sm text-green-600">
                      {user?.shareLinkedCreated === true ? (
                        <button
                          onClick={() => {
                            handleCopyLink(user?.shareLink);
                          }}
                          className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300"
                        >
                          Copy Share Link
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            handleCreateShareLink(user?.requestId);
                          }}
                          className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-full hover:bg-green-600 transition-all duration-300"
                        >
                          {shareLinkLoading === true
                            ? "Generating Share Link"
                            : "Create Share Link"}
                        </button>
                      )}
                    </td>
                  ) : (
                    <button className="px-3 py-1 text-sm font-medium text-black  rounded-full  transition-all duration-300">
                      No Results
                    </button>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showResultsPopup && selectedUser && (
        // <ResultShowPopup />
        <div className="fixed  inset-0 py-[2%] text-[#111] bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white min-w-[85%] max-w-[95%] h-[600px] overflow-y-auto rounded-lg shadow-2xl  relative">
            <div className="border-b-[1px] border-b-[#111] border-opacity-20 flex justify-between items-start p-4">
              <div className="flex gap-[12px]">
                <button className="iconWatermark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1831_1793)">
                      <path
                        d="M13.0007 6C13.0007 5.47471 13.1042 4.95457 13.3052 4.46927C13.5062 3.98396 13.8008 3.54301 14.1723 3.17157C14.5437 2.80014 14.9847 2.5055 15.47 2.30448C15.9553 2.10346 16.4754 2 17.0007 2C17.526 2 18.0461 2.10346 18.5314 2.30448C19.0167 2.5055 19.4577 2.80014 19.8291 3.17157C20.2006 3.54301 20.4952 3.98396 20.6962 4.46927C20.8972 4.95457 21.0007 5.47471 21.0007 6C21.0007 7.06087 20.5793 8.07828 19.8291 8.82843C19.079 9.57857 18.0616 10 17.0007 10C15.9398 10 14.9224 9.57857 14.1723 8.82843C13.4221 8.07828 13.0007 7.06087 13.0007 6ZM8.99669 8.144L13.4897 15.132L15.9257 11.233L24.0007 22H0.179688L8.99669 8.144Z"
                        fill="#373CBD"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1831_1793">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="flex flex-col">
                  <p className="text-[18px] font-[600]">
                    Results - {selectedUser.name}
                  </p>
                  <p className="text-[14px] font-[400]">
                    Total Images - {selectedUser.results?.length}
                  </p>
                </div>
              </div>

              {/* <h2 className="text-lg font-bold">
                Results for {selectedUser.name}
                <p className="text-sm">
                  Total Results - {selectedUser.results?.length}
                </p>
              </h2> */}
              <button onClick={() => setShowResultsPopup(false)} className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="flex px-[50px] ">
              <div className="w-[234px] justify-center  flex  border-r-[1px] border-r-[#111] border-opacity-20 pr-[58px]">
                <div className="flex flex-col py-[40px] items-center">
                  <div className="w-[200px] h-[200px] rounded-full flex-shrink-0">
                    <img
                      src={`https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${encodeURIComponent(
                        selectedUser?.faceUrl[currentImageIndex]
                      )}`}
                      alt={`face ${currentImageIndex + 1}`}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <button
                    onClick={() => copyImageUrl(currentImageIndex)}
                    className="setPrice whitespace-nowrap mt-[20px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.2428 10.3578L11.3002 9.41381L12.2428 8.47115C12.8634 7.84509 13.2108 6.99869 13.2088 6.11717C13.2069 5.23565 12.8559 4.39078 12.2325 3.76745C11.6092 3.14411 10.7643 2.79307 9.88281 2.79114C9.00129 2.78921 8.15489 3.13655 7.52884 3.75715L6.5855 4.70048L5.64284 3.75781L6.5855 2.81515C7.46072 1.93993 8.64776 1.44824 9.8855 1.44824C11.1232 1.44824 12.3103 1.93993 13.1855 2.81515C14.0607 3.69036 14.5524 4.87741 14.5524 6.11515C14.5524 7.35289 14.0607 8.53993 13.1855 9.41515L12.2428 10.3578ZM10.3568 12.2431L9.41417 13.1858C8.53896 14.061 7.35191 14.5527 6.11417 14.5527C4.87643 14.5527 3.68938 14.061 2.81417 13.1858C1.93896 12.3106 1.44727 11.1236 1.44727 9.88581C1.44727 8.64807 1.93896 7.46103 2.81417 6.58581L3.7575 5.64315L4.70017 6.58715L3.7575 7.52981C3.14542 8.15752 2.80528 9.00111 2.81077 9.87783C2.81627 10.7546 3.16695 11.5938 3.78685 12.2138C4.40676 12.8338 5.24597 13.1846 6.12269 13.1902C6.99941 13.1958 7.84304 12.8558 8.47084 12.2438L9.41417 11.3011L10.3568 12.2431ZM9.8855 5.17181L10.8288 6.11515L6.11417 10.8285L5.1715 9.88581L9.8855 5.17181Z"
                        fill="#161616"
                      />
                    </svg>
                    {copied ? "Copied" : "Copy Link"}
                  </button>
                </div>
              </div>
              {currentResultIndex === -1 ? (
                <div className="grid py-[30px]  grid-cols-4  px-[40px] gap-[40px]">
                  {selectedUser?.results?.length > 0 &&
                    selectedUser?.results?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => setCurrentResultIndex(index)}
                        className="h-[250px] w-[210px] cursor-pointer"
                      >
                        <img
                          className="w-full h-full object-cover"
                          src={getThumbnail(item)}
                          alt=""
                        />
                      </div>
                    ))}
                </div>
              ) : (
                selectedUser?.results?.length > 0 &&
                currentResultIndex !== -1 && (
                  <div className="w-full py-[30px] relative">
                    <button
                      onClick={() => setCurrentResultIndex(-1)}
                      className="absolute top-6 right-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#667085"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <div className="flex px-[20px] gap-[60px] justify-between items-center ">
                      <button
                        onClick={handlePreviousResult}
                        className="prevNextLive"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1831_1754)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.29279 12.7073C8.10532 12.5198 8 12.2655 8 12.0003C8 11.7352 8.10532 11.4809 8.29279 11.2933L13.9498 5.63634C14.042 5.54083 14.1524 5.46465 14.2744 5.41224C14.3964 5.35983 14.5276 5.33225 14.6604 5.33109C14.7932 5.32994 14.9248 5.35524 15.0477 5.40552C15.1706 5.4558 15.2823 5.53006 15.3762 5.62395C15.4701 5.71784 15.5443 5.82949 15.5946 5.95239C15.6449 6.07529 15.6702 6.20696 15.669 6.33974C15.6679 6.47252 15.6403 6.60374 15.5879 6.72575C15.5355 6.84775 15.4593 6.9581 15.3638 7.05034L10.4138 12.0003L15.3638 16.9503C15.5459 17.1389 15.6467 17.3915 15.6445 17.6537C15.6422 17.9159 15.537 18.1668 15.3516 18.3522C15.1662 18.5376 14.9154 18.6427 14.6532 18.645C14.391 18.6473 14.1384 18.5465 13.9498 18.3643L8.29279 12.7073Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1831_1754">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <img
                        src={selectedUser?.results?.[currentResultIndex]}
                        alt="Result"
                        className="max-h-[500px] max-w-full object-contain flex-shrink-0  "
                      />

                      <button
                        onClick={handleNextResult}
                        className="prevNextLive"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1831_1776)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.7072 12.7073C15.8947 12.5198 16 12.2655 16 12.0003C16 11.7352 15.8947 11.4809 15.7072 11.2933L10.0502 5.63634C9.95797 5.54083 9.84762 5.46465 9.72562 5.41224C9.60361 5.35983 9.47239 5.33225 9.33961 5.33109C9.20684 5.32994 9.07516 5.35524 8.95226 5.40552C8.82936 5.4558 8.71771 5.53006 8.62382 5.62395C8.52993 5.71784 8.45567 5.82949 8.40539 5.95239C8.35511 6.07529 8.32981 6.20696 8.33096 6.33974C8.33212 6.47252 8.3597 6.60374 8.41211 6.72575C8.46452 6.84775 8.5407 6.9581 8.63621 7.05034L13.5862 12.0003L8.63621 16.9503C8.45406 17.1389 8.35326 17.3915 8.35554 17.6537C8.35782 17.9159 8.46299 18.1668 8.64839 18.3522C8.8338 18.5376 9.08462 18.6427 9.34681 18.645C9.60901 18.6473 9.86161 18.5465 10.0502 18.3643L15.7072 12.7073Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1831_1776">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="matrix(-1 0 0 1 24 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                    <div className="justify-center items-center mt-[20px] flex">
                      <button className="imageLen">
                        <svg
                          onClick={handlePreviousResult}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1831_1764)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.5282 8.47124C5.40322 8.34622 5.33301 8.17668 5.33301 7.9999C5.33301 7.82313 5.40322 7.65359 5.5282 7.52857L9.29953 3.75724C9.36103 3.69356 9.43459 3.64277 9.51593 3.60784C9.59726 3.5729 9.68474 3.55451 9.77326 3.55374C9.86178 3.55297 9.94957 3.56983 10.0315 3.60336C10.1134 3.63688 10.1879 3.68638 10.2505 3.74897C10.3131 3.81157 10.3626 3.886 10.3961 3.96793C10.4296 4.04986 10.4465 4.13765 10.4457 4.22617C10.4449 4.31469 10.4265 4.40217 10.3916 4.48351C10.3567 4.56484 10.3059 4.63841 10.2422 4.6999L6.9422 7.9999L10.2422 11.2999C10.3636 11.4256 10.4308 11.594 10.4293 11.7688C10.4278 11.9436 10.3577 12.1108 10.2341 12.2344C10.1105 12.3581 9.94326 12.4282 9.76847 12.4297C9.59367 12.4312 9.42527 12.364 9.29953 12.2426L5.5282 8.47124Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1831_1764">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {currentResultIndex + 1}/{selectedUser?.results?.length}
                        <svg
                          onClick={handleNextResult}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1831_1769)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.4718 8.47124C10.5968 8.34622 10.667 8.17668 10.667 7.9999C10.667 7.82313 10.5968 7.65359 10.4718 7.52857L6.70047 3.75724C6.63897 3.69356 6.56541 3.64277 6.48407 3.60784C6.40274 3.5729 6.31526 3.55451 6.22674 3.55374C6.13822 3.55297 6.05043 3.56983 5.9685 3.60336C5.88657 3.63688 5.81213 3.68638 5.74954 3.74897C5.68694 3.81157 5.63744 3.886 5.60392 3.96793C5.5704 4.04986 5.55353 4.13765 5.5543 4.22617C5.55507 4.31469 5.57346 4.40217 5.6084 4.48351C5.64334 4.56484 5.69413 4.63841 5.7578 4.6999L9.0578 7.9999L5.7578 11.2999C5.63636 11.4256 5.56917 11.594 5.57069 11.7688C5.5722 11.9436 5.64232 12.1108 5.76592 12.2344C5.88953 12.3581 6.05674 12.4282 6.23153 12.4297C6.40633 12.4312 6.57473 12.364 6.70047 12.2426L10.4718 8.47124Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1831_1769">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="matrix(-1 0 0 1 16 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveUsersPopup;
