import React, { useContext, useEffect, useRef, useState } from "react";
import "./TrainingSession.css";
import TrainingFolder from "../../Components/Forms/TrainingSessionForm/TrainingFolder";
import Lottie from "../../Assets/Animation/Loading";
import { useParams, useLocation, useNavigate } from "react-router-dom";
//import fileUploadIcon from "../../Assets/fileUploadIcon.svg";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
//import DesignPoster from "../../Components/DesignPoster/DesignPoster";
import userIcon from "../../Assets/userIcon.svg";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
//import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import options from "../../Assets/NavbarIcons/options.svg";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import MetaDataPopup from "../../Components/MetaDataPopup/MetaDataPopup";
import RegisteredUsersPopup from "../../Components/RegisteredUsersPopup/RegisteredUserspopup";
import PaidPhotosHistory from "../../Components/PaidPhotosHistory/PaidPhotosHistory";
import LiveUsersPopup from "../../Components/LiveUsersPopup/LiveUsersPopup";
import axios from "axios";
import CrewUsers from "../../Components/PopUps/CrewUsers";
//import albumThumb from "../../Assets/albumThumn.png";
//import refresshIcon from "../../Assets/refresshIcon.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";
import eventFaceDetection from "../../Assets/eventFaceDetection.svg";
import ConfirmationPopup from "../../Components/PopUps/Confirmationpopup/ConfirmationPopup";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Watermark from "../../Components/Watermark/Watermark";
import QrCodeGenerator from "../../Components/QrCodeGenerator/QrCodeGenerator";
//import AddPinSelectionTool from "../../Components/Forms/AddPinSelectionTool/AddPinSelectionTool";
import AdvanceSettings from "../../Components/advanceSettings/AdvanceSettings";
import AlertAIPopup from "../../Components/PopUps/AlertAIPopup/AlertAIPopup";
//import UserListPopup from "../../Components/PopUps/UserListPopup";

function TrainingSession() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [toggleNotifyState, setNotifyToggleState] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [aiPopupMessage, setAiPopupMessage] = useState(null);
  const [showNotifyPopup, setNotifyShowPopup] = useState(false);
  const [showNotifyPopupLoading, setNotifyShowPopupLoading] = useState(false);
  const [viewRegisteredUsersPopup, setViewRegisteredUsersPopup] =
    useState(false);
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const [viewLiveUsersPopup, setViewLiveUsersPopup] = useState(false);
  // const [designPoster, setDesignPoster] = useState(false);
  const [viewQrPopup, setViewQrPopup] = useState(false);
  const [paidPhotosSection, setPaidPhotosSection] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [addWatermarkPopup, setAddWatermarkPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [sessionForm, setsessionForm] = useState(false);
  //
  // const [message, setMMessage] = useState("");

  const [update, setUpdate] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({ show: false });
  const [loading, setLoading] = useState(true);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();

  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const [albmTrainingDetails, setalbmTrainingDetails] = useState({});
  const [albumDetails, setAlbumDetails] = useState({});
  const [openAdvSettings, setOpenAdvSettings] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId } = useContext(dataContext);

  const Ref = useRef(null);

  useEffect(() => {
    Ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getTrainingSessionData(), getAlbumDetails()]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("scroll");
  }, []);

  const getTrainingSessionData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_TRAINING_SESSION_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.data?.googleDriveLink) {
          setFormData({
            ...formData,
            gdriveUrl: response?.data?.data?.googleDriveLink,
          });
          //setGdriveUrl(response?.data?.data?.googleDriveLink)
        } else {
          setFormData({ ...formData, gdriveUrl: "" });
        }
        setalbmTrainingSession(response?.data?.data?.sections);
        setalbmTrainingDetails(response?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        setNotifyToggleState(
          response?.data?.data?.msgSend === true ? true : false
        );
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  //publishToFaceDetectionTraining
  const publishToFaceDetectionTraining = async (
    totalImages,
    processedImages,
    folderId,
    folderName
  ) => {
    verifyUser();
    setLoading(true);
    try {
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/publishfacesession/${projectId}/${studioId}/${shootmatesAlbumId}?privatePhotos=${
          albumDetails?.paidPhotos === true ? true : false
        }`,

        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/aiservices/publishfacesession/${projectId}/${studioId}/${shootmatesAlbumId}?privatePhotos=${
        //   albumDetails?.paidPhotos === true ? true : false
        // }`,
        data: {
          folderId: folderId,
          folderName: folderName,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setLoading(false);
        } else {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setUpdate(!update);
      setShowConfirmation({ show: false });
      setLoading(false);
    }
  };

  const handlecreatefolder = () => {
    setsessionForm(true);
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleRegisteredDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewRegisteredUsersPopup(true);
  };

  const handleLiveDataButton = () => {
    setViewLiveUsersPopup(true);
  };

  const calculateTimeDifference = (
    compressionEndTime,
    compressionStartTime,
    key,
    status
  ) => {
    let time = null;
    if (!compressionStartTime || !compressionEndTime) {
      if (key === "compress") {
        return (time = ``);
      } else {
        return (time = ``);
      }
    }
    if (compressionStartTime === compressionEndTime) {
      if (status === "COMPLETED") {
        return (time = `0 hours 0 minutes 0 seconds`);
      } else {
        return (time = ``);
      }
    }
    const timeDifferenceInMilliseconds =
      new Date(compressionEndTime) - new Date(compressionStartTime);
    const millisecondsInSecond = 1000;
    const millisecondsInMinute = 60 * millisecondsInSecond;
    const millisecondsInHour = 60 * millisecondsInMinute;
    const hours = Math.floor(timeDifferenceInMilliseconds / millisecondsInHour);
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (timeDifferenceInMilliseconds % millisecondsInMinute) /
        millisecondsInSecond
    );
    time = `${hours}h ${minutes}m ${seconds}s`;
    return time;
  };

  const handleConfirmation = async (showConfirmation) => {
    const folderId = showConfirmation?.folderId;
    const totalImages = showConfirmation?.totalImages;
    const processedImages = showConfirmation?.processedImages;
    const folderName = showConfirmation?.folderName;
    await publishToFaceDetectionTraining(
      totalImages,
      processedImages,
      folderId,
      folderName
    );
  };

  const handleSubmit = async () => {
    try {
      verifyUser();
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let url =
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdashboardai/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

      // selectedUploadType === "GOOGLE_DRIVE"
      //   ? `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/creategdrivefolderdashboardai/${projectId}/${studioId}/${shootmatesAlbumId}`
      //   : `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

      let config = {
        method: "post",
        url: url,
        data: {
          ...formData,
          title: formData?.folderName,
          coverImage: "ai",
          description: "ai",
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setsessionForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setUpdate(!update);
    }
  };

  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboardainew/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          folderId: folderId,
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const openuserWindow = () => {
    const origin = window.location.origin;
    const url = `${origin}/Event/${projectId}/photosharetool/${shootmatesAlbumId}/userselection`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (
      addUserPopup ||
      viewQrPopup ||
      addWatermarkPopup ||
      viewUserListPopup ||
      metaDataPopup ||
      viewRegisteredUsersPopup ||
      paidPhotosSection ||
      viewLiveUsersPopup ||
      sessionForm ||
      showConfirmation?.show
    ) {
      setOpenAdvSettings(false);
    }
  }, [
    addUserPopup,
    viewQrPopup,
    addWatermarkPopup,
    viewUserListPopup,
    metaDataPopup,
    viewRegisteredUsersPopup,
    paidPhotosSection,
    viewLiveUsersPopup,
    sessionForm,
    showConfirmation?.show,
  ]);

  const handleNotificationToggleClick = () => {
    setNotifyShowPopup(true);
  };

  const changeNotificationSend = async () => {
    try {
      setNotifyShowPopupLoading(true);
      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/notifychanger/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setNotifyToggleState(!toggleNotifyState);
        setNotifyShowPopupLoading(false);
        setNotifyShowPopup(false);
      } else {
        console.log("response", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendMessages = async () => {
    try {
      setLoading(true);
      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/notifysender/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      console.log("response", response?.data?.msgTrigger);

      if (response.status === 200) {
        if (response?.data?.msgTrigger === true) {
          setSendMessage(true);
          setAiPopupMessage(
            "Please try after sometime..Messages are currently sending.."
          );
        } else {
          setSendMessage(true);
          setAiPopupMessage("Messages will be sended soon..");
        }
        setLoading(false);
      } else {
        setLoading(false);
        setSendMessage(true);
        setAiPopupMessage("Failed..Please try after sometime..");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div ref={Ref} className="faceTrainingDetailContainer min-h-[100vh]">
      {showNotifyPopup && (
        <div className="fixed top-0 z-[100] left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 w-[400px] text-center rounded-lg shadow-lg">
            <p className="text-[20px]">
              Are you sure you want to change the notification send ?
            </p>
            <div className="flex justify-center items-center gap-[24px] mt-4">
              <button
                className="border-[1px] rounded-[10px] border-opacity-20 px-[15px] text-[15px] py-[10px] font_semibold_12"
                onClick={() => setNotifyShowPopup(false)}
              >
                Cancel
              </button>
              <button onClick={changeNotificationSend} className="eventButton">
                {showNotifyPopupLoading ? "Please Wait.." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          {" "}
          <Lottie />
        </>
      ) : (
        <div>
          <AlertAIPopup
            visible={sendMessage}
            onClose={setSendMessage}
            aiPopupMessage={aiPopupMessage}
          />
          {/* {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )} */}
          {openAdvSettings && (
            <AdvanceSettings
              openAdvSttings={openAdvSettings}
              setOpenAdvSettings={setOpenAdvSettings}
              handleLiveDataButton={handleLiveDataButton}
              setPaidPhotosSection={setPaidPhotosSection}
              setAddWatermarkPopup={setAddWatermarkPopup}
              handleAlbumMetaDataButton={handleAlbumMetaDataButton}
              handleRegisteredDataButton={handleRegisteredDataButton}
              handlecreatefolder={handlecreatefolder}
              handleUserListingButton={handleUserListingButton}
              detailsIcon={detailsIcon}
              options={options}
              userIcon={userIcon}
            />
          )}

          {addUserPopup && (
            <AddUser
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {viewQrPopup && (
            <QrCodeGenerator
              slugId={albumDetails?.slugId}
              onClose={() => setViewQrPopup(false)}
            />
          )}
          {addWatermarkPopup && (
            <Watermark
              setAddWatermarkPopup={setAddWatermarkPopup}
              projectId={projectId}
              studioId={studioId}
              albumId={shootmatesAlbumId}
              visible={addWatermarkPopup}
              openAdvSttings={openAdvSettings}
              setOpenAdvSettings={setOpenAdvSettings}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopup
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          {viewRegisteredUsersPopup && (
            <PopupWrapper visible={viewRegisteredUsersPopup}>
              <RegisteredUsersPopup
                studioId={studioId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setViewRegisteredUsersPopup={setViewRegisteredUsersPopup}
              />
            </PopupWrapper>
          )}
          {paidPhotosSection && (
            <PopupWrapper visible={paidPhotosSection}>
              <PaidPhotosHistory
                studioId={studioId}
                slugId={albumDetails?.slugId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setPaidPhotosSection={setPaidPhotosSection}
              />
            </PopupWrapper>
          )}
          {viewLiveUsersPopup && (
            <PopupWrapper visible={viewLiveUsersPopup}>
              <LiveUsersPopup
                studioId={studioId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setViewLiveUsersPopup={setViewLiveUsersPopup}
              />
            </PopupWrapper>
          )}
          {sessionForm && (
            <TrainingFolder
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={sessionForm}
              setpopUp={setsessionForm}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              setError={setError}
            />
          )}
          {showConfirmation?.show && (
            <ConfirmationPopup
              showConfirmation={showConfirmation}
              onCancel={() => setShowConfirmation({ show: false })}
              onConfirm={() => handleConfirmation(showConfirmation)}
            />
          )}

          <div className="flex flex-col gap-[16px]  px-[20px] py-[12px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-[8px] items-center">
                <div className="eventDetailSideSeperator px-[15px]">
                  <img
                    src={eventFaceDetection}
                    alt=""
                    className="w-[62px] h-[62px]"
                  />
                </div>

                <div className="ml-[10px] flex justify-start items-start flex-col gap-[8px]">
                  <p className="albumType ">{albumDetails?.albumType} </p>
                  <p className="text-[19px] wh font-[600] items-center justify-center flex-shrink-0 flex gap-[4px]  text-[#161616] ">
                    {albumDetails?.albumName}
                    {/* <svg
                      onClick={() => setUpdate(!update)}
                      className="flex-shrink-0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
                        stroke="#4D58E3"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.6667 3.33301V7.49967H12.5"
                        stroke="#4D58E3"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg> */}
                  </p>
                  {albumDetails?.totalMedias && (
                    <p className="text-[14px] text-[#8C8C8C]">
                      {" "}
                      Total Photos
                      <span className="text-[#111] ml-[5px]">
                        {albumDetails?.totalMedias}{" "}
                      </span>{" "}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-[10px]">
                {/* <p className="text-[18px] text-[#8C8C8C]">
                  Notification Send -
                </p> */}

                <button
                  onClick={handleNotificationToggleClick}
                  className="advsettingdFace flex items-center gap-2"
                  // className={`px-6 py-2 text-white font-semibold rounded-full shadow-md transition-all duration-300 ${
                  //   toggleNotifyState
                  //     ? "bg-green-600 hover:bg-green-700"
                  //     : "bg-red-500 hover:bg-red-600"
                  // }`}
                >
                  Notification Send -
                  <span
                    className={`relative shadow-lg  inline-block w-12 h-6 transition duration-200 ease-in-out ${
                      toggleNotifyState ? "bg-[#4D58E3]" : "bg-[#F2F4F7]"
                    } rounded-full`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 ease-in-out transform ${
                        toggleNotifyState ? "translate-x-6" : "translate-x-0"
                      }`}
                    />
                  </span>
                  {/* {toggleNotifyState ? "ON" : "OFF"} */}
                </button>
                {toggleNotifyState === false && (
                  <button
                    onClick={sendMessages}
                    className="advsettingdFace  flex items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 2L11 13" />
                      <path d="M22 2L15 22 11 13 2 9z" />
                    </svg>
                    Send Messages
                  </button>
                )}
                <button
                  onClick={handlecreatefolder}
                  className="advsettingdFace  "
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M13.6595 6.22617C13.6462 5.90617 13.5928 5.59284 13.4928 5.29284C13.0328 3.85284 11.6862 2.81284 10.0995 2.81284H8.2395C7.85284 2.81284 7.82617 2.77283 7.61951 2.4995L6.6862 1.2595C6.25286 0.679501 5.91284 0.326172 4.82617 0.326172H3.27283C1.64616 0.326172 0.326172 1.64617 0.326172 3.27284V5.28616V10.0928C0.326172 12.0595 1.9262 13.6595 3.89286 13.6595H10.0929C12.0595 13.6595 13.6595 12.0595 13.6595 10.0928V6.37283C13.6662 6.33283 13.6662 6.27283 13.6595 6.22617Z"
                      fill="#4D58E3"
                    />
                  </svg>
                  Create folder
                </button>
                <button
                  onClick={() => setOpenAdvSettings(true)}
                  className="advsettingdFace "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                      stroke="#8C8C8C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.33301 8.5863V7.41297C1.33301 6.71963 1.89967 6.1463 2.59967 6.1463C3.80634 6.1463 4.29967 5.29297 3.69301 4.2463C3.34634 3.6463 3.55301 2.8663 4.15967 2.51963L5.31301 1.85963C5.83967 1.5463 6.51967 1.73297 6.83301 2.25963L6.90634 2.3863C7.50634 3.43297 8.49301 3.43297 9.09967 2.3863L9.17301 2.25963C9.48634 1.73297 10.1663 1.5463 10.693 1.85963L11.8463 2.51963C12.453 2.8663 12.6597 3.6463 12.313 4.2463C11.7063 5.29297 12.1997 6.1463 13.4063 6.1463C14.0997 6.1463 14.673 6.71297 14.673 7.41297V8.5863C14.673 9.27963 14.1063 9.85297 13.4063 9.85297C12.1997 9.85297 11.7063 10.7063 12.313 11.753C12.6597 12.3596 12.453 13.133 11.8463 13.4796L10.693 14.1396C10.1663 14.453 9.48634 14.2663 9.17301 13.7396L9.09967 13.613C8.49967 12.5663 7.51301 12.5663 6.90634 13.613L6.83301 13.7396C6.51967 14.2663 5.83967 14.453 5.31301 14.1396L4.15967 13.4796C3.55301 13.133 3.34634 12.353 3.69301 11.753C4.29967 10.7063 3.80634 9.85297 2.59967 9.85297C1.89967 9.85297 1.33301 9.27963 1.33301 8.5863Z"
                      stroke="#8C8C8C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  Manage Contents
                </button>
                {!albumDetails?.paidPhotos && (
                  <button onClick={openuserWindow} className="advsettingdFace ">
                    <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                    <p className="font_normal_11 text-[#161616]">
                      Open QR Window
                    </p>
                  </button>
                )}
                <button
                  onClick={() => setViewQrPopup(true)}
                  className="advsettingdFace bg-[#161616] text-[#fff]"
                >
                  {" "}
                  <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                  View QR Code
                </button>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          <GroupData
            albmTrainingSession={albmTrainingSession}
            calculateTimeDifference={calculateTimeDifference}
            setShowConfirmation={setShowConfirmation}
            handleRefreshImages={handleRefreshImages}
            location={location}
            navigate={navigate}
          />
        </div>
      )}
    </div>
  );
}

export default TrainingSession;

const GroupData = ({
  albmTrainingSession,
  calculateTimeDifference,
  setShowConfirmation,
  handleRefreshImages,
  location,
  navigate,
}) => {
  const renderData = (data) => {
    return (
      data &&
      data?.map((obj, index) => (
        <div
          key={index}
          className="flex items-center rounded-lg mt-2 newBox  py-[30px] px-[20px] shadow-sm hover:shadow-md transition-shadow duration-200 hover:bg-blue-50"
        >
          <div className="flex-shrink-0   rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M29.32 14.4533C29.2933 13.8133 29.1867 13.1866 28.9867 12.5866C28.0667 9.70665 25.3734 7.62665 22.2 7.62665H18.48C17.7066 7.62665 17.6533 7.54665 17.24 6.99998L15.3734 4.51998C14.5067 3.35998 13.8267 2.65332 11.6533 2.65332H8.54663C5.2933 2.65332 2.65332 5.29332 2.65332 8.54665V12.5733V22.1866C2.65332 26.12 5.85337 29.32 9.7867 29.32H22.1867C26.12 29.32 29.32 26.12 29.32 22.1866V14.7466C29.3333 14.6666 29.3333 14.5466 29.32 14.4533Z"
                fill="#4D58E3"
              />
              <path
                d="M20.7731 26.2667C17.9598 26.4667 17.9598 30.5467 20.7731 30.7467H27.4531C28.2664 30.7467 29.0397 30.4534 29.6397 29.9067C31.613 28.1867 30.5597 24.72 27.9597 24.4C27.0264 18.7734 18.8797 20.9067 20.813 26.2667"
                fill="#4D58E3"
              />
            </svg>
          </div>

          <div className="ml-4 w-full flex justify-between items-center ">
            <div className="">
              <p className="folderName">{obj.folderName}</p>

              <div className="flex mt-[8px] gap-6 text-sm text-gray-700">
                {obj.uploadType !== "DESK" && (
                  <div className="flex items-center">
                    <span className="ml-2">Total Images</span>
                    <span className="ml-2 text-blue-600">
                      {obj.uploadType === "DESK"
                        ? obj.processedImages
                        : obj.pullCount}
                    </span>
                  </div>
                )}
                <div className="flex text-[#B4B2B2] text-[14px] font-[500] items-center">
                  {/* <span className="ml-2">Uploaded Images</span> */}
                  <span className=" ">{obj.processedImages} Images</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center gap-3">
            {/* {obj.trainingStatus && (
              <div className="flex gap-6 mr-4 text-[12px] text-[#161616]">
                <div className="flex gap-2 items-center">
                  <span className="">AI Operation Time</span>
                  <span className=" text-[#E65F5F]">
                    {calculateTimeDifference(
                      obj.trainingEndTime,
                      obj.trainingStartTime,
                      "training",
                      obj.trainingStatus
                    )}
                  </span>
                  <span className="">AI Operation Status</span>
                  <span className=" text-[#E65F5F]">{obj.trainingStatus}</span>
                </div>
              </div>
            )} */}

            <div
              onClick={() =>
                navigate(`${location.pathname}/upload/${obj.folderId}`)
              }
              className=""
            >
              <button className="uploadImageButFace ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.75 20.25V15.75H15.75L12 11.25L8.25 15.75H11.25V20.25H7.5V20.2125C7.374 20.22 7.254 20.25 7.125 20.25C5.63316 20.25 4.20242 19.6574 3.14752 18.6025C2.09263 17.5476 1.5 16.1168 1.5 14.625C1.5 11.739 3.6825 9.387 6.483 9.0645C6.72854 7.78094 7.41368 6.62306 8.42057 5.79001C9.42746 4.95696 10.6932 4.5008 12 4.5C13.307 4.50072 14.5729 4.95682 15.5801 5.78985C16.5872 6.62289 17.2727 7.78081 17.5185 9.0645C20.319 9.387 22.4985 11.739 22.4985 14.625C22.4985 16.1168 21.9059 17.5476 20.851 18.6025C19.7961 19.6574 18.3653 20.25 16.8735 20.25C16.7475 20.25 16.626 20.22 16.4985 20.2125V20.25H12.75Z"
                    fill="black"
                  />
                </svg>{" "}
                <p>Upload</p>
              </button>
            </div>
            <div onClick={() => handleRefreshImages(obj.folderId)} className="">
              <button className="uploadImageButFace ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 4V9H15"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Refresh</p>
              </button>
            </div>
            {/* <div
              onClick={() =>
                setShowConfirmation({
                  show: true,
                  folderId: obj.folderId,
                  folderName: obj.folderName,
                  totalImages: obj.totalImages,
                  processedImages: obj.processedImages,
                })
              }
              className=""
            >
              <button className="publishBut"> publish</button>
            </div> */}
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="faceTrainingDetailTable">
      {albmTrainingSession && albmTrainingSession.length === 0 ? (
        <h1>
          <span className="ml-5 text-red-500">No data found!</span>
        </h1>
      ) : (
        renderData(albmTrainingSession)
      )}
    </div>
  );
};
