import React from "react";

function AdvanceSettingsAlb({
  handleAlbumMetaDataButton,
  setaddPinPopup,
  shootmatesAlbumId,
  handleUserListingButton,
  setEditAlbumTemplate,
  setOpenAdvSettings,
}) {
  return (
    <div className="fixed bg-gray-900 bg-opacity-75 z-[100] top-0 right-0 bottom-0 left-0 justify-center items-center  flex mx-auto   ">
      <div className="bg-[#fff]   -mt-[13%] pop  rounded-[10px] flex flex-col p-[15px]    ">
        <div className="flex items-end justify-end">
          {/* <p className="text-[18px]">Advance Settings</p>*/}
          <button onClick={() => setOpenAdvSettings(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="text-[16px] flex-col flex justify-start items-start">
          {/* <div onClick={copyToClipboard} className="shortButtonBlack">
            <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
            <p className="font_normal_11 text-[#F6F6F6]">
              {copiedLive ? "Album Link Copied!" : "Copy Album Link"}
            </p>
          </div> */}
          <div className="flex flex-col items-center ">
          <button
              onClick={handleAlbumMetaDataButton}
              className="flex gap-[11px] hover:border-opacity-100  py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">Settings</p>
            </button>
            <button
              onClick={() => {
                setEditAlbumTemplate({
                  mode: true,
                  albumId: shootmatesAlbumId,
                });
              }}
              className="flex hover:border-opacity-100 gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={editIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">Edit album template</p>
            </button>
            <button
              onClick={handleUserListingButton}
              className="flex gap-[11px] hover:border-opacity-100 py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">View / Manage Crew</p>
            </button>
          <button
              onClick={() => {
                setaddPinPopup(true);
              }}
              className="flex gap-[11px] hover:border-opacity-100 py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={setPinIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">Set pin</p>
            </button>
            {/* <div
              onClick={() => {
                setGalleryPopup(true);
              }}
              className="shortButton"
            >
              <img src={options} alt="" className="w-[16px] h-[16px]" />
              <p className="font_normal_11 text-[#161616]">
                Create album gallery
              </p>
            </div> */}
            {/* <div
                onClick={() => {
                  setAddUserPopup(true);
                }}
                className="shortButton"
              >
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Add user</p>
              </div> */}
          
           
        
          </div>
          <div className="flex w-full items-center ">
            {/* <button
              onClick={handleAlbumMetaDataButton}
              className="flex gap-[11px]  py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
             <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" /> 
              <p className=" font-[400] text-[#161616]">Settings</p>
            </button> */}

            {/* <div
              onClick={() => {
                setUpdate(!update);
              }}
              className="shortButton"
            >
              <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
              <p className="font_normal_11 text-[#161616]">Refresh</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvanceSettingsAlb;
