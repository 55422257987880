import React from "react";
import { useParams, useLocation } from "react-router-dom";
import ImageList from "../ImageList/ImageList";
import UploadPanel from "../UploadPanel/UploadPanel";
import UploadQueuePopup from "../UploadQueue/UploadQueue";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import MessagePopup from "../PopUps/MessagePopup/MessagePopup";
import UploadPopup from "../UploadPopup/UploadPopup";
import "./ImageUpload.css";
import Toast from "../ToastMessage/Toast";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import useOptimizedImageUploader from "../../Context/useUploader";
const ImageUpload = () => {
  const location = useLocation();
  const {
    handleRefreshImages,
    toastMessages,
    //albumType,
    loading,
    isExpanded,
    showUploadQueue,
    totalFiles,
    completedFiles,
    currentFile,
    currentFileProgress,
    messagePopup,
    uploadedFiles,
    selectedMedia,
    setSelectedMedias,
    folderName,
    folderId,
    slugId,
    studioDetails,
    memshotAlbumId,
    showUploadPopup,
    isUploading,
    fileUploadProgress,
    selectedFiles,
    uploadedMedias,
    //paidPhotos,
    refresh,
    setRefresh,
    type,
    getBucketName,
    setIsExpanded,
    setShowUploadPopup,
    deletingMedia,
    getBreadCrumbData,
    setMessagePopup,
    setToastMessages,
    handleImageImport,
    handleDeleteImage,
    toggleExpand,
    aiPublishersData,
  } = useOptimizedImageUploader();

  const [selectedOption, setSelectedOption] = React.useState(null);
  const sectionType = location?.pathname.split("/")[3];
  return (
    <>
      <Breadcrumb
        update={refresh}
        setUpdate={setRefresh}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${useParams().projectId}` },
          getBreadCrumbData(sectionType),
        ]}
      />
      {messagePopup?.visible && (
        <MessagePopup
          messagePopup={messagePopup}
          setMessagePopup={setMessagePopup}
        />
      )}
      <div className="uploader mt-[15px] p-[10px]">
        <Toast messages={toastMessages} onClose={() => setToastMessages([])} />
        {showUploadQueue && (
          <UploadQueuePopup
            compressing={false}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            uploadedFiles={uploadedFiles}
            selectedFiles={selectedFiles}
            toggleExpand={toggleExpand}
            totalFiles={totalFiles}
            completedFiles={completedFiles}
            fileUploadProgress={fileUploadProgress}
            currentFile={currentFile}
            currentFileProgress={currentFileProgress}
          />
        )}
        <UploadPanel
          handleRefreshImages={handleRefreshImages}
          slugId={slugId}
          type={type}
          studioDetails={studioDetails}
          memshotAlbumId={memshotAlbumId}
          setShowUploadPopup={setShowUploadPopup}
          uploadedMediasLength={uploadedMedias?.length - 1}
          setRefresh={setRefresh}
          refresh={refresh}
          selectedMedia={selectedMedia}
          setSelectedMedias={setSelectedMedias}
        />
        {showUploadPopup && (
          <UploadPopup
            isUploading={isUploading}
            handleImageImport={handleImageImport}
            showUploadPopup={showUploadPopup}
            setShowUploadPopup={setShowUploadPopup}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <ImageList
            aiPublishersData={aiPublishersData}
            selectedMedia={selectedMedia}
            setSelectedMedias={setSelectedMedias}
            handleDeleteImage={handleDeleteImage}
            memshotAlbumId={memshotAlbumId}
            uploadedMedias={uploadedMedias}
            type={type}
            sectionType={sectionType}
            getBucketName={getBucketName}
            filesizes={null} // Adjust as needed
            folderName={folderName}
            folderId={folderId}
            deletingMedia={deletingMedia}
          />
        )}
      </div>
    </>
  );
};

export default ImageUpload;
