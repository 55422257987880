import React from 'react'

function SetPhotoPricePopup({handleSetPrice,newPrice,setNewPrice,setPricePopupOpen}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Set Price</h2>
            <input
              type="number"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
              min="1"
              placeholder="Enter new price"
              className="w-full p-2 border rounded-md mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setPricePopupOpen(false)}
                className="px-4 py-2 text-sm font-medium rounded-full bg-gray-500 text-white transition hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleSetPrice}
                className="px-4 py-2 text-sm font-medium rounded-full bg-blue-500 text-white transition hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
  )
}

export default SetPhotoPricePopup