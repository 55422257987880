import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Slider from "../Slider/Slider";
import { userAuthContext } from "../../Context/UserAuthContext";
import Loading from "../../Assets/Animation/Loading";
import "./PaidPhotoHistory.css";
import SetPhotoPricePopup from "./SetPhotoPricePopup";

function PaidPhotosHistory({
  studioId,
  memshotAlbumId,
  projectId,
  setPaidPhotosSection,
  slugId,
}) {
  const [refreshing, setRefreshing] = useState(true);
  const [paymentHistories, setPaymentHistories] = useState([]);
  const [filterOption, setFilterOption] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pricePopupOpen, setPricePopupOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [expired, setExpired] = useState(null);
  const [newPrice, setNewPrice] = useState("");
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        await verifyUser();
        const config = {
          method: "get",
          url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/paidpaymenthistory/${projectId}/${studioId}/${memshotAlbumId}`,
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        setPaymentHistories(response?.data?.data[0]);

        setCurrentPrice(response?.data?.data[0]?.paidPhotoFee);
        setExpired(response?.data?.data[0]?.isExpired);
        setRefreshing(false);
      } catch (error) {
        console.error("Error fetching registered users:", error);
        setRefreshing(false);
      }
    };
    fetchRegisteredUsers();
  }, [refreshing, filterOption]);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(`https://app.memshots.com/faces/${link}`);
  };

  const handleViewFaces = (user) => {
    setSelectedUser(user);
    setCurrentImageIndex(0);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleCloseSlider = () => {
    setSelectedUser(null);
  };

  const getPaymentStatus = (status) => {
    if (status === "PAYMENT_SUCCESS")
      return { text: "Payment Completed", color: "text-green-500" };
    if (status === "PAYMENT_ERROR")
      return { text: "Payment Failed", color: "text-red-500" };
    return { text: "Payment Pending", color: "text-yellow-500" };
  };

  const openOrderLink = (order) => {
    const link = `https://app.memshots.com/${slugId}/purchasedphotos/${order?.orderId}`;
    window.open(link, "_blank");
  };

  const handleSetPrice = async () => {
    try {
      setRefreshing(true);

      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/setPrice/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          amount: newPrice,
        },
      };
      setPricePopupOpen(false);
      const response = await axios(config);

      setRefreshing(false);
    } catch (error) {
      console.log("error", error);
      setRefreshing(false);
    }
  };

  const handleChangeExpiry = async () => {
    try {
      setRefreshing(true);
      const config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/changeexpiryStatus/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRefreshing(false);
    } catch (error) {
      console.log("error", error);
      setRefreshing(false);
    }
  };

  return (
    <>
      {pricePopupOpen && (
        <SetPhotoPricePopup
          handleSetPrice={handleSetPrice}
          newPrice={newPrice}
          setNewPrice={setNewPrice}
          setPricePopupOpen={setPricePopupOpen}
        />
      )}
      <div className="bg-white shadow-lg max-w-6xl w-full h-[600px] overflow-y-auto rounded-lg mt-10">
        {selectedUser && (
          <Slider
            memshotAlbumId={memshotAlbumId}
            images={selectedUser?.faceUrl}
            currentIndex={currentImageIndex}
            onPrev={handlePreviousImage}
            onNext={handleNextImage}
            onClose={handleCloseSlider}
          />
        )}
        <div className="flex justify-between items-center px-[16px] py-[20px]">
          <div className="justify-start items-start flex">
            <button className="iconWatermark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 6V4H6V6H9.5C10.802 6 11.901 6.838 12.315 8H6V10H12.315C12.1102 10.5836 11.7296 11.0893 11.2254 11.4474C10.7213 11.8056 10.1184 11.9987 9.5 12H6V14.414L11.586 20H14.414L8.414 14H9.5C10.6518 13.9979 11.7677 13.599 12.6598 12.8704C13.552 12.1419 14.1658 11.1282 14.398 10H17V8H14.398C14.2458 7.27184 13.9298 6.58788 13.474 6H17Z"
                  fill="#373CBD"
                />
              </svg>
            </button>
            <div className="ml-[12px]">
              <p className="whitespace-nowrap managePhotosPrice">
                Payment History
              </p>
              <p className="whitespace-nowrap managePhotosPriceText">
                {`Current Price Per Photo - ${
                  currentPrice || "Price not set ! Please set the price"
                }`}
              </p>
              <div className="gap-[15px] text-[#454545] flex mt-[5px] justify-start items-start">
                <p className="text-sm font-medium">
                  {`Order Expiry Status - ${
                    refreshing
                      ? "Loading..."
                      : expired === true
                      ? "Expired"
                      : "Active"
                  }`}
                </p>
                <p className="text-sm font-medium">
                  {`Total orders: ${
                    refreshing
                      ? "Loading..."
                      : paymentHistories?.paidPhotosOrders?.length || 0
                  }`}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-[20px] justify-start">
            {/* <span className="text-white text-sm font-medium">
            {`Current Price Per Photo - ${
              refreshing
                ? "Loading..."
                : currentPrice || "Price not set ! Please set the price"
            }`}
          </span> */}
            <div className="flex gap-[15px] items-start justify-start">
              <button
                onClick={() => setPricePopupOpen(true)}
                className="whitespace-nowrap flex justify-center items-center setPrice"
              >
                <svg
                  className="flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.3333 4.00033V2.66699H4V4.00033H6.33333C7.20133 4.00033 7.934 4.55899 8.21 5.33366H4V6.66699H8.21C8.07349 7.05603 7.8197 7.39317 7.4836 7.63196C7.1475 7.87075 6.74563 7.99945 6.33333 8.00033H4V9.60966L7.724 13.3337H9.60933L5.60933 9.33366H6.33333C7.10121 9.33226 7.84515 9.06632 8.4399 8.58061C9.03464 8.0949 9.44387 7.4191 9.59867 6.66699H11.3333V5.33366H9.59867C9.49721 4.84822 9.28655 4.39225 8.98267 4.00033H11.3333Z"
                    fill="#373CBD"
                  />
                </svg>
                {currentPrice > 0 ? "Reset Price" : "Set Price"}
              </button>

              <button
                onClick={handleChangeExpiry}
                className="whitespace-nowrap setPrice"
              >
                Change Expiry
              </button>

              <button
                onClick={() => setRefreshing(!refreshing)}
                className="whitespace-nowrap flex justify-center items-center setPrice"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 4V9H15"
                    stroke="#4D58E3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Refresh</p>
              </button>
              <button onClick={() => setPaidPhotosSection(false)} className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* <div className="flex items-center space-x-4">
         
          <button
            onClick={() => setRefreshing(!refreshing)}
            className={`px-4 py-2 text-sm font-medium rounded-full text-white transition ${
              refreshing ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
            }`}
            disabled={refreshing}
          >
            {refreshing ? "Refreshing..." : "Refresh"}
          </button>
          <button
            onClick={() => setPaidPhotosSection(false)}
            className="px-4 py-2 text-sm font-medium rounded-full bg-red-500 hover:bg-red-600 text-white transition"
          >
            Close
          </button>
        </div> */}
        </div>

        {refreshing ? (
          <Loading />
        ) : (
          <div className="text-[#161616] p-[10px]">
            <table className="w-full ">
              <thead className="border-[1px] border-[#111] border-opacity-20">
                <tr className="text-[12px] font-[500] ">
                  {[
                    "No",
                    "Name",
                    "Email/Number",
                    "WhatsApp",
                    "Photos",
                    "Amount",
                    "Status",
                    "Order Link",
                  ].map((header) => (
                    <th key={header} className="py-3 px-5  text-center">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paymentHistories?.paidPhotosOrders?.length > 0 ? (
                  paymentHistories.paidPhotosOrders.map((order, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 transition-colors"
                    >
                      <td className="py-3 px-5 text-center">{index + 1}</td>
                      <td className="py-3 px-5 text-center">{order?.name}</td>
                      <td className="py-3 px-5 text-center">{order?.email}</td>
                      <td className="py-3 px-5 text-center">
                        {order.whatsAppNo}
                      </td>
                      <td className="py-3 px-5 text-center">
                        {order?.photosRequested?.length}
                      </td>
                      <td className="py-3 px-5 text-center">
                        {order?.totalAmount}
                      </td>
                      <td
                        className={`py-3 px-5 text-center font-medium ${
                          getPaymentStatus(order?.paymentPaidStatus).color
                        }`}
                      >
                        {getPaymentStatus(order?.paymentPaidStatus).text}
                      </td>
                      <td className="py-3 px-5 text-center">
                        <button
                          onClick={() => openOrderLink(order)}
                          className="text-blue-600 hover:underline"
                        >
                          Open
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="py-10 text-center">
                      <p className="mt-[13%] text-center text-gray-500">
                        Clients haven’t made any purchase
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default PaidPhotosHistory;
