import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ref, uploadBytesResumable, listAll } from "firebase/storage";
import _ from "lodash";
import { userAuthContext } from "../Context/UserAuthContext";
import { dataContext } from "../Context/DataContext";
import { bucketStorageMap } from "../store/firebase-config";

const MAX_FILE_SIZE = 100 * 1024 * 1024;
const MAX_FILES_LIMIT = 2000;
const MAX_UPLOAD_BATCH = 500;

const useOptimizedImageUploader = () => {
  const sizeRespToMainImage = 0.1;
  const paddingRespectiveToMainImage = 0.01;
  const [selectedMedia, setSelectedMedias] = useState([]);
  const [toastMessages, setToastMessages] = useState([]);
  const [isCompress, setIsCompress] = useState(false);
  const [albumType, setAlbumType] = useState("");
  const [type, setType] = useState("album");
  const [refresh, setRefresh] = useState(false);
  const [aiPublishersData,setAiPublishersData]=useState({})
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadQueue, setShowUploadQueue] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentFileProgress, setCurrentFileProgress] = useState(0);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [folderName, setFolderName] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [slugId, setSlugId] = useState(null);
  const [memshotAlbumId, setMemshotsAlbumId] = useState(null);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedMedias, setUploadedMedias] = useState([]);
  const [paidPhotos, setPaidPhotos] = useState(null);
  const [deletingMedia, setDeletingMedia] = useState(null);
  const [watermarkdetails, setwatermarkdetails] = useState(null);
  const [messageSend,setMessageSend]=useState(false)
  const { projectId, shootmatesAlbumId, sectionId } = useParams();
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, studioDetails } = useContext(dataContext);

  useEffect(() => {
    verifyUser();
    const fetchData = async () => {
      if (studioId) {
        setLoading(true);
        try {
          const type = await getUploadedMediasData();
          if (type === "aiunpaid" || type === "aipaid") {
            await getaipublisherdata();
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, uploadedFiles, refresh]);

  const getBucketName = (type) => {
    switch (type) {
      case "photoselection":
      case "album":
      case "photosharetool":
        return "albumdata";
      case "aipaid":
        return "shootmatesprivatecloud";
      case "aiunpaid":
        return "aimemshots";
      default:
        return "albumdata";
    }
  };

  

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectURL = URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
        URL.revokeObjectURL(objectURL); // Release memory
      };
      img.onerror = (err) => {
        URL.revokeObjectURL(objectURL); // Ensure memory is freed on error
        reject(err);
      };
      img.src = objectURL;
    });
  };

  const getSizeMaintainingAspectRatio = (image, maxWidth, maxHeight) => {
    const imageWidth = image.width;
    const imageHeight = image.height;
    // Let's find the max available width for the scaled image
    var ratio = imageWidth / imageHeight;
    var aspectWidth = Math.min(maxWidth, ratio * maxHeight);
    var aspectHeight = Math.floor(aspectWidth / ratio);
    return {
      width: imageWidth > aspectWidth ? aspectWidth : imageWidth,
      height: imageHeight > aspectHeight ? aspectHeight : imageHeight,
    };
  };

  const getWatermarkSize = (image, width, height) => {
    if (image.width < image.height) {
      return getSizeMaintainingAspectRatio(image, width, height);
    } else {
      return getSizeMaintainingAspectRatio(image, height, width);
    }
  };

  const getWatermarkRect = (mainImage, watermark, position) => {
    const sizeRespectToMainImage = sizeRespToMainImage;
    let watermarkHeight;
    let watermarkWidth;
    let padding;

    if (mainImage.width > mainImage.height) {
      watermarkHeight = mainImage.height * sizeRespectToMainImage;
      watermarkWidth = mainImage.width * sizeRespectToMainImage;
      padding = mainImage.width * paddingRespectiveToMainImage;
    } else {
      watermarkHeight = mainImage.width * sizeRespectToMainImage;
      watermarkWidth = mainImage.height * sizeRespectToMainImage;
      padding = mainImage.height * paddingRespectiveToMainImage;
    }

    const watermarkSize = getWatermarkSize(
      watermark,
      watermarkWidth,
      watermarkHeight
    );

    switch (position) {
      case "top-left":
        return {
          x: padding * 1.5,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "top-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * 1.5,
          y: padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-left":
        return {
          x: padding * 1.5,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      case "bottom-right":
        return {
          x: mainImage.width - watermarkSize.width - padding * 1.5,
          y: mainImage.height - watermarkSize.height - padding,
          width: watermarkSize.width,
          height: watermarkSize.height,
        };
      default:
        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        };
    }
  };

  const getUploadedMediasData = useCallback(async () => {
    try {
      const config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumsections/getMediaSectionData/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        const { folderName, folderId, paidPhotos } =
          response.data.data.sections[0].sectionProps;
        setFolderName(folderName);
        setFolderId(folderId);
        setIsCompress(response?.data?.data?.compress === true ? true : false); //change to key from backend in future
        setMessageSend(response?.data?.data?.msgSend === true ? true : false);
        setMemshotsAlbumId(response?.data?.data?.memshotAlbumId);
        setSlugId(response?.data?.data?.slugId);
        setwatermarkdetails(response?.data?.data?.watermarkdetails);
        setAlbumType(response?.data?.data?.albumType);
        setType(
          response?.data?.data?.type ? response?.data?.data?.type : "album"
        );
        setPaidPhotos(paidPhotos);
        await getMediaNamesListing(
          response?.data?.data?.memshotAlbumId,
          folderName,
          response?.data?.data?.type ? response?.data?.data?.type : "album"
        );
        return response?.data?.data?.type
          ? response?.data?.data?.type
          : "album";
      }
      return "album";
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, studioId, shootmatesAlbumId, sectionId, tokenData]);

  const getaipublisherdata = useCallback(async () => {
    try {
      const config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/facedetection/getaipublisherdata/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        const data = response?.data?.data;
        // Group by status
        const grouped = _.groupBy(data, (item) =>
          _.get(item, "medias.status", "UNKNOWN")
        );

        // Merge media lists with error handling
        const statusMap = _.mapValues(grouped, (group) =>
          _.flatMap(group, (item) => {
            const mediaList = _.get(item, "medias.medias", []);
            return Array.isArray(mediaList) && mediaList.length > 0
              ? mediaList
              : [];
          })
        );
        setAiPublishersData(statusMap)
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, studioId, shootmatesAlbumId, sectionId, tokenData]);

  const handleRefreshImages = useCallback(async () => {
    try {
      const config = {
        method: "patch",
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboardainew/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      await axios(config);
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, studioId, shootmatesAlbumId, folderId, tokenData]);

  const getMediaNamesFromFolder = async (memshotAlbumId, folderName, type) => {
    try {
      const bucketName = getBucketName(type);
      const selectedStorage = bucketStorageMap[bucketName];
      if (!selectedStorage) throw new Error("Invalid bucket name.");

      const storageRef = ref(
        selectedStorage,
        `/${memshotAlbumId}/${folderName}`
      );
      const result = await listAll(storageRef);
      return result.items.map((item) => item.name);
    } catch (error) {
      console.error("Error fetching media names:", error);
      throw error;
    }
  };

  const getMediaNamesListing = async (memshotAlbumId, folderName, type) => {
    try {
      const mediaNames = await getMediaNamesFromFolder(
        memshotAlbumId,
        folderName,
        type
      );
      setUploadedMedias(mediaNames);
    } catch (error) {
      console.error("Error fetching media names:", error);
    }
  };

  const addWatermark = async (
    originalImageFile,
    watermarkImagePath,
    position,
    quality
  ) => {
    try {
      console.log("watermnarkl");
      
      // Load the original image
      const originalImage = await createImageBitmap(originalImageFile);
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const canvasWidth = originalImage.width;
      const canvasHeight = originalImage.height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Draw the original image onto the canvas
      context.drawImage(originalImage, 0, 0, canvasWidth, canvasHeight);

      // Fetch and load the watermark image
      const result = await fetch(watermarkImagePath);
      const blob = await result.blob();
      const watermarkImage = await createImageBitmap(blob);

      // Get the watermark position
      const getWatermarkPositions = getWatermarkRect(
        originalImage,
        watermarkImage,
        position
      );

      console.log("getWatermarkPositions", getWatermarkPositions);

      // Draw the watermark onto the canvas
      context.drawImage(
        watermarkImage,
        getWatermarkPositions?.x,
        getWatermarkPositions?.y,
        getWatermarkPositions?.width,
        getWatermarkPositions?.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Create a new file from the Blob
              const watermarkedFile = new File([blob], originalImageFile.name, {
                type: "image/jpeg",
              });

              // ✅ Resolve with watermarked file and dimensions
              resolve({
                watermarkedFile,
                width: canvasWidth,
                height: canvasHeight,
              });
            } else {
              reject(new Error("Canvas is empty"));
            }
          },
          "image/jpeg",
          quality
        );
      });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const compressImage = useCallback(async (file) => {
    try {
      const img = new Image();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = (e) => {
          img.src = e.target.result;
          img.onload = () => {
            // Calculate new dimensions (using Math.floor to ensure integer values)
            const width = Math.floor(img.width / 2);
            const height = Math.floor(img.height / 2);

            // Create a canvas to draw the compressed image
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas to a Blob
            canvas.toBlob(
              (resultBlob) => {
                if (!resultBlob) {
                  reject(new Error("Canvas is empty"));
                  return;
                }
                // Create a new file from the Blob
                const compressedFile = new File([resultBlob], file.name, {
                  type: "image/jpeg",
                });
                // Resolve with both the file and its dimensions
                resolve({ compressedFile, width, height });
              },
              "image/jpeg",
              0.9
            );
          };

          img.onerror = (error) => {
            reject(error);
          };
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  }, []);

  const handleImageImport = useCallback(
    async (acceptedFiles) => {
      try {
        if (isUploading) {
          setToastMessages([
            "Please wait for the current upload task to complete..",
          ]);
          return;
        }

        const [validFiles, invalidFiles] = _.partition(
          acceptedFiles,
          (file) => {
            const isImage =
              file.type.startsWith("image/jpeg") ||
              file.type.startsWith("image/jpg");
            return isImage && file.size <= MAX_FILE_SIZE;
          }
        );

        if (invalidFiles.length > 0) {
          setToastMessages(
            invalidFiles.map(
              (file) =>
                `${file.name} is an unsupported file type or exceeds the size limit 25mb`
            )
          );
        }

        if (validFiles.length < 1) {
          setToastMessages([
            "No Valid File Format Found or files exceed the size limit 25mb.",
          ]);
          return;
        }

        if (validFiles.length > MAX_UPLOAD_BATCH) {
          setToastMessages([
            `Only ${MAX_UPLOAD_BATCH} images can be selected at a time. Skipping additional images.`,
          ]);
          validFiles.splice(MAX_UPLOAD_BATCH);
        }

        const storageListsData = await getMediaNamesFromFolder(
          memshotAlbumId,
          folderName,
          type
        );
        const fileNames = storageListsData || [];
        const readyToUploadFiles = _.differenceBy(
          validFiles,
          fileNames.map((name) => ({ name })),
          "name"
        );
        const skipCount = _.intersectionBy(
          validFiles,
          fileNames.map((name) => ({ name })),
          "name"
        ).length;

        if (skipCount > 0) {
          setToastMessages([`${skipCount} file(s) already exist. Skipping...`]);
        }

        if (fileNames.length >= MAX_FILES_LIMIT) {
          setShowUploadPopup(false);
          setToastMessages([
            `Only 500 file(s) can be uploaded in one folder. Create another folder.`,
          ]);
          return;
        }

        const availableSlots = MAX_FILES_LIMIT - fileNames.length;
        if (readyToUploadFiles.length > availableSlots) {
          readyToUploadFiles.splice(availableSlots);
          setToastMessages([
            `${availableSlots} file(s) will be uploaded. Skipping additional files.`,
          ]);
        }

        if (readyToUploadFiles.length === 0) {
          setShowUploadPopup(false);
          setToastMessages([`No New file(s) to upload`]);
          return;
        }

        setSelectedFiles([]);
        setShowUploadPopup(false);
        setShowUploadQueue(true);
        setIsExpanded(true);
        setSelectedFiles(readyToUploadFiles);
        await startFilesUpload(readyToUploadFiles, type);
      } catch (error) {
        console.error("Error handling image import:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUploading, memshotAlbumId, folderName]
  );

  const startFilesUpload = async (readyToUploadFiles, type) => {
    if (readyToUploadFiles.length > 0) {
      try {
        setIsUploading(true);
        setTotalFiles(readyToUploadFiles.length);
        setCompletedFiles(0);
        let imageWidth = null;
        let imageHeight = null;
        const watermarkUrl = watermarkdetails?.watermarkUrl;
        const watermarkPosition = watermarkdetails?.position;

        for (const file of readyToUploadFiles) {
          let fileToUpload = file;
          // Check if the file size is less than 5MB (5 * 1024 * 1024 bytes)
          if (isCompress && file.size > 5 * 1024 * 1024) {
            // First, add the watermark if needed
            if (watermarkUrl && watermarkPosition) {
              const { watermarkedFile, width, height } = await addWatermark(
                fileToUpload,
                watermarkUrl,
                watermarkPosition,
                1
              );
              fileToUpload = watermarkedFile;
            }
            // Then compress the image
            const { compressedFile, width, height } = await compressImage(fileToUpload);
            console.log("Compressed image dimensions:", width, height);
            imageWidth = width;
            imageHeight = height;
            fileToUpload = compressedFile;
          } else {
            if (watermarkUrl && watermarkPosition) {
              // Only add the watermark if it's there
              const { watermarkedFile, width, height } = await addWatermark(
                fileToUpload,
                watermarkUrl,
                watermarkPosition,
                1
              );
              imageWidth = width;
              imageHeight = height;
              fileToUpload = watermarkedFile;
            } else {
              console.log("no compress");
              const { width, height } = await getImageDimensions(fileToUpload);
              imageWidth = width;
              imageHeight = height;
              // No watermark and no compression, use the original file
              fileToUpload = file;
            }
          }

          const bucketName = getBucketName(type);
          const selectedStorage = bucketStorageMap[bucketName];
          if (!selectedStorage) throw new Error("Invalid bucket name.");
          const fileName = `${file.name}`;
          const storageRef = ref(
            selectedStorage,
            `/${memshotAlbumId}/${folderName}/${fileName}`
          );
          const metadata = {
            customMetadata: {
              width: imageWidth,
              height:imageHeight
            },
          };
  
          const uploadTask = uploadBytesResumable(storageRef, fileToUpload, metadata);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setCurrentFileProgress(progress);
              setFileUploadProgress((prev) => ({
                ...prev,
                [fileName]: progress,
              }));
            },
            (error) => {
              console.error(`Error uploading ${fileName}:`, error);
              alert(`Error uploading ${fileName}. Please try again.`);
            },
            () => {
              setCurrentFile(null);
              setCurrentFileProgress(0);
              setFileUploadProgress((prev) => {
                const updatedProgress = { ...prev };
                delete updatedProgress[fileName];
                return updatedProgress;
              });
              setCompletedFiles((prev) => prev + 1);
              setUploadedFiles((prev) => ({ ...prev, [fileName]: true }));
            }
          );
          await uploadTask;
        }

        await handleRefreshImages();
        setIsUploading(false);
      } catch (error) {
        console.error("Error uploading files to Firebase:", error);
        setIsUploading(false);
        alert("Error uploading files. Please try again.");
      }
    } else {
      alert("Please select file");
    }
  };

  const handleDeleteImage = async (imageName, folderId) => {
    try {
      setDeletingMedia(imageName); // Set the media being deleted
      const config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/deletemediadashboard/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          fileName: imageName,
          folderId: folderId,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setUploadedMedias(
          uploadedMedias.filter((media) => media !== imageName)
        );
      }
    } catch (error) {
      console.error(`Error deleting ${imageName}:`, error);
      alert(`Error deleting ${imageName}. Please try again.`);
    } finally {
      setDeletingMedia(null); // Reset the deleting state
    }
  };

  const getBreadCrumbData = (type) => {
    if (type === "facedetection") {
      return {
        label: "Face Detection Details",
        url: `/Event/${projectId}/facedetection/${shootmatesAlbumId}`,
      };
    } else if (type === "albumdetail") {
      return {
        label: "Album Details",
        url: `/Event/${projectId}/albumdetail/${shootmatesAlbumId}`,
      };
    } else if (type === "invitationdetails") {
      return {
        label: "Invitation Details",
        url: `/Event/${projectId}/invitationdetails/${shootmatesAlbumId}`,
      };
    } else if (type === "selectiontool") {
      return {
        label: "Selection Tool Details",
        url: `/Event/${projectId}/selectiontool/${shootmatesAlbumId}`,
      };
    } else if (type === "photosharetool") {
      return {
        label: "Photo Share Tool Details",
        url: `/Event/${projectId}/photosharetool/${shootmatesAlbumId}`,
      };
    } else {
      return {
        label: "Album Details",
        url: `/Event/${projectId}/albumdetail/${shootmatesAlbumId}`,
      };
    }
  };

  return {
    handleRefreshImages,
    messageSend,
    toastMessages,
    albumType,
    type,
    getBucketName,
    loading,
    isExpanded,
    showUploadQueue,
    totalFiles,
    completedFiles,
    currentFile,
    currentFileProgress,
    messagePopup,
    uploadedFiles,
    refresh,
    setRefresh,
    selectedMedia,
    setSelectedMedias,
    folderName,
    folderId,
    memshotAlbumId,
    showUploadPopup,
    isUploading,
    fileUploadProgress,
    selectedFiles,
    uploadedMedias,
    paidPhotos,
    deletingMedia,
    handleImageImport,
    handleDeleteImage,
    setShowUploadPopup,
    setMessagePopup,
    slugId,
    studioDetails,
    setToastMessages,
    getBreadCrumbData,
    toggleExpand: () => setIsExpanded(!isExpanded),
    aiPublishersData
  };
};

export default useOptimizedImageUploader;
