import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Slider from "../Slider/Slider";
import { userAuthContext } from "../../Context/UserAuthContext";
import Loading from "../../Assets/Animation/Loading";
function RegisteredUsersPopup({
  studioId,
  memshotAlbumId,
  projectId,
  setViewRegisteredUsersPopup,
}) {
  const [refreshing, setRefreshing] = useState(true);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [filterOption, setFilterOption] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        await verifyUser();
        let config = {
          method: "get",
          url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/regfaces/${projectId}/${studioId}/${memshotAlbumId}?reqType=${filterOption}`,
          // url:`https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/aiservices/regfaces/${projectId}/${studioId}/${memshotAlbumId}?reqType=${filterOption}`,

          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        setRegisteredUsers(response?.data?.data);
        setRefreshing(false);
      } catch (error) {
        console.error("Error fetching registered users:", error);
        setRefreshing(false);
      }
    };
    fetchRegisteredUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing, filterOption]);

  const handleCopyLink = (link) => {
    const textarea = document.createElement("textarea");
    textarea.value = `https://app.memshots.com/faces/${link}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleViewFaces = (user) => {
    setSelectedUser(user);
    setCurrentImageIndex(0);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleCloseSlider = () => {
    setSelectedUser(null);
  };
  const getVerified = (user) => {
    if (user?.email) {
      return user?.email;
    }
    if (user?.whatsAppNo) {
      return user?.whatsAppNo;
    }
    return user?.regWhatsAppNo ? user?.regWhatsAppNo : "email not verified";
  };
  const convertMongoDBTimestampToIST = (mongodbTimestamp) => {
    const utcTime = new Date(mongodbTimestamp);
    const istTime = utcTime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    return istTime;
  };
  return (
    <div className="bg-white mt-[5%] max-w-7xl w-full h-[600px] overflow-y-auto rounded-md shadow-md">
      {selectedUser && (
        <Slider
          memshotAlbumId={memshotAlbumId}
          images={selectedUser?.faceUrl}
          currentIndex={currentImageIndex}
          onPrev={handlePreviousImage}
          onNext={handleNextImage}
          onClose={handleCloseSlider}
        />
      )}
      <div className="flex justify-between text-[#111] items-center p-6 sticky top-0 bg-white">
        <p className="text-xl font-semibold ">Registered Users</p>
        <div className="flex items-center gap-[20px]">
          <span className="text-sm text-gray-500 mr-2">{`Total Users Registered: ${
            refreshing ? "Refreshing..." : registeredUsers?.counter || 0
          }`}</span>
          <button
            onClick={() => setRefreshing(!refreshing)}
            className="whitespace-nowrap flex justify-center items-center setPrice"
            disabled={refreshing}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.9331 13.0407C19.7442 14.4806 19.167 15.842 18.2632 16.9788C17.3595 18.1157 16.1633 18.985 14.803 19.4937C13.4427 20.0024 11.9696 20.1312 10.5417 19.8663C9.1138 19.6013 7.78492 18.9527 6.69761 17.99C5.6103 17.0272 4.80557 15.7867 4.36973 14.4013C3.93389 13.016 3.88338 11.5381 4.22362 10.1263C4.56385 8.7144 5.282 7.42178 6.30104 6.38703C7.32007 5.35229 8.60156 4.61446 10.0081 4.25268C13.9071 3.25268 17.9431 5.25968 19.4331 8.99968"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 4V9H15"
                stroke="#4D58E3"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>{refreshing ? "Refreshing..." : "Refresh"}</p>
          </button>
          <button onClick={() => setViewRegisteredUsersPopup(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center p-4">
        <button
          onClick={() => setFilterOption("ALL")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "ALL"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilterOption("WITHOUT_RESULTS")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "WITHOUT_RESULTS"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          NO RESULTS
        </button>
        <button
          onClick={() => setFilterOption("WITH_RESULTS")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "WITH_RESULTS"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          WITH RESULTS
        </button>
      </div>
      {refreshing ? (
        <Loading />
      ) : (
        <div className="text-[#161616] p-[10px]">
          <table className="w-full">
            <thead className="border-[1px] border-[#111] border-opacity-20">
              <tr className="text-[12px] font-[500]">
                <th className="py-2 px-4 border-b">No</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Email/Number</th>
                <th className="py-2 px-4 border-b">Registered Faces</th>
                <th className="py-2 px-4 border-b">Total results Count</th>
                <th className="py-2 px-4 border-b">View Results</th>
                <th className="py-2 px-4 border-b">Registered Time</th>
              </tr>
            </thead>
            <tbody>
              {registeredUsers &&
                registeredUsers.peopleFaces?.length > 0 &&
                registeredUsers.peopleFaces?.map((user, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-green-100" : "bg-green-200"
                    }`}
                  >
                    <td className="py-2 px-4 border-b">{index + 1}</td>
                    <td className="py-2 px-4 border-b">{user?.name}</td>
                    <td className="py-2 px-4 border-b">{getVerified(user)}</td>
                    <td className="py-2 px-4 border-b">
                      {user?.faceUrl && user?.faceUrl.length > 0 ? (
                        <button
                          onClick={() => handleViewFaces(user)}
                          className="text-sm  whitespace-nowrap text-white bg-blue-500 hover:bg-blue-600 px-[12px] py-1 rounded-full focus:outline-none focus:ring focus:border-green-300"
                        >
                          Show Registered Faces
                        </button>
                      ) : (
                        <button className="text-sm whitespace-nowrap text-white bg-red-500 hover:bg-red-600 px-[12px] py-1 rounded-full focus:outline-none focus:ring focus:border-green-300">
                          No Faces
                        </button>
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {user?.totalResultcount ? user?.totalResultcount : 0}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {user?.encodedString && (
                        <button
                          onClick={() => handleCopyLink(user?.encodedString)}
                          className="text-sm  text-white bg-green-500 hover:bg-green-600 px-[12px] whitespace-nowrap py-1 rounded-full focus:outline-none focus:ring focus:border-green-300"
                        >
                          Copy Link
                        </button>
                      )}
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap border-b">
                      {convertMongoDBTimestampToIST(user?.registeredAt)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RegisteredUsersPopup;
