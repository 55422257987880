import React from "react";
// import uploadanimation from './upload-animation.json'
import Animaton from "./Animation.json";
import Lottie from "react-lottie-player";
function Loading() {
  return (
    <div
      className="h-[80vh]"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={Animaton}
        play
        style={{ width: 300, height: 300 }}
      />
    </div>
  );
}

export default Loading;
