import React from "react";
// css in PackagePopup.css

function FlexiNewDesign({ packages }) {
  const steps = [
    " Create an Event — Set up your event.",
    " Choose Services — Pick the services you want.",
    " Pay as You Go — Pay only for the services you use.",
  ];

  return (
    <div className="bg-[#fff] mt-[37px] text-[#161616] rounded-[12px] p-[20px]">
      <div className="pb-[20px] border-b-[1px] border-b-[#111] border-opacity-10 flex gap-[12px] ">
        <button className="iconWatermark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="#373CBD" />
          </svg>
        </button>

        <div>
          <div className="flex gap-[4px]">
            <p className="text-[18px] font-[600]">Flexi Plans</p>{" "}
            <button
              style={{
                borderRadius: "4px",
                background: "rgba(152, 196, 255, 0.20)",
              }}
              className="text-[13px] px-[12px] py-[3px]  text-[#373CBD]"
            >
              Per Events
            </button>
          </div>

          <p className="  text-[14px] font-[400] mt-[4px]">
            Our Flexi Plans offer flexible, pay-as-you-go service pricing for
            single events. Just create an event, and you'll be able to choose
            from a range of customizable services to suit your needs.
          </p>
        </div>
      </div>

      <div className="mt-[21px] ml-[15px]">
        <p className="font-[600]  text-[18px]">How it's Works</p>
        <ul className="text-[14px] font-[400] mt-[4px]">
          {steps?.map((item, index) => (
            <p
              key={index}
              className="before:content-['•'] before:mr-2 before:text-black list-none"
            >
              {item}
            </p>
          ))}
        </ul>
      </div>

      {packages?.map((item) => (
        <div className="mt-[60px]">
          <div className="flex justify-between pb-[16px] border-b-[1px] border-b-[#C9C9C9]">
            <div className="flex gap-[23px]">
              <p className="text-[24px] font-[600]">{item?.packageName}</p>
              <button className="storageinGBbut">
                {item?.storageInGb} GB Storage
              </button>
            </div>

            <div className="flex gap-[10px]">
              <p className="fexiPrice text-[24px]">₹ {item?.price}</p>
              <p className="fexiPrice text-[13px]">Per Event</p>
            </div>
          </div>

          {item?.features?.map((item) => (
            <div className="flex justify-between py-[16px] border-b-[1px] border-b-[#C9C9C9]">
              <div className="flex justify-center items-center gap-[20px]">
                {item?.extraAmount ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 11 11"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1628_2804)">
                      <rect
                        x="2.375"
                        y="3"
                        width="6.25"
                        height="5.625"
                        fill="#FA6666"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 5.5C0.5 4.17392 1.02678 2.90215 1.96447 1.96447C2.90215 1.02678 4.17392 0.5 5.5 0.5C6.82608 0.5 8.09785 1.02678 9.03553 1.96447C9.97322 2.90215 10.5 4.17392 10.5 5.5C10.5 6.82608 9.97322 8.09785 9.03553 9.03553C8.09785 9.97322 6.82608 10.5 5.5 10.5C4.17392 10.5 2.90215 9.97322 1.96447 9.03553C1.02678 8.09785 0.5 6.82608 0.5 5.5ZM5.21467 7.64L8.09333 4.04133L7.57333 3.62533L5.11867 6.69267L3.38 5.244L2.95333 5.756L5.21467 7.64067V7.64Z"
                        fill="#FA6666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1628_2804">
                        <rect
                          width="10"
                          height="10"
                          fill="white"
                          transform="translate(0.5 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M10.5001 18.3337C15.1025 18.3337 18.8334 14.6027 18.8334 10.0003C18.8334 5.39795 15.1025 1.66699 10.5001 1.66699C5.89771 1.66699 2.16675 5.39795 2.16675 10.0003C2.16675 14.6027 5.89771 18.3337 10.5001 18.3337Z"
                      fill="#4D58E3"
                    />
                    <path
                      d="M9.3167 12.9837C9.15003 12.9837 8.9917 12.917 8.87503 12.8003L6.5167 10.442C6.27503 10.2003 6.27503 9.80033 6.5167 9.55866C6.75837 9.31699 7.15837 9.31699 7.40003 9.55866L9.3167 11.4753L13.6 7.19199C13.8417 6.95033 14.2417 6.95033 14.4834 7.19199C14.725 7.43366 14.725 7.83366 14.4834 8.07533L9.75837 12.8003C9.6417 12.917 9.48337 12.9837 9.3167 12.9837Z"
                      fill="white"
                    />
                  </svg>
                )}
                <p className={`${item?.extraAmount && "ml-[6px]"}`}>
                  {item?.features}
                </p>
              </div>

              <div className="text-[16px] font-[600] text-[#4D58E3]">
                <p>{item?.count}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default FlexiNewDesign;
