import React, { useRef, useState } from "react";

const kPaddingRespectToMainImage = 0.017;
const kPaddingOffsetForHorizontalMargin = 1.5;

const WatermarkPosition = Object.freeze({
  TopLeft: 0,
  TopRight: 1,
  BottomLeft: 2,
  BottomRight: 3,
});

const getWatermarkRect = (mainImage, position, customWidth, customHeight) => {
  const padding = mainImage.width * kPaddingRespectToMainImage;

  switch (position) {
    case WatermarkPosition.TopLeft:
      return {
        x: padding * kPaddingOffsetForHorizontalMargin,
        y: padding,
        width: customWidth,
        height: customHeight,
      };
    case WatermarkPosition.TopRight:
      return {
        x:
          mainImage.width -
          customWidth -
          padding * kPaddingOffsetForHorizontalMargin,
        y: padding,
        width: customWidth,
        height: customHeight,
      };
    case WatermarkPosition.BottomLeft:
      return {
        x: padding * kPaddingOffsetForHorizontalMargin,
        y: mainImage.height - customHeight - padding,
        width: customWidth,
        height: customHeight,
      };
    case WatermarkPosition.BottomRight:
      return {
        x:
          mainImage.width -
          customWidth -
          padding * kPaddingOffsetForHorizontalMargin,
        y: mainImage.height - customHeight - padding,
        width: customWidth,
        height: customHeight,
      };
    default:
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
  }
};

const WatermarkComponent = () => {
  const canvasRef = useRef(null);

  const [mainImageSrc, setMainImageSrc] = useState(null);
  const [watermarkSrc, setWatermarkSrc] = useState(null);
  const [position, setPosition] = useState(WatermarkPosition.BottomRight);
  const [watermarkWidth, setWatermarkWidth] = useState(100);
  const [watermarkHeight, setWatermarkHeight] = useState(100);

  const handlePreview = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const mainImage = new Image();
    mainImage.src = mainImageSrc;
    mainImage.onload = () => {
      canvas.width = mainImage.width;
      canvas.height = mainImage.height;
      context.drawImage(mainImage, 0, 0);

      if (watermarkSrc) {
        const watermarkImage = new Image();
        watermarkImage.src = watermarkSrc;
        watermarkImage.onload = () => {
          const watermarkRect = getWatermarkRect(
            mainImage,
            position,
            watermarkWidth,
            watermarkHeight
          );

          context.drawImage(
            watermarkImage,
            0,
            0,
            watermarkImage.width,
            watermarkImage.height,
            watermarkRect.x,
            watermarkRect.y,
            watermarkRect.width,
            watermarkRect.height
          );
        };
      }
    };
  };

  return (
    <div className="p-4">
      <div className="relative">
        <canvas ref={canvasRef} className="border mt-4 w-full" />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Main Image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setMainImageSrc(URL.createObjectURL(file));
            }
          }}
          className="border rounded p-2 mb-4"
        />

        <label className="block mb-2">Watermark Image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setWatermarkSrc(URL.createObjectURL(file));
              handlePreview();
            }
          }}
          className="border rounded p-2"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">Position:</label>
        <select
          value={position}
          onChange={(e) => {
            setPosition(Number(e.target.value));
            handlePreview();
          }}
          className="border rounded p-2 w-full"
        >
          <option value={WatermarkPosition.TopLeft}>Top Left</option>
          <option value={WatermarkPosition.TopRight}>Top Right</option>
          <option value={WatermarkPosition.BottomLeft}>Bottom Left</option>
          <option value={WatermarkPosition.BottomRight}>Bottom Right</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Watermark Width:</label>
        <input
          type="range"
          min="10"
          max="500"
          value={watermarkWidth}
          onChange={(e) => {
            setWatermarkWidth(Number(e.target.value));
            handlePreview();
          }}
          className="w-full"
        />
        <span>{watermarkWidth}px</span>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Watermark Height:</label>
        <input
          type="range"
          min="10"
          max="500"
          value={watermarkHeight}
          onChange={(e) => {
            setWatermarkHeight(Number(e.target.value));
            handlePreview();
          }}
          className="w-full"
        />
        <span>{watermarkHeight}px</span>
      </div>
    </div>
  );
};

export default WatermarkComponent;
