import { useEffect } from "react";

const useDynamicFontFace = (fontArray) => {
  useEffect(() => {
    if (!fontArray?.length) return;

    const styleElements = [];

    fontArray.forEach(({ fontName, fontUrl }) => {
      if (!fontName || !fontUrl) return;

      // Check if the style for this font already exists in the document head
      const existingStyle = Array.from(
        document.head.querySelectorAll("style")
      ).find((style) => style.innerHTML.includes(`font-family: '${fontName}'`));

      if (existingStyle) return; // If the style already exists, skip adding it

      const style = document.createElement("style");
      style.innerHTML = `
        @font-face {
          font-family: '${fontName}';
          src: url('${fontUrl}') format('${getFontFormat(fontUrl)}');
        }
      `;
      document.head.appendChild(style);
      styleElements.push(style); // Store the reference for cleanup
    });

    // Cleanup function to remove added styles when component unmounts or fontArray updates
    return () => {
      styleElements.forEach((style) => {
        if (document.head.contains(style)) {
          document.head.removeChild(style);
        }
      });
    };
  }, [fontArray]); // Depend on fontArray, so the effect runs when fontArray changes
};

const getFontFormat = (url) => {
  const extension = url?.split(".").pop().toLowerCase();
  const formatMap = {
    woff2: "woff2",
    woff: "woff",
    ttf: "truetype",
    otf: "opentype",
  };
  return formatMap[extension] || "woff2"; // Default to woff2 if unknown
};

export default useDynamicFontFace;
