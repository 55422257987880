import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import "./AdvSettings.css";

function AdvanceSettings({
  openAdvSttings,
  setOpenAdvSettings,
  handleLiveDataButton,
  setPaidPhotosSection,
  setAddWatermarkPopup,
  handleAlbumMetaDataButton,
  handleRegisteredDataButton,
  handlecreatefolder,
  handleUserListingButton,
  detailsIcon,
  options,
  userIcon,
}) {
  return (
    // <PopupWrapper  visible={openAdvSttings}>
    <div className="fixed bg-gray-900 bg-opacity-75 z-[100]  top-0 right-0 bottom-0 left-0 justify-center items-center  flex mx-auto   ">
      <div className="bg-[#fff]  pop  rounded-[10px] flex flex-col p-[15px]    ">
        <div className="flex items-end justify-end">
          {/* <p className="text-[18px]">Advance Settings</p>*/}
          <button onClick={() => setOpenAdvSettings(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col text-[16px]  items-start">
          <div className="flex flex-col gap-[2px] items-start">
            {/* <div className=" "> */}
            <button
              onClick={handleAlbumMetaDataButton}
              className="hover:border-opacity-100 flex gap-[11px] py-[8px] border-opacity-10 border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">Settings</p>
            </button>
            {/* </div> */} 
            <button
              onClick={() =>  {
                setAddWatermarkPopup(true);
              }}
              className="hover:border-opacity-100 flex gap-[11px] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={options} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">Add Watermark</p>
            </button>
            <button
              onClick={handleUserListingButton}
              className="flex hover:border-opacity-100 gap-[11px] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">View / Manage Crew</p>
            </button>
            <button
              onClick={handleRegisteredDataButton}
              className="flex hover:border-opacity-100 gap-[11px] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">
                View Registered Users
              </p>
            </button>
            <button
              onClick={handleLiveDataButton}
              className="flex hover:border-opacity-100 gap-[11px] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">View Live Users</p>
            </button>
            <button
              onClick={() => {
                setPaidPhotosSection(true);
              }}
              className="flex hover:border-opacity-100 gap-[11px] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              {/* <img src={userIcon} alt="" className="w-[16px] h-[16px]" /> */}
              <p className=" font-[400] text-[#161616]">
                View/Manage Paid Photos
              </p>
            </button>
            {/* <button
              onClick={handlecreatefolder}
              className="flex gap-[11px] text-[14px] font-[400] py-[8px] border-opacity-10  border-b-[0.5px] border-b-[#111] w-full"
            >
              Create Folder
            </button> */}
          </div>
        </div>
      </div>
    </div>
    // </PopupWrapper>
  );
}

export default AdvanceSettings;
