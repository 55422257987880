import React, { useContext, useState, useEffect, useRef } from "react";
import Lottie from "../../Assets/Animation/Loading";
import { useParams } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import MetaDataPopupInvitation from "../../Components/MetaDataPopup/MetaDataPopupInvitation";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import Lottie1 from "../../Assets/Animation/LoadingWhite";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import AlbumEditForm from "../../Components/AlbumSection/AlbumEditForm";
import axios from "axios";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
import "./InvitationView.css";
import invitation from "../../Assets/invitation.svg";
import refresshIcon from "../../Assets/refresshIcon.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import TemplatePurchase from "../../Components/InvitationPurchase/TemplatePurchase";
import { shootmatesalbumdata } from "../../store/firebase-config";
import { ref, uploadBytesResumable } from "firebase/storage";
import compressImage from "../../utils/compressImage";

const InvitationView = () => {
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [copy, setCopy] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [invitationData, setInvitationData] = useState({});
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [editAlbumTemplate, setEditAlbumTemplate] = useState({
    mode: false,
    albumId: null,
  });
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishCrmAlbumLoading, setPublishCrmAlbumLoading] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, roleSelected } = useContext(dataContext);
  const [imagesToUpload, setImagesToUpload] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState([]);

  const Ref = useRef(null);

  useEffect(() => {
    Ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (studioId) {
      getInvitationTemplateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getInvitationTemplateData = async () => {
    try {
      setLoading(true);
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumoprtn/getInvitationDetails/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setInvitationData(response?.data?.data);
        setPickedTemplate(response?.data?.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setUploadLoading(true);
    if (Object.keys(imagesToUpload).length === 0) {
      publishCrmInvitationTemplate(pickedTemplate);
      return;
    }
    try {
      let dataToTransfer = pickedTemplate;
      await Promise.all(
        Object.entries(imagesToUpload)?.map(async (obj, index) => {
          let mediaArray = [];
          // let key = obj[0]
          let value = obj[1];
          for (let i = 0; i < value?.images?.length; i++) {
            let file = null;
            const selectedFile = value?.images[i];
            if (selectedFile?.type?.startsWith("image")) {
              const fileSize = selectedFile?.size / 1048576;
              if (fileSize > 1) {
                file = await compressImage(selectedFile);
              } else {
                file = selectedFile;
              }
            } else {
              file = selectedFile;
            }

            if (file) {
              let randomId = Math.floor(Date.now() * Math.random());
              let fileName = `${randomId}_${file?.name?.replace(/ /g, "")}`;
              let filePath = `${studioId}/${projectId}/${fileName}`;
              const storageRef = ref(shootmatesalbumdata, filePath);
              const uploadTask = uploadBytesResumable(storageRef, file);
              uploadTask.on("state_changed", (snapshot) => {
                const uploadprogress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[index] = {
                    progress: uploadprogress,
                    type: value?.type,
                  };
                  return newProgress;
                });
              });
              await uploadTask;
              const url = `https://storage.googleapis.com/shootmatesalbumdata/${filePath}`;
              if (value?.type === "GALLERY") {
                mediaArray.push({ url });
              } else mediaArray.push(url);
            } else {
              let orginalFile =
                dataToTransfer?.sections[value?.index]?.sectionData || [];
              if (value?.type === "GALLERY") {
                mediaArray.push({ url: orginalFile?.media[i]?.url });
              } else mediaArray.push(orginalFile?.coverImage);
            }
          }
          if (value?.type === "COVERIMAGE" || value?.type === "THANKING") {
            dataToTransfer.sections[value?.index].sectionData.coverImage =
              mediaArray[0];
          } else if (value?.type === "STUDIO") {
            dataToTransfer.sections[value?.index].sectionData.studioLogo =
              mediaArray[0];
          } else if (value?.type === "GALLERY") {
            dataToTransfer.sections[value?.index].sectionData.media =
              mediaArray;
          }
        })
      );
      return publishCrmInvitationTemplate(dataToTransfer);
    } catch (error) {
      console.log(error.message);
    }
  };

  const publishCrmInvitationTemplate = async (dataToTransfer) => {
    try {
      setPublishCrmAlbumLoading(true);
      setLoadingText("Saving invitation..");
      verifyUser();
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${shootmatesAlbumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${shootmatesAlbumId}`,

        data: dataToTransfer,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: "Invitation templated saved successfully..",
        });
        setImagesToUpload((prevState) => {
          const transformedState = dataToTransfer?.sections?.reduce(
            (acc, section, index) => {
              if (section?.sectionProps?.sectionType === "GALLERY") {
                acc[index] = {
                  index,
                  type: section?.sectionProps?.sectionType,
                  images: section?.sectionData?.media.map(() => ""),
                };
              }
              return acc;
            },
            {}
          );

          return transformedState;
        });
      } else {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
      setUploadLoading(false);
    } catch (error) {
      setPublishCrmAlbumLoading(false);
      setUploadLoading(false);
      console.log("error", error);
    }
  };
  const publishCrmInvitationToMemshots = async () => {
    setPublishCrmAlbumLoading(true);
    setLoadingText("Publishing invitation..");
    try {
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,

        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
      } else {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
    } catch (error) {
      console.log("error", error);
      setPublishCrmAlbumLoading(false);
    }
  };
  const handleAlbumMetaDataButton = () => {
    if (!invitationData?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const copyToClipboard = () => {
    const linkText = `${invitationData?.slugId}`;
    const linkToCopy = `https://${
      roleSelected?.studioUniqueText || "invitation"
    }.memshots.com/${linkText}`;
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div ref={Ref} className="invitationDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {publishCrmAlbumLoading && <Lottie1 loadingText={loadingText} />}
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {editAlbumTemplate?.mode && (
            <AlbumEditForm
              update={update}
              setUpdate={setUpdate}
              visible={editAlbumTemplate?.mode}
              setAlbum={setEditAlbumTemplate}
              projectId={projectId}
              albumId={editAlbumTemplate?.albumId}
            />
          )}
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {metaDataPopup && (
            <MetaDataPopupInvitation
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={invitationData}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <div className="eventDetailSideSeperator px-[15px]">
                <img src={invitation} alt="" className="w-[62px] h-[62px]" />
              </div>

              <div className="ml-[10px]  flex flex-col gap-[8px]">
                <p className="albumType">{invitationData?.templateType}</p>
                <p className="flex  items-center gap-1 font_semibold_20 text-[#161616]">
                  {invitationData?.albumName}
                  {/* <svg
                    onClick={() => setUpdate(!update)}
                    className="flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M16.6107 10.8674C16.4534 12.0674 15.9723 13.2019 15.2192 14.1492C14.4661 15.0966 13.4693 15.821 12.3357 16.2449C11.2021 16.6688 9.97454 16.7761 8.7846 16.5554C7.59467 16.3346 6.48727 15.7941 5.58118 14.9918C4.67509 14.1895 4.00448 13.1557 3.64128 12.0013C3.27808 10.8468 3.23599 9.61527 3.51952 8.43872C3.80305 7.26216 4.4015 6.18498 5.2507 5.32269C6.0999 4.46041 7.16781 3.84555 8.33989 3.54406C11.5891 2.71073 14.9524 4.38323 16.1941 7.49989"
                      stroke="#4D58E3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.6667 3.33301V7.49967H12.5"
                      stroke="#4D58E3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {/* <div className=' cursor-pointer mr-[14px] py-[5px] pl-[5px] pr-[10px] border-[1px] border-[#8C8C8C4D] flex items-center gap-[10px] rounded-[4px]'>
								<img src={albumDetailPreviewIcon} alt="" />
								<p className='font_normal_11 text-[#161616]'>Preview</p>
							</div> */}
              <button
                onClick={publishCrmInvitationToMemshots}
                className="eventButton font_semibold_12"
              >
                Publish Invitation
              </button>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="px-[20px] py-[16px] flex justify-between items-center">
            {/* <div className='flex items-center gap-[16px]'>
							<div className='shortButton'>
								<img src={options} alt="" className='w-[16px] h-[16px]' />
								<p className='font_normal_11 text-[#161616]'>Sections</p>
							</div>
							<div
								onClick={() => {
									setAddUserPopup(true)
								}}
								className='shortButton'>
								<img src={userIcon} alt="" />
								<p className='font_normal_11 text-[#161616]'>Add User</p>
							</div>
						</div> */}
            <div className="flex items-center gap-[16px]">
              <div onClick={handleAlbumMetaDataButton} className="shortButton">
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>
              <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div>
              <div
                onClick={handleSave}
                className="px-[10px] py-[5px] rounded-[4px] bg-[#4D58E3] cursor-pointer"
              >
                <p className="font_normal_11 text-[#FFFFFF]">Save Changes</p>
              </div>
              <div
                onClick={() => copyToClipboard("live")}
                className="shortButtonBlack"
              >
                <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#F6F6F6]">
                  {copy ? "Invitation Link Copied!" : "Invitation Link "}
                </p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>

          <div>
            <TemplatePurchase
              templateJson={pickedTemplate}
              setTemplateJson={setPickedTemplate}
              preview={false}
              setImagesToUpload={setImagesToUpload}
              uploadLoading={uploadLoading}
              progress={progress}
            />
            {/* <InvitationEditForm
              setUpdate={setUpdate}
              update={update}
              formValues={formValues}
              setFormValues={setFormValues}
              invitationData={invitationData}
              setInvitationData={setInvitationData}
              pickedTemplate={pickedTemplate}
              setPickedTemplate={setPickedTemplate}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default InvitationView;
