import React, { useState, useContext, useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import { useParams } from "react-router-dom";
import { userAuthContext } from "../../Context/UserAuthContext";
import Lottie from "../../Assets/Animation/LoadingWhite";
import MessagePopup from "./MessagePopup/MessagePopup";
import axios from "axios";

const CrewUsers = ({ studioId, setViewUserListPopup }) => {
  const { projectId, shootmatesAlbumId } = useParams();
  const [assignedCrewData, setAssignedCrewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [update, setUpdate] = useState(false);
  const [crewData, setCrewData] = useState([]);
  const [activeTab, setActiveTab] = useState("crewList");
  useEffect(() => {
    verifyUser();
    if (activeTab === "assignUser") {
      getAssignedCrewData();
    } else {
      getUserCrewDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, update]);

  const getUserCrewDatas = async () => {
    setLoading(true);
    setLoadingText("Fetching data..");
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/userslists/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setCrewData(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getAssignedCrewData = async () => {
    setLoading(true);
    setLoadingText("Fetching assigned data..");
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/userDetailsAlbumCrew/${projectId}/${studioId}/${shootmatesAlbumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/userDetailsAlbumCrew/${projectId}/${studioId}/${shootmatesAlbumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setAssignedCrewData(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Failed to fetch assigned data.",
        });
      }
    } catch (error) {
      setLoading(false);
      setMessagePopup({
        visible: true,
        type: "error",
        message: "Failed to fetch assigned data.",
      });
      console.log("error", error);
    }
  };

  const removeFromCrew = async (userId, year) => {
    let userYear = null;
    if (!year) {
      const currentDate = new Date();
      userYear = currentDate.getFullYear();
    } else {
      userYear = year;
    }
    try {
      setLoading(true);
      setLoadingText("Removing Crew..");
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/desk/removeCrewUser/${projectId}/${studioId}/${shootmatesAlbumId}/${userId}`,
        data: {
          year: parseInt(userYear),
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200 && response?.data?.error !== true) {
        setLoadingText("Crew Removed..");
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
        setUpdate(!update);
        setLoadingText(null);
      } else {
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: response?.data?.msg,
        });
        setLoadingText(null);
      }
    } catch (error) {
      setLoading(false);
      setMessagePopup({
        visible: true,
        type: "success",
        message: "Request failed please try again..",
      });
      console.log("error", error);
      setLoadingText(null);
    }
  };

  const assignAsCrew = async (userId) => {
    try {
      setLoading(true);
      setLoadingText("Assigning Crew..");
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/desk/addCrewUser/${projectId}/${studioId}/${shootmatesAlbumId}/${userId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoadingText("Crew Removed..");
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
        setUpdate(!update);
        setLoadingText(null);
      } else {
        setLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: response?.data?.msg,
        });
        setLoadingText(null);
      }
    } catch (error) {
      setLoading(false);
      setMessagePopup({
        visible: true,
        type: "success",
        message: "Request failed please try again..",
      });
      console.log("error", error);
      setLoadingText(null);
    }
  };
  return (
    <PopupWrapper visible={true}>
      {loading ? (
        <Lottie loadingText={loadingText} />
      ) : (
        <>
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          <div className=" user-list-popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-md shadow-md w-[1000px] h-[40rem] overflow-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Crew List</h2>
              <div className="flex space-x-4">
                <button
                  className={`tab-button ${
                    activeTab === "assignUser"
                      ? "active-tab bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-blue-700"
                  } rounded-md py-2 px-4 transition duration-300 ease-in-out`}
                  onClick={() => setActiveTab("assignUser")}
                >
                  Assigned Crew Lists
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "crewList"
                      ? "active-tab bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-blue-200 hover:text-blue-700"
                  } rounded-md py-2 px-4 transition duration-300 ease-in-out`}
                  onClick={() => setActiveTab("crewList")}
                >
                  Assign Crew
                </button>

                <button onClick={() => setViewUserListPopup(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18 6L6 18M6 6L18 18"
                      stroke="#667085"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {activeTab === "assignUser" && (
              <div class="relative overflow-x-auto ml-[10px] mt-[10px]">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3 bg-[]">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        User Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <Lottie
                        loadingText={
                          loadingText !== null ? loadingText : "Fetching data.."
                        }
                      />
                    ) : assignedCrewData && assignedCrewData?.length > 0 ? (
                      assignedCrewData?.map((el) => (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {el?.authorisedUsers?.name}
                          </th>
                          <td className="px-6 py-4">
                            {el?.authorisedUsers?.userRole}
                          </td>
                          <td className="px-6 py-4">
                            {el?.authorisedUsers?.email}
                          </td>
                          <td className="px-6 py-4">
                            <button
                              class="select-none rounded-lg bg-red-500 py-2 px-5 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              type="button"
                              onClick={() => {
                                removeFromCrew(
                                  el?.authorisedUsers?.shootmatesUserId
                                );
                              }}
                            >
                              Remove Crew
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colspan="4"
                          className="px-6 py-4 text-center text-red-700 dark:text-red-400"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {activeTab === "crewList" && (
              <div class="relative overflow-x-auto ml-[10px] mt-[10px]">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3 bg-[]">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        User Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <Lottie
                        loadingText={
                          loadingText !== null ? loadingText : "Fetching data.."
                        }
                      />
                    ) : crewData && crewData?.length > 0 ? (
                      crewData?.map((el) => (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {el?.userRolesAndAccess?.userName}
                          </th>
                          <td className="px-6 py-4">
                            {el?.userRolesAndAccess?.studioRole}
                          </td>
                          <td className="px-6 py-4">
                            {el?.userRolesAndAccess?.email}
                          </td>
                          <td className="px-6 py-4">
                            <button
                              class="select-none rounded-lg bg-red-500 py-2 px-5 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              type="button"
                              onClick={() => {
                                assignAsCrew(el?.userRolesAndAccess?.userId);
                              }}
                            >
                              Assign as Crew
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colspan="4"
                          className="px-6 py-4 text-center text-red-700 dark:text-red-400"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </PopupWrapper>
  );
};

export default CrewUsers;
