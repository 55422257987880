import React, { useContext, useState, useRef, useEffect } from "react";
import "../AlbumCreateForm.css";
import { dataContext } from "../../../Context/DataContext";
import { useParams } from "react-router-dom";
import { shootmatesalbumdata } from "../../../store/firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import coverImageIcon from "../../../Assets/coverImageIcon.svg";

function DynamicAlbumTemplate({
  studioDetails,
  pickedTemplate,
  setFormValues,
  wishesOptions,
}) {
  const inputRefs = useRef([]);
  const [uploadingStates, setUploadingStates] = useState({});
  const neglectedFields = ["sectionEnabled", "studioLocation", "socialMedia"];
  const imageFields = [
    "studioLogo",
    "coverImage",
    "coverImageMob",
    "coverImageWeb",
  ];
  const [sections, setSections] = useState(pickedTemplate?.sections);
  const { eventId } = useParams();
  const { studioId } = useContext(dataContext);
  console.log("pickedTemplate", pickedTemplate?.sections);

  useEffect(() => {
    if (pickedTemplate?.sections) {
      setSections(
        pickedTemplate.sections.map((section) => ({
          ...section,
          sectionData: {
            ...section.sectionData,
            ...(section.sectionData.hasOwnProperty("studioLogo") && {
              studioLogo:
                section.sectionData.studioLogo || studioDetails?.studioLogo,
            }),
            ...(section.sectionData.hasOwnProperty("email") && {
              email: section.sectionData.email || studioDetails?.email,
            }),
            ...(section.sectionData.hasOwnProperty("studioName") && {
              studioName:
                section.sectionData.studioName || studioDetails?.studioName,
            }),
          },
        }))
      );
    }
  }, [pickedTemplate, studioDetails]);

  const handleAlbumCoverPage = async (e, sectionIndex, sectionKey, subKey) => {
    let uploadState = null;
    if (subKey) {
      uploadState = sectionKey + subKey + sectionIndex;
    } else {
      uploadState = sectionKey + sectionIndex;
    }
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert("No file choosen");
      return;
    }
    const allowedExtensions = [".png", ".jpeg", ".jpg"];
    const fileExtension = selectedFile.name.toLowerCase().slice(-4);
    if (!allowedExtensions.includes(fileExtension)) {
      if (subKey) {
        const inputRef = inputRefs.current[sectionKey + subKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      } else {
        const inputRef = inputRefs.current[sectionKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      }
      alert("Only .png, .jpeg, and .jpg files are allowed.");
      return;
    }
    const maxFileSize = 25 * 1024 * 1024;
    if (selectedFile.size > maxFileSize) {
      if (subKey) {
        const inputRef = inputRefs.current[sectionKey + subKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      } else {
        const inputRef = inputRefs.current[sectionKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      }
      alert("File size exceeds the maximum limit of 25MB.");
      return;
    }
    if (selectedFile) {
      try {
        setUploadingStates((prevState) => ({
          ...prevState,
          [uploadState]: true,
        }));
        const uniqueIdentifier = Date.now();
        const fileName = `${uniqueIdentifier}_${selectedFile.name}`;
        const storageRef = ref(
          shootmatesalbumdata,
          `${studioId}/${eventId}/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath = `${studioId}/${eventId}/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        // Update the section object with the gcsBucketUrl
        setSections((prevSections) => {
          const updatedSections = [...prevSections];
          if (subKey) {
            updatedSections[sectionIndex].sectionData[sectionKey][subKey] =
              gcsBucketUrl;
          } else {
            updatedSections[sectionIndex].sectionData[sectionKey] =
              gcsBucketUrl;
          }
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            sections: updatedSections,
          }));
          return updatedSections;
        });

        setUploadingStates((prevState) => ({
          ...prevState,
          [uploadState]: false, // Set the field as not uploading
        }));
      } catch (error) {
        console.error("Error uploading studio logo:", error);
        setUploadingStates((prevState) => ({
          ...prevState,
          [uploadState]: false, // Set the field as not uploading
        }));
      }
    }
  };

  const handleRemoveTemplateImage = (sectionIndex, sectionKey, subKey) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      if (subKey) {
        updatedSections[sectionIndex].sectionData[sectionKey][subKey] = "";
        const inputRef = inputRefs.current[sectionKey + subKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      } else {
        updatedSections[sectionIndex].sectionData[sectionKey] = "";
        const inputRef = inputRefs.current[sectionKey + sectionIndex];
        if (inputRef) {
          inputRef.value = "";
        }
      }
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        sections: updatedSections,
      }));
      return updatedSections;
    });
  };

  const handleInputChange = (e, sectionIndex, sectionKey, subKey) => {
    const { value } = e.target;
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      if (subKey) {
        updatedSections[sectionIndex].sectionData[sectionKey][subKey] = value;
      } else {
        updatedSections[sectionIndex].sectionData[sectionKey] = value;
      }
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        sections: updatedSections,
      }));
      return updatedSections;
    });
  };

  const imageFieldsCheck = (key) => {
    return !imageFields.includes(key);
  };

  const shouldRenderField = (sectionKey, subKey) => {
    if (subKey) {
      return !neglectedFields.includes(subKey);
    }
    return !neglectedFields.includes(sectionKey);
  };

  const inputStyles = {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #e5e7eb",
    borderRadius: "0.25rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#4b5563",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  };

  const handleSelectChange = (e, sectionIndex, sectionKey, subKey) => {
    const { value } = e.target;
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      if (subKey) {
        updatedSections[sectionIndex].sectionData[sectionKey][subKey] = value;
      } else {
        updatedSections[sectionIndex].sectionData[sectionKey] = value;
      }
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        sections: updatedSections,
      }));
      return updatedSections;
    });
  };

  return (
    <>
      {sections.map((section, sectionIndex) => {
        const dynamicFields = [];

        for (const sectionKey in section.sectionData) {
          if (
            shouldRenderField(sectionKey) &&
            typeof section.sectionData[sectionKey] === "object"
          ) {
            for (const subKey in section.sectionData[sectionKey]) {
              if (shouldRenderField(sectionKey, subKey)) {
                const uploadedImage = section.sectionData[sectionKey][subKey];

                dynamicFields.push(
                  <div key={subKey} className="mb-4">
                    <p
                      className="font_normal_12 text-[#8C8C8C] mb-[8px]"
                      // style={{
                      //   // display: 'block',
                      //   fontSize: '1rem',
                      //   fontWeight: 600,
                      //   marginBottom: '0.5rem',
                      //   color: '#374151',
                      // }}
                    >
                      {subKey}
                    </p>
                    {subKey === "studioLogo" || subKey === "coverImage" ? (
                      <div>
                        <div className="w-full h-[100px] rounded-[8px] border-[#8C8C8C4D] border-[1px] cursor-pointer flex items-center justify-center">
                          {uploadedImage &&
                          !uploadingStates[
                            sectionKey + subKey + sectionIndex
                          ] ? (
                            <img
                              alt={"Uploaded Image" + sectionIndex}
                              src={uploadedImage}
                              // style={{
                              //   maxWidth: '100px',
                              //   maxHeight: '100px',
                              // }}
                              className="w-full h-full object-cover rounded-[8px]"
                            />
                          ) : (
                            <div
                              onClick={() => {
                                inputRefs.current[
                                  sectionKey + subKey + sectionIndex
                                ].click();
                              }}
                              className="flex gap-[10px] h-full w-full justify-center  items-center border-[1px] border-[#8C8C8C33]"
                            >
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) =>
                                  handleAlbumCoverPage(
                                    e,
                                    sectionIndex,
                                    sectionKey,
                                    subKey
                                  )
                                }
                                ref={(el) =>
                                  (inputRefs.current[
                                    sectionKey + subKey + sectionIndex
                                  ] = el)
                                }
                                // style={inputStyles}
                                className="hidden"
                              />
                              <img src={coverImageIcon} alt="" />
                              <p className="font_normal_14 text-[#161616]">
                                {uploadingStates[
                                  sectionKey + subKey + sectionIndex
                                ]
                                  ? "Uploading..."
                                  : "Choose Image"}
                              </p>
                            </div>
                          )}
                          {/* {uploadedImage && !uploadingStates[sectionKey + subKey + sectionIndex] && (
                            <>
                              <img
                                alt={"Uploaded Image" + sectionIndex}
                                src={uploadedImage}
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                }}
                              />
                              <button
                                onClick={() => {
                                  handleRemoveTemplateImage(
                                    sectionIndex,
                                    sectionKey,
                                    subKey
                                  );
                                }}
                              >
                                Remove Image
                              </button>
                            </>
                          )}
                          {uploadingStates[sectionKey + subKey + sectionIndex] && <p>Uploading...</p>} */}
                        </div>
                        <div className="flex justify-end mt-[4px]">
                          {uploadedImage &&
                            !uploadingStates[
                              sectionKey + subKey + sectionIndex
                            ] && (
                              <p
                                onClick={() => {
                                  handleRemoveTemplateImage(
                                    sectionIndex,
                                    sectionKey,
                                    subKey
                                  );
                                }}
                                className="font_normal_12 text-[#4D58E3] cursor-pointer"
                              >
                                Change Image
                              </p>
                            )}
                        </div>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder={`Please enter ${
                          section?.sectionProps?.displayNames[subKey]
                            ? section?.sectionProps?.displayNames[subKey]
                            : subKey
                        }`}
                        value={section.sectionData[sectionKey][subKey]}
                        onChange={(e) =>
                          handleInputChange(e, sectionIndex, sectionKey, subKey)
                        }
                        // style={inputStyles}
                        className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                      />
                    )}
                  </div>
                );
              }
            }
          } else if (shouldRenderField(sectionKey)) {
            const uploadedImage = section.sectionData[sectionKey];

            dynamicFields.push(
              <div key={sectionKey} className="mb-4">
                <p
                  className="font_normal_12 text-[#8C8C8C] mb-[8px]"
                  // style={{
                  //   // display: 'block',
                  //   fontSize: '1rem',
                  //   fontWeight: 600,
                  //   marginBottom: '0.5rem',
                  //   color: '#374151',
                  // }}
                >
                  {section?.sectionProps?.displayNames[sectionKey]
                    ? section?.sectionProps?.displayNames[sectionKey]
                    : sectionKey}
                </p>
                {!imageFieldsCheck(sectionKey) ? (
                  <div>
                    <div className="w-full h-[100px] rounded-[8px] border-[#8C8C8C4D] border-[1px] cursor-pointer flex items-center justify-center">
                      {uploadedImage &&
                      !uploadingStates[sectionKey + sectionIndex] ? (
                        <img
                          src={uploadedImage}
                          alt={"Uploaded Image" + sectionIndex}
                          // style={{
                          //   maxWidth: '100px',
                          //   maxHeight: '100px',
                          // }}
                          className="w-full h-full object-cover rounded-[8px]"
                        />
                      ) : (
                        <div
                          onClick={() => {
                            inputRefs.current[
                              sectionKey + sectionIndex
                            ].click();
                          }}
                          className="flex gap-[10px] h-full w-full justify-center  items-center border-[1px] border-[#8C8C8C33]"
                        >
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) =>
                              handleAlbumCoverPage(
                                e,
                                sectionIndex,
                                sectionKey,
                                ""
                              )
                            }
                            ref={(el) =>
                              (inputRefs.current[sectionKey + sectionIndex] =
                                el)
                            }
                            // style={inputStyles}
                            className="hidden"
                          />
                          <img src={coverImageIcon} alt="" />
                          <p className="font_normal_14 text-[#161616]">
                            {uploadingStates[sectionKey + sectionIndex]
                              ? "Uploading..."
                              : "Choose Image"}
                          </p>
                        </div>
                      )}
                      {/* {uploadedImage && !uploadingStates[sectionKey + sectionIndex] && (
                        <>
                          <img
                            src={uploadedImage}
                            alt={"Uploaded Image" + sectionIndex}
                            style={{
                              maxWidth: '100px',
                              maxHeight: '100px',
                            }}
                          />
                          <button
                            onClick={() => {
                              handleRemoveTemplateImage(
                                sectionIndex,
                                sectionKey,
                                ""
                              );
                            }}
                          >
                            Remove Image
                          </button>
                        </>
                      )}
                      {uploadingStates[sectionKey + sectionIndex] && <p>Uploading...</p>} */}
                    </div>
                    {uploadedImage &&
                      !uploadingStates[sectionKey + sectionIndex] && (
                        <div className="flex justify-end mt-[4px]">
                          <p
                            onClick={() => {
                              handleRemoveTemplateImage(
                                sectionIndex,
                                sectionKey,
                                ""
                              );
                            }}
                            className="font_normal_12 text-[#4D58E3] cursor-pointer"
                          >
                            Change Image
                          </p>
                        </div>
                      )}
                  </div>
                ) : sectionKey === "wishesUrl" && wishesOptions.length >= 0 ? (
                  <div key="sendWishesField" className="mb-4">
                    <p
                      className="font_normal_12 text-[#8C8C8C] mb-[8px]"
                      // style={{
                      //   // display: 'block',
                      //   fontSize: '1rem',
                      //   fontWeight: 600,
                      //   marginBottom: '0.5rem',
                      //   color: '#374151',
                      // }}
                    >
                      Select an Option
                    </p>
                    <select
                      value={section.sectionData.selectedOption}
                      onChange={(e) =>
                        handleSelectChange(e, sectionIndex, sectionKey, "")
                      }
                      style={inputStyles}
                    >
                      {wishesOptions.map((option) => (
                        <option key={option?.cardId} value={option?.cardUrl}>
                          {option?.cardName}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <input
                    type="text"
                    placeholder={`Please enter ${
                      section?.sectionProps?.displayNames[sectionKey]
                        ? section?.sectionProps?.displayNames[sectionKey]
                        : sectionKey
                    }`}
                    value={section.sectionData[sectionKey]}
                    onChange={(e) =>
                      handleInputChange(e, sectionIndex, sectionKey, "")
                    }
                    // style={inputStyles}
                    className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  />
                )}
              </div>
            );
          }
        }

        const sectionTypes = {
          COVERIMAGE: "Cover Details Section",
          STUDIO: "Studio Details Section",
          SHARED_MEMORIES: "Shared Memories Section",
          SEND_WISHES: "Send Wishes Section",
          VENUE: "Venue Details Section",
          FAMILY_WISHES: "Family Wishes Section",
          TEASER_LIVE: "Teaser Details Section",
          THANKING: "Thanking Section",
        };
        const getSectionName = (section) => {
          if (sectionTypes[section]) {
            return sectionTypes[section];
          } else {
            return "Section";
          }
        };
        return (
          <div className=" w-full">
            <div key={sectionIndex} className=" px-[24px]">
              <p className="font_semibold_16 text-[#161616] mb-[16px]">
                {getSectionName(section?.sectionProps?.sectionType)}
              </p>
              <div className="w-full grid grid-cols-2 gap-x-[24px] ">
                {dynamicFields}
              </div>
            </div>
            <div className="seperatorHorizontalNew mb-[16px]  "></div>
          </div>
        );
      })}
    </>
  );
}

export default DynamicAlbumTemplate;
